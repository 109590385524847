import { Button, IconButton, Skeleton, Stack } from '@mui/material';
import { Badge, Box, Grid, Typography } from '@mui/material';
import { display } from '@mui/system';
import Avatar from 'components/@extended/Avatar';
import Loader2 from 'components/Loader2';
import MainCard from 'components/MainCard';
import useAuth from 'hooks/useAuth';
import { DocumentDownload, Profile } from 'iconsax-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { downloadCertificate, getCertificateAccomplishUrl } from 'services/Settings.service';
import Snackbar from 'utils/Snackbar';

const VerifyCertificate = () => {
  const { id } = useParams();
  const { organization } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('serviceToken');
  // const [certificateData, setCertificateData] = useState();
  const [image, setImage] = useState<{ path: string }>();
  const [course, setCourse] = useState<{ name: string }>();
  const [certiOrg, setCertiOrg] = useState<{ name: string }>();
  const [completionDate, setCompletionDate] = useState('');
  const [user, setUser] = useState<{ firstName: string; lastName: string; userProfilePicId: { path: string } }>();
  const [pdfCertificate, setPdfCertificate] = useState<{ path: string } | null>(null);
  const [date, setDate] = useState();

  useEffect(() => {
    if (id)
      getCertificateAccomplishUrl(id)
        .then((val) => {
          // console.log(val?.data?.data);
          setImage(val?.data?.data?.image);
          setCourse(val?.data?.data?.course);
          setCompletionDate(val?.data?.data?.completionDate);
          setUser(val?.data?.data?.user);
          setCertiOrg(val?.data?.data?.organization);
          setDate(val?.data?.data?.timeTaken);
          if (val?.data?.data?.pdf) setPdfCertificate(val?.data?.data?.pdf);
          if (!val?.data?.data?.completedDate) {
            setTimeout(() => {
              navigate(-1);
            }, 500);

            Snackbar('No Certificate Found', 'error');
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
        });
    else navigate(-1);
  }, []);

  const handleDownload = async (pdfUrl: string) => {
    try {
      const response = await downloadCertificate({ path: pdfUrl, filename: 'certificate' });
      const contentType = response.headers['content-type'];

      const link = document.createElement('a');
      link.href = `http://10.10.1.120:3000/api/certificates/download?path=${pdfUrl}&filename=certificate'`;
      link.setAttribute('download', 'certificate.pdf');
      if (contentType) {
        const ext = contentType.split('/').pop();
        link.setAttribute('download', `certificate.${ext}`);
      } else {
      }
      document.body.appendChild(link);
      link.click();
      Snackbar('Course Content Downloaded Successfully.', 'success');
    } catch (error) {
      Snackbar('Unable to download the file', 'error');
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        // backgroundColor: '#f5f5f5',
        p: { xs: 0, sm: 4, md: 6 }
      }}
    >
      {loading ? (
        <Stack direction={'row'} justifyContent={'center'} width={'100%'} height={'100%'}>
          <Loader2 />
        </Stack>
      ) : (
        <MainCard
          sx={{
            //   width: { xs: '100%', sm: '90%', md: '80%' },
            maxWidth: 1400,
            //   boxShadow: 3,
            borderRadius: { xs: 0, sm: 2 },
            //   backgroundColor: '#fff',
            p: { xs: 0, sm: 2 }
          }}
        >
          <Box sx={{ mb: 4 }}>
            <Typography variant="caption" color="text.secondary" sx={{ mb: 1, display: 'block' }}>
              Course Certificate
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 2 }}>
              {/* Generative AI with Large Language Models */}
              {loading ? <Skeleton sx={{ height: 30, width: '40%' }} animation="wave" variant="rectangular" /> : course?.name || ''}
            </Typography>
          </Box>
          <Grid container spacing={2} sx={{ flexDirection: { xs: 'row-reverse', sm: 'row' } }}>
            {/* Left Section */}
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: { xs: 'center', sm: 'flex-start' },
                  gap: { sm: 2 },
                  mb: { xs: 0, sm: 3 },
                  pt: 1,
                  flexDirection: { xs: 'column', sm: 'row' },
                  backgroundColor: 'primary.lighter',
                  borderRadius: 1,
                  padding: 2
                }}
              >
                <Badge
                  overlap="circular"
                  // anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  sx={{ mb: { xs: 3, sm: 0 } }}
                  badgeContent={
                    //   <Avatar size="badge">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 48 48">
                      <path fill="#c8e6c9" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
                      <path
                        fill="#4caf50"
                        d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"
                      ></path>
                    </svg>
                    //   </Avatar>
                  }
                >
                  <Avatar size="xl" type="outlined" src={`${process.env.REACT_APP_FILE_URL}${user?.userProfilePicId?.path}`}>
                    {loading && <Profile variant="Bold" />}
                  </Avatar>
                </Badge>
                <Box>
                  <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Completed by {loading ? <Skeleton sx={{ height: 30 }} animation="wave" variant="rectangular" /> : user?.firstName}{' '}
                    {loading ? <Skeleton sx={{ height: 30 }} animation="wave" variant="rectangular" /> : user?.lastName}
                  </Typography>
                  <Typography color="text.secondary">
                    {loading ? (
                      <Skeleton sx={{ height: 30 }} animation="wave" variant="rectangular" />
                    ) : (
                      moment(completionDate).format('MMMM D, YYYY')
                    )}
                    {/* July 6, 2024 */}
                  </Typography>
                  <Typography color="text.secondary">16 hours (approximately)</Typography>
                  <Typography color="text.secondary" sx={{ mt: 1 }}>
                    {organization?.name || certiOrg?.name || ''} certifies their successful completion of {course?.name || ''}
                  </Typography>
                </Box>
              </Box>
              <Box>
                {id && course?.name && token && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                      textTransform: 'none',
                      mt: 2
                    }}
                    href={`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
                      course && course?.name ? course?.name?.replace(/ /g, '+') : ''
                    }&certUrl=${process.env.REACT_APP_URL}${`/account/accomplishments/verify/${id}`}&certId=${id}&organizationName=${
                      organization.name || certiOrg?.name || ''
                    }&issueMonth=${moment(completionDate).month() + 1}&issueYear=${moment(completionDate).format('YYYY')}`}
                    target="_blank"
                  >
                    Add To LinkedIn
                  </Button>
                )}
              </Box>
            </Grid>
            {/* Right Section */}
            <Grid item xs={12} md={7}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: { md: 'right', sm: 'center', xs: 'center' }
                }}
              >
                <Box
                  onClick={() => {
                    if (token) {
                      window.open(`${process.env.REACT_APP_FILE_URL}${pdfCertificate?.path}`, '_blank');
                    }
                  }}
                  sx={{
                    width: '100%',
                    // border: '1px solid #e0e0e0',
                    // borderRadius: 1,
                    maxWidth: '500px',
                    overflow: 'hidden'
                  }}
                >
                  <img
                    // src="https://s3.amazonaws.com/coursera_assets/meta_images/generated/CERTIFICATE_LANDING_PAGE/CERTIFICATE_LANDING_PAGE~ETWC25X9USQ9/CERTIFICATE_LANDING_PAGE~ETWC25X9USQ9.jpeg"
                    // src="https://static.vecteezy.com/system/resources/thumbnails/004/805/384/small_2x/graduation-certificate-template-free-vector.jpg"
                    src={`${process.env.REACT_APP_FILE_URL}${image?.path}`}
                    alt="Certificate"
                    style={{
                      width: '100%',
                      height: 'auto'
                    }}
                  />
                </Box>
              </Box>
              {token && (
                <Stack direction={'row'} justifyContent={'space-around'}>
                  <Box sx={{ display: { md: 'block', sm: 'none', xs: 'none' } }}>&nbsp;</Box>
                  <Box>
                    <Button
                      variant="contained"
                      endIcon={<DocumentDownload />}
                      sx={{ mr: 5 }}
                      onClick={() => {
                        handleDownload(`${pdfCertificate?.path}`);
                      }}
                    >
                      Download
                    </Button>
                  </Box>
                </Stack>
              )}
            </Grid>
          </Grid>
        </MainCard>
      )}
    </Box>
  );
};

export default VerifyCertificate;
