import axios from 'axios';
const API_URL = process.env.REACT_APP_BASEURL;
const API_URL_SUPERADMIN = process.env.REACT_APP_BASEURL + 'superAdminDashboard/';
// const API_URL_SUPERADMIN = 'http://10.10.1.189:3000/api/superAdminDashboard/';

export const getAllOrganizations = async (reqData: Object) => {
  return await axios.get(`${API_URL}organization/all`, { params: reqData });
};

export const getOrganizationTableUserList = (reqData: Object) => {
  return axios.get(`${API_URL}organization/userslist`, { params: reqData });
};

export const getSuperDashboardTotalCount = async () => {
  return await axios.get(`${API_URL_SUPERADMIN}totalCount`);
};

export const getSuperDashboardTotalCountWithDate = (reqData: Object) => {
  return axios.get(`${API_URL_SUPERADMIN}totalCount`, { params: reqData });
};

export const getSuperDashboardOverview = () => {
  return axios.get(`${API_URL_SUPERADMIN}organizationWithMostUser`);
};

export const getOrganizationRevenue = (reqData: Object) => {
  return axios.get(`${API_URL_SUPERADMIN}organizationRevenue`, { params: reqData });
};

export const getGroupRevenue = (reqData: Object) => {
  return axios.get(`${API_URL}dashboard/groupRevenue`, { params: reqData });
};

export const getCourseRevenue = (reqData: Object) => {
  return axios.get(`${API_URL}dashboard/courseRevenue`, { params: reqData });
};

export const getReportTable = (reqData: Object) => {
  return axios.get(`${API_URL}analysis/orgUsersList`, { params: reqData });
};

export const getCourseList = async (reqData: any) => {
  return axios.get(`${API_URL}analysis/orgAllCourses`, { params: reqData });
  // return axios.get(`${API_URL}analysis/orgCoursesList`, { params: reqData });
};

export const getOrgAssessmentUsers = async (reqData: any) => {
  return axios.get(`${API_URL}analysis/orgAssessmentUsers`, { params: reqData });
};

export const getSuperReportTable = (reqData: Object) => {
  return axios.get(`${API_URL_SUPERADMIN}orgUsersList`, { params: reqData });
};

export const getSuperCourseList = async (reqData: any) => {
  return axios.get(`${API_URL_SUPERADMIN}orgAllCourses`, { params: reqData });
  // return axios.get(`${API_URL}analysis/orgCoursesList`, { params: reqData });
};

export const getSuperOrgAssessmentUsers = async (reqData: any) => {
  return axios.get(`${API_URL_SUPERADMIN}orgAssessmentUsers`, { params: reqData });
};

export const getOrgCategories = (reqData: Object) => {
  return axios.get(`${API_URL}category/all`, { params: reqData });
};

export const getOrgeventNotification = (reqData: Object) => {
  return axios.get(`${API_URL_SUPERADMIN}eventNotification/all`, { params: reqData });
};

export const getOffer = (reqData: Object) => {
  return axios.get(`${API_URL_SUPERADMIN}offer/all`, { params: reqData });
};

export const getAllGroup = (reqData: Object) => {
  return axios.get(`${API_URL}group/all`, { params: reqData });
};

export const organizationUpdateStatus = (reqData: Object) => {
  return axios.put(`${API_URL_SUPERADMIN}organization/updateStatus`, reqData);
};

export const userUpdateStatus = (reqData: Object) => {
  return axios.put(`${API_URL_SUPERADMIN}user/updateStatus`, reqData);
};

export const categoryUpdateStatus = (reqData: Object) => {
  return axios.put(`${API_URL_SUPERADMIN}category/updateStatus`, reqData);
};

export const courseUpdateStatus = (reqData: Object) => {
  return axios.put(`${API_URL_SUPERADMIN}course/updateStatus`, reqData);
};

export const groupUpdateStatus = (reqData: Object) => {
  return axios.put(`${API_URL_SUPERADMIN}group/updateStatus`, reqData);
};

export const assessmentUpdateStatus = (reqData: Object) => {
  return axios.put(`${API_URL_SUPERADMIN}assessment/updateStatus`, reqData);
};

export const offerUpdateStatus = (reqData: Object) => {
  return axios.put(`${API_URL_SUPERADMIN}offer/updateStatus`, reqData);
};

export const eventNotificationUpdateStatus = (reqData: Object) => {
  return axios.put(`${API_URL_SUPERADMIN}eventNotification/updateStatus`, reqData);
};

export const addSuperUser = (reqData: Object) => {
  return axios.post(`${API_URL_SUPERADMIN}supportUser`, reqData);
};

export const editSuperUser = (reqData: Object) => {
  return axios.put(`${API_URL_SUPERADMIN}supportUser`, reqData);
};

export const getAllSuperUser = (reqData: Object) => {
  return axios.get(`${API_URL_SUPERADMIN}supportUser`, { params: reqData });
};

export const deleteSuperUser = (reqData: Object) => {
  return axios.delete(`${API_URL_SUPERADMIN}supportUser`, { data: reqData });
};

export const getfeedback = (reqData: Object) => {
  return axios.get(`${API_URL_SUPERADMIN}feedback/all`, { params: reqData });
};

export const testCredential = (reqData: Object) => {
  return axios.post(`${API_URL_SUPERADMIN}testCredentials`, reqData);
};
