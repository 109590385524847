import { Button, Divider, FormHelperText, Grid, InputLabel, Stack, TextField } from '@mui/material';
import MainCard from 'components/MainCard';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Snackbar from 'utils/Snackbar';
import { addContent, editContent } from 'services/landingPageManagement.service';
import BackToggle from 'components/third-party/dropzone/BackToggle';
import UploadAvatar from 'components/third-party/dropzone/Avatar';
import { Box } from '@mui/material';

// Define validation schema
const validationSchema = yup.object({
  title: yup.string().required('Title is required'),
  files: yup
    .mixed()
    .required('image is required')
    .test('fileType', 'Only JPG, PNG, JPEG,SVG and GIF files are allowed', (value) => {
      // console.log(value, 'valueimage');
      if (!value || value.length === 0) return false;
      const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg', 'image/svg+xml'];
      return allowedFileTypes.includes(value.type || value.mimetype);
    })
});

function UseCaseForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { rowData } = location.state || {};
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const existingImage = rowData?.image ? process.env.REACT_APP_FILE_URL + rowData?.image?.path : null;

  const formik = useFormik({
    initialValues: {
      title: rowData?.title || '',
      files: rowData ? rowData.image : []
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      setIsButtonDisabled(true);
      const serviceMethod = rowData ? editContent : addContent;

      // Append title and image to form data
      formData.append('title', values.title);
      if (formik.values?.files) {
        formData.append('image', formik.values.files?._id ? formik.values.files?._id : formik?.values?.files);
      }

      rowData && formData.append('id', rowData?._id?.toString());

      // Submit form
      serviceMethod(formData, 'useCase')
        .then((res) => {
          const successMessage = rowData ? 'Content updated successfully' : 'Content added successfully';
          const errorMessage = res?.data?.message || 'An error occurred';

          if (res.status === 200 || res.status === 201) {
            Snackbar(successMessage, 'success');
            navigate('/superadmin/landingpagemanagement');
          } else {
            Snackbar(errorMessage, 'error');
            setIsButtonDisabled(false);
          }
        })
        .catch((err) => {
          Snackbar(err?.data?.message || 'An error occurred', 'error');
          setIsButtonDisabled(false);
        });
    }
  });

  return (
    <MainCard
      title={
        <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
          <BackToggle show={true} />
          <span style={{ marginLeft: 10 }}>{rowData ? 'Update Use Case Content' : 'Add Use Case Content'}</span>
        </span>
      }
      content={false}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ p: 2.5 }}>
          <Grid container spacing={3}>
            {/* Title Field */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">Title</InputLabel>
                <TextField
                  fullWidth
                  id="title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  // helperText={formik.touched.title && formik.errors.title}
                />
                {formik.touched.title && formik.errors.title && (
                  <FormHelperText error style={{ color: 'red' }}>
                    {String(formik.errors.title)}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>

            {/* Image Upload Field */}
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="image">Upload Image</InputLabel>
                <UploadAvatar setFieldValue={formik.setFieldValue} file={existingImage} />
                {formik.touched.files && formik.errors.files && (
                  <FormHelperText error style={{ color: 'red' }}>
                    {formik.errors.files as string}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ p: 1.5 }}>
          <Button 
            sx={{ 
              fontSize: { xs: "12px", sm: "14px", },
              padding: { xs: "4px 8px", sm: "6px 12px", } 
            }} 
            variant="contained" 
            type="submit" 
            disabled={isButtonDisabled}
          >
            {rowData ? 'Update' : 'Add'}
          </Button>
          <Button 
            sx={{
              fontSize: { xs: "12px", sm: "14px", },
              padding: { xs: "3.5px 10px", sm: "4.5px 10px", },
              ":hover": {
                color: "white",
                backgroundColor:  "#dc2626"
              },
            }}
            variant="outlined" 
            color="error" 
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
        </Stack>
      </form>
    </MainCard>
  );
}

export default UseCaseForm;
