import { useRef, useState } from 'react'; // ReactNode, SyntheticEvent

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, ButtonBase, CardContent, ClickAwayListener, Grid, Paper, Popper, Stack, Typography, useMediaQuery } from '@mui/material'; // Tab, Tabs,

// project-imports
import ProfileTab from './ProfileTab';
// import SettingTab from './SettingTab';
import Avatar from 'components/@extended/Avatar';
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
// import IconButton from 'components/@extended/IconButton';
import useAuth from 'hooks/useAuth';

// assets
import avatar1 from 'assets/images/users/avatar-6.png';
import avatar2 from 'assets/images/users/avatar-7.png';
import { FormattedMessage } from 'react-intl';
import { Theme } from '@mui/material/styles';

// import { Profile } from 'iconsax-react';

// types
// interface TabPanelProps {
//   children?: ReactNode;
//   dir?: string;
//   index: number;
//   value: number;
// }

// tab panel wrapper
// function TabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;

//   return (
//     <Box
//       role="tabpanel"
//       hidden={value !== index}
//       id={`profile-tabpanel-${index}`}
//       aria-labelledby={`profile-tab-${index}`}
//       {...other}
//       sx={{ p: 1 }}
//     >
//       {value === index && children}
//     </Box>
//   );
// }

// function a11yProps(index: number) {
//   return {
//     id: `profile-tab-${index}`,
//     'aria-controls': `profile-tabpanel-${index}`
//   };
// }

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const ProfilePage = () => {
  const theme = useTheme();
  const downSM = useMediaQuery((theme: Theme) => theme.breakpoints.down(666));

  const { logout, user }: any = useAuth();
  const handleLogout = async () => {
    try {
      await logout();
      // navigate(`/login`, {
      //   state: {
      //     // from: ''
      //   }
      // });
    } catch (err) {
      console.error(err);
    }
  };
  var name: string;
  var types: string;
  // user?.type.charAt(0).toUpperCase() + user?.type.slice(1)
  if (user?.firstName && user?.lastName && user?.type) {
    name =
      user?.firstName.charAt(0).toUpperCase() +
      user?.firstName.slice(1) +
      ' ' +
      user?.lastName.charAt(0).toUpperCase() +
      user?.lastName.slice(1);
    types = user?.type.charAt(0).toUpperCase() + user?.type.slice(1);
  }

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: user?.type === 'student' ? 0 : 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          borderRadius: '50%',
          // '&:hover': {
          //   backgroundColor: user?.type === 'student' ? '#0f8a7e' : theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter'
          // },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          }
        }}
        className="profile"
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {/* <Avatar
          alt={user?.firstName}
          src={
            user?.userProfilePicId?.path
              ? process.env.REACT_APP_FILE_URL + user?.userProfilePicId?.path
              : user?.userProfilePicId?.mimetype
              ? `data:${user?.userProfilePicId?.mimetype};base64,${user?.userProfilePicId?.file}`
              : user && user.gender === 'male'
              ? avatar1
              : avatar2
          }
          sx={{
            objectFit: 'contain',
            border: '1px solid',
            borderColor: theme.palette.primary.main,
            bgcolor: 'transparent',
            pointerEvents: 'none',
            userSelect: 'none'
          }}
        /> */}

        <Stack direction="row" spacing={1.25} alignItems="center">
          <Avatar
            alt={user?.firstName}
            src={
              user?.userProfilePicId?.path
                ? process.env.REACT_APP_FILE_URL + user?.userProfilePicId?.path
                : user?.userProfilePicId?.mimetype
                ? `data:${user?.userProfilePicId?.mimetype};base64,${user?.userProfilePicId?.file}`
                : user && user.gender === 'male'
                ? avatar1
                : avatar2
            }
            sx={{
              width: 45,
              height: 45,
              objectFit: 'contain',
              border: '1px solid',
              borderColor: theme.palette.primary.main,
              bgcolor: 'transparent',
              pointerEvents: 'none',
              userSelect: 'none'
            }}
          />
          {!downSM && (
            <Stack direction="column" display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'}>
              <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>{user?.firstName}</Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>{user?.type}</Typography>
            </Stack>
          )}
        </Stack>
      </ButtonBase>
      <Popper
        placement='bottom-start'
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{ zIndex: 1 }}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions  position="top-right" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                // width: 290,
                // minWidth: 240,
                maxWidth: 290,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 250
                },
                borderRadius: 1.5
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} content={false}>
                  <CardContent sx={{ px: 2, pt: 2, pb: 1 }}>
                    <Box
                      sx={{
                        border: `1px solid ${theme.palette.divider}`,
                        '&:hover': {
                          bgcolor: 'primary.lighter',
                          borderColor: theme.palette.primary.light
                        },
                        borderRadius: 2,
                        p: 2
                      }}
                    >
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Stack direction="row" spacing={1.25} alignItems="center">
                            <Avatar
                              alt={name}
                              src={
                                user?.userProfilePicId?.path
                                  ? process.env.REACT_APP_FILE_URL + user?.userProfilePicId?.path
                                  : user?.userProfilePicId?.mimetype
                                  ? `data:${user?.userProfilePicId?.mimetype};base64,${user?.userProfilePicId?.file}`
                                  : user && user.gender === 'male'
                                  ? avatar1
                                  : avatar2
                              }
                              sx={{
                                objectFit: 'contain',
                                border: '1px solid',
                                borderColor: theme.palette.primary.main,
                                bgcolor: 'transparent',
                                pointerEvents: 'none',
                                userSelect: 'none'
                              }}
                            />
                            <Stack direction="column">
                              {/* <Typography variant="subtitle1">{user?.name}</Typography> */}
                              <Typography variant="h6">{name}</Typography>
                              <Typography variant="caption" sx={{ fontWeight: '600' }}>
                                {types}
                              </Typography>
                              {user?.type === 'student' && (
                                <Typography variant="body2">
                                  <FormattedMessage id="Points" />:{user?.points}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>

                  {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="profile tabs">
                      <Tab
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textTransform: 'capitalize'
                        }}
                        icon={<Profile size={18} style={{ marginBottom: 0, marginRight: '10px' }} />}
                        label="Profile"
                        {...a11yProps(0)}
                      />
                    </Tabs>
                  </Box> */}
                  {/* <TabPanel value={value} index={0} dir={theme.direction}> */}
                  <ProfileTab handleLogout={handleLogout} setOpen={handleToggle} />
                  {/* </TabPanel> */}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default ProfilePage;
