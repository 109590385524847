import {
  Button,
  Divider,
  Grid,
  InputLabel,
  Stack,
  Box,
  TextField,
  FormControl,
  Typography,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Select,
  MenuItem,
  FormLabel,
  Autocomplete,
  Chip,
  Checkbox
} from '@mui/material';
import MainCard from 'components/MainCard';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Snackbar from 'utils/Snackbar';
import { addContent, editContent } from 'services/landingPageManagement.service';
import BackToggle from 'components/third-party/dropzone/BackToggle';
import React from 'react';
import { AddCircle, MinusCirlce } from 'iconsax-react';
import UploadAvatar from 'components/third-party/dropzone/Avatar';

const validationSchema = yup.object({
  title: yup.string().required('Title is required'),
  order: yup.number().required('order is required'),
  description: yup.string().required('Description is required'),
  pageType: yup.string().required('pageType is required'),
  cards: yup.array().when('type', {
    is: 'Card',
    then: yup
      .array()
      .of(
        yup.object({
          cardTitle: yup.string().required('Card title is required'),
          cardContent: yup.string().required('Card content is required'),
          cardImage: yup.mixed().test('fileType', 'Only JPG, PNG, JPEG, SVG and GIF files are allowed', (value) => {
            if (!value || value.length === 0) return true;
            const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg', 'image/svg+xml'];
            return allowedFileTypes.includes(value.type);
          })
        })
      )
      .min(1, 'At least one card is required')
      .max(5, 'You can only add up to 5 cards'),
    otherwise: yup.array().notRequired()
  }),
  points: yup.array().when('type', {
    is: 'Point',
    then: yup
      .array()
      .of(yup.string().required('Point is required'))
      .min(1, 'At least one point is required')
      .max(10, 'You can only add up to 10 points'),
    otherwise: yup.array().notRequired()
  }),
  files: yup
    .mixed()
    .required('image is required')
    .test('fileType', 'Only JPG, PNG, JPEG, and GIF files are allowed', (value) => {
      if (!value || value.length === 0) return false;
      const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg', 'image/svg+xml'];
      return allowedFileTypes.includes(value.type || value.mimetype);
    })
});

function FeaturesForm(data: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const { rowData } = location.state || {};
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectedType, setSelectedType] = useState(rowData ? rowData?.type : 'none');
  const existingImage = rowData?.image ? process.env.REACT_APP_FILE_URL + rowData?.image?.path : null;
  const [isHighlighted, setIsHighlighted] = useState(rowData?.highlighted?.length > 0 ? true : false);

  const handleCheckboxChange = (event: any) => {
    setIsHighlighted(event.target.checked);
  };
  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedType(event.target.value);
  };
  const formik = useFormik({
    initialValues: {
      //   ...(rowData ? { cmsId: rowData?._id } : {}),
      type: rowData?.type || '',
      pageType: rowData?.pageType ? rowData?.pageType : undefined,
      title: rowData?.title || '',
      order: rowData?.order || '',
      description: rowData?.description || '',
      cards: rowData?.cards || [{ cardImage: rowData ? rowData.cards.cardImage : [], cardTitle: '', cardContent: '' }],
      points: rowData?.points || [''],
      files: rowData ? rowData.image : [],
      highlighted: rowData?.highlighted || []
    },
    validationSchema,

    onSubmit: (values) => {
      const formData = new FormData();
      setIsButtonDisabled(true);
      const serviceMethod = rowData ? editContent : addContent;

      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('pageType', values.pageType);
      formData.append('order', values.order);
      if (formik.values?.files) {
        formData.append('image', formik?.values?.files?._id ? formik.values.files?._id : formik?.values?.files);
      }
      rowData && formData.append('id', rowData?._id?.toString());
      if (values.highlighted?.length > 0) {
        values.highlighted.forEach((item: any, index: any) => {
          formData.append(`highlighted[${index}]`, item);
        });
      }
      if (selectedType === 'cards') {
        formData.append('type', 'cards');
        if (rowData) {
          values.cards.forEach((card: any, index: any) => {
            formData.append(`cards[${index}][cardImage]`, card.cardImage._id ? card.cardImage._id : card.cardImage);
            formData.append(`cards[${index}][cardTitle]`, card.cardTitle);
            formData.append(`cards[${index}][cardContent]`, card.cardContent);
          });
        } else {
          values.cards.forEach((card: any, index: any) => {
            formData.append(`cards[${index}][cardImage]`, card.cardImage);
            formData.append(`cards[${index}][cardTitle]`, card.cardTitle);
            formData.append(`cards[${index}][cardContent]`, card.cardContent);
          });
        }
      } else if (selectedType === 'points') {
        formData.append('type', 'points');
        values.points.forEach((point: any, index: any) => {
          formData.append(`points[${index}]`, point);
        });
      }

      serviceMethod(formData, 'features')
        .then((res) => {
          const successMessage = rowData ? 'Content updated successfully' : 'Content added successfully';
          const errorMessage = res?.data?.message || 'An error occurred';

          if (res.status === 200 || res.status === 201) {
            Snackbar(successMessage, 'success');
            navigate('/superadmin/landingpagemanagement');
          } else {
            Snackbar(errorMessage, 'error');
            setIsButtonDisabled(false);
          }
        })
        .catch((err) => {
          Snackbar(err?.data?.message || 'An error occurred', 'error');
          setIsButtonDisabled(false);
        });
    }
  });

  const handleAddCard = () => {
    if (formik.values.cards.length < 5) {
      formik.setFieldValue('cards', [...formik.values.cards, { icon: '', cardTitle: '', cardContent: '' }]);
    }
  };

  const handleRemoveCard = (index: number) => {
    if (formik.values.cards.length > 1) {
      const updatedCards = [...formik.values.cards];
      updatedCards.splice(index, 1);
      formik.setFieldValue('cards', updatedCards);
    }
  };

  const handleAddPoints = () => {
    if (formik.values.points.length < 5) {
      formik.setFieldValue('points', [...formik.values.points, '']);
    }
  };

  const handleRemovePoints = (index: number) => {
    if (formik.values.points.length > 1) {
      const updatedPoints = [...formik.values.points];
      updatedPoints.splice(index, 1);
      formik.setFieldValue('points', updatedPoints);
    }
  };

  return (
    <MainCard
      title={
        <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
          <BackToggle show={true} />
          <span style={{ marginLeft: 10 }}>{rowData ? 'Update Feature Content' : 'Add Feature Content'}</span>
        </span>
      }
      content={false}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ p: 2.5 }}>
          <Grid container spacing={3}>
            {/* Type Selection */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="pageType">Page Type</InputLabel>
                <Select
                  fullWidth
                  id="pageType"
                  name="pageType"
                  value={formik.values.pageType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.pageType && Boolean(formik.errors.pageType)}
                  displayEmpty
                  sx={{
                    "& .MuiOutlinedInput": {
                      borderRadius: '10px'
                    }
                  }}
                >
                  <MenuItem value="" disabled>
                    Select page type
                  </MenuItem>

                  <MenuItem value="homePage">Home Page</MenuItem>
                  <MenuItem value="overview">Overview Page</MenuItem>
                  <MenuItem value="mobileApp">Mobile App</MenuItem>
                </Select>
                {formik.touched.pageType && formik.errors.pageType && (
                  <FormLabel error sx={{ color: 'red', mt: 1 }}>
                    {String(formik.errors.pageType)}
                  </FormLabel>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="order">Order</InputLabel>
                <TextField
                  fullWidth
                  id="order"
                  name="order"
                  value={formik.values.order}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.order && Boolean(formik.errors.order)}
                  // helperText={formik.touched.order && formik.errors.order}
                />
                {formik.touched.order && formik.errors.order && (
                  <FormHelperText error style={{ color: 'red' }}>
                    {String(formik.errors.order)}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">Title</InputLabel>
                <TextField
                  fullWidth
                  id="title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  // helperText={formik.touched.title && formik.errors.title}
                />
                {formik.touched.title && formik.errors.title && (
                  <FormHelperText error style={{ color: 'red' }}>
                    {String(formik.errors.title)}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            

            <Grid item xs={12} md={6}>
              <FormControl component="fieldset">
                <Typography variant="h6">Select Type</Typography>
                <RadioGroup row value={selectedType} onChange={handleTypeChange}>
                  <FormControlLabel value="cards" control={<Radio />} label="Card" />
                  <FormControlLabel value="points" control={<Radio />} label="Point" />
                  <FormControlLabel value="none" control={<Radio />} label="None" />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={<Checkbox checked={isHighlighted} onChange={handleCheckboxChange} name="highlightedCheckbox" color="primary" />}
                  label={<Typography variant="h6">With Highlighted?</Typography>}
                />
              </FormControl>
            </Grid>
            {/* Conditionally render the Autocomplete field if the checkbox is checked */}
            {isHighlighted && (
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="highlighted">Highlighted text</InputLabel>
                  <Autocomplete
                    multiple
                    id="highlighted"
                    options={[]} // You can provide an array of options if needed
                    freeSolo
                    value={formik.values.highlighted || []}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('highlighted', newValue);
                    }}
                    onBlur={formik.handleBlur}
                    renderTags={(value, getTagProps) =>
                      value?.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={formik.touched.highlighted && Boolean(formik.errors.highlighted)}
                        // helperText={formik.touched.highlighted && formik.errors.highlighted}
                      />
                    )}
                  />
                  {formik.touched.highlighted && formik.errors.highlighted && (
                    <FormHelperText error style={{ color: 'red' }}>
                      {String(formik.errors.highlighted)}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
            )}

            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="description">Description</InputLabel>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  // helperText={formik.touched.description && formik.errors.description}
                  multiline
                  rows={4}
                />
                {formik.touched.description && formik.errors.description && (
                  <FormHelperText error style={{ color: 'red' }}>
                    {String(formik.errors.description)}
                  </FormHelperText>
                )}
              </Stack>  
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="image">Upload Image</InputLabel>
                <Stack spacing={1}>
                  <UploadAvatar setFieldValue={formik.setFieldValue} file={existingImage} />
                  {formik.touched.files && formik.errors.files && (
                    <FormHelperText error style={{ color: 'red' }}>
                      {formik.errors.files as string}
                    </FormHelperText>
                  )}
                </Stack>
              </Stack>
            </Grid>

            {selectedType === 'cards' && (
              <>
                {formik.values.cards.map((card: any, index: any) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      p: 4,
                      ml: 3,
                      mt: 3,
                      border: '1px solid #e0e0e0',
                      borderRadius: '8px'
                    }}
                  >
                    <Grid container spacing={2} sx={{ width: '100%' }}>
                      {/* Image Selection */}
                      <Grid item xs={12} sm={4} container spacing={2}>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="image">Upload Image Icon</InputLabel>
                            <Stack spacing={1}>
                              <UploadAvatar
                                setFieldValue={formik.setFieldValue}
                                file={card.cardImage ? process.env.REACT_APP_FILE_URL + card?.cardImage?.path : null}
                                name={`cards[${index}].cardImage`}
                              />
                              {Array.isArray(formik.touched.cards) &&
                                Array.isArray(formik.errors.cards) &&
                                formik.touched.cards?.[index]?.cardImage &&
                                typeof formik.errors.cards[index] === 'object' &&
                                formik.errors.cards?.[index]?.cardImage && (
                                  <FormHelperText error style={{ color: 'red' }}>
                                    {String(formik.errors.cards?.[index]?.cardImage)}
                                  </FormHelperText>
                                )}
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>

                      {/* Card Details Section */}
                      <Grid item xs={12} sm={8}>
                        <Stack spacing={2}>
                          <Stack spacing={1}>
                            <InputLabel>Card Title</InputLabel>
                            <TextField
                              fullWidth
                              // label="Card Title"
                              name={`cards[${index}].cardTitle`}
                              value={card.cardTitle}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                Array.isArray(formik.touched.cards) &&
                                Array.isArray(formik.errors.cards) &&
                                typeof formik.errors.cards[index] === 'object' &&
                                formik.touched.cards?.[index]?.cardTitle &&
                                Boolean(formik.errors.cards?.[index]?.cardTitle)
                              }
                              // helperText={
                              //   Array.isArray(formik.touched.cards) &&
                              //   Array.isArray(formik.errors.cards) &&
                              //   typeof formik.errors.cards[index] === 'object' &&
                              //   formik.touched.cards?.[index]?.cardTitle &&
                              //   String(formik.errors.cards?.[index]?.cardTitle)
                              // }
                            />
                            {
                               Array.isArray(formik.touched.cards) &&
                               Array.isArray(formik.errors.cards) &&
                               typeof formik.errors.cards[index] === 'object' &&
                               formik.touched.cards?.[index]?.cardTitle &&(
                               <FormHelperText error style={{ color: 'red' }}>
                                    {String(formik.errors.cards?.[index]?.cardTitle)}
                                </FormHelperText>)
                            }
                          </Stack>

                          <Stack spacing={1}>
                            <InputLabel>Card Content</InputLabel>
                            <TextField
                              fullWidth
                              // label="Card Content"
                              name={`cards[${index}].cardContent`}
                              value={card.cardContent}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={formik.touched.cards?.[index]?.cardContent && Boolean(formik.errors.cards?.[index]?.cardContent)}
                              // helperText={formik.touched.cards?.[index]?.cardContent && formik.errors.cards?.[index]?.cardContent}
                              multiline
                              rows={2}
                            />
                            {
                               Array.isArray(formik.touched.cards) &&
                               Array.isArray(formik.errors.cards) &&
                               typeof formik.errors.cards[index] === 'object' &&
                               formik.touched.cards?.[index]?.cardContent &&(
                               <FormHelperText error style={{ color: 'red' }}>
                                    {String(formik.errors.cards?.[index]?.cardContent)}
                                </FormHelperText>)
                            }
                          </Stack>
                        </Stack>
                      </Grid>

                      {/* Action Buttons Section */}
                      <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end" spacing={2}>
                          <IconButton onClick={() => handleRemoveCard(index)} disabled={formik.values.cards.length <= 1}>
                            <MinusCirlce />
                          </IconButton>
                          <IconButton onClick={handleAddCard} disabled={formik.values.cards.length >= 5}>
                            <AddCircle />
                          </IconButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </>
            )}
            {selectedType === 'points' && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: 4,
                    ml: 3,
                    mt: 3,
                    border: '1px solid #e0e0e0',
                    borderRadius: '8px'
                  }}
                >
                  {formik.values.points.map((point: any, index: number) => (
                    <Grid container spacing={2} sx={{ width: '100%' }} key={index}>
                      <Grid item xs={12} md={10} mt={2}>
                        <Stack spacing={1}>
                          <InputLabel>Point</InputLabel>
                          <TextField
                            fullWidth
                            // label="Point"
                            name={`points[${index}]`}
                            value={formik.values.points[index]}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.points?.[index] && Boolean(formik.errors.points?.[index])}
                            helperText={formik.touched.points?.[index] && formik.errors.points?.[index]}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Stack direction="row" justifyContent={{ xs: "flex-end", md: "flex-start"}} spacing={1} mt={{md: "50px"}}>
                          <IconButton onClick={() => handleRemovePoints(index)} disabled={formik.values.points.length <= 1}>
                            <MinusCirlce />
                          </IconButton>
                          <IconButton onClick={handleAddPoints} disabled={formik.values.points.length >= 10}>
                            <AddCircle />
                          </IconButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </>
            )}
          </Grid>
        </Box>
        <Divider />
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ p: 1.5 }}>
          <Button 
             sx={{ 
              fontSize: { xs: "12px", sm: "14px", },
              padding: { xs: "4px 8px", sm: "6px 12px", }, 
            }} 
            variant="contained" 
            type="submit" 
            disabled={isButtonDisabled}
          >
            {rowData ? 'Update' : 'Add'}
          </Button>
          <Link to={''} onClick={() => navigate(-1)}>
            <Button 
              sx={{
                fontSize: { xs: "12px", sm: "14px", },
                padding: { xs: "3.5px 10px", sm: "4.5px 10px", },
                ":hover": {
                  color: "white",
                  backgroundColor:  "#dc2626"
                },
              }}
              variant="outlined" 
              color="error" 
            >
              Cancel
            </Button>
          </Link>
        </Stack>
      </form>
    </MainCard>
  );
}

export default FeaturesForm;
