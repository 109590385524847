import { FormattedMessage } from 'react-intl';
import { RefreshSquare } from 'iconsax-react';
import { NavItemType } from 'types/menu';

// icons
const icons = { RefreshSquare };

const TransferStudents: NavItemType = {
  id: 'TransferStudents',
  type: 'group',
  name: 'TransferStudents',
  icon: icons.RefreshSquare,
  children: [
    {
      icon: icons.RefreshSquare,
      id: 'TransferStudents',
      title: <FormattedMessage id="TransferStudents" />,
      type: 'item',
      url: '/transferstudents'
    }
  ]
};

export default TransferStudents;