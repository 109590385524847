import React, { useEffect, useState } from 'react';
import { useCanvas } from 'contexts/CanvasContext';
import { Tool } from 'types/canvas';
import { Box } from '@mui/system';

import * as pdfjsLib from 'pdfjs-dist';
import type { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';
import { Button, Fab } from '@mui/material';
import { Add, Next, Previous } from 'iconsax-react';

import { ReactSketchCanvas } from 'react-sketch-canvas';
import FileViewer from 'react-file-viewer';

interface CanvasProps {
  tool: Tool;
  color: string;
  lineWidth: number;
}

const Whiteboard = () => {
  const {
    canvasDocRef,
    reactSketchCanvasRef,
    strokeColor,
    strokeWidth,
    eraserWidth,
    fileType,
    fileUrl,
    file,
    setFile,
    setTotalPages,
    handleCanvasOnChange,
    goToPreviousPage,
    goToNextPage
  } = useCanvas();

  useEffect(() => {
    // Set up the worker
    pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist@2.7.570/build/pdf.min.js`;

    // Load the PDF
    const loadPdf = async () => {
      try {
        if (!fileUrl) return;
        if (fileType?.includes('pdf')) {
          const loadingTask = pdfjsLib.getDocument(fileUrl);
          const loadedPdf = await loadingTask.promise;
          console.log('loadedPdf', loadedPdf);
          setFile(loadedPdf);
          setTotalPages(loadedPdf.numPages);
        } else if (fileType?.includes('image')) {
          // Handle image loading
          const image = new Image();
          image.src = fileUrl;
          image.onload = () => {
            console.log('Loaded image', image);
            setFile(image); // Set the loaded image in state
          };
          image.onerror = (error) => {
            console.error('Error loading image:', error);
          };
        }
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    loadPdf();
  }, [fileUrl]);

  return (
    <Box sx={{ width: '100%', height: 'auto' }}>
      {file ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ position: 'relative', width: '80%', height: '100%' }}>
            <Box sx={{ position: '', top: 0, left: 0, zIndex: 1 }}>
              <canvas
                ref={canvasDocRef}
                style={{
                  width: '100%'
                  // cursor: 'crosshair'
                  // maxHeight: '70vh'
                }}
              />
            </Box>
            <Box sx={{ position: 'absolute', top: 0, left: 0, zIndex: 2, width: '100%', height: '100%' }}>
              <ReactSketchCanvas
                ref={reactSketchCanvasRef}
                strokeColor={strokeColor}
                strokeWidth={strokeWidth}
                eraserWidth={eraserWidth}
                // width="100%"
                // height="600px"
                canvasColor="transparent"
                style={{ cursor: 'crosshair' }}
                onChange={handleCanvasOnChange}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box width={'100%'}>
          <ReactSketchCanvas
            ref={reactSketchCanvasRef}
            strokeColor={strokeColor}
            strokeWidth={strokeWidth}
            eraserWidth={eraserWidth}
            width="100%"
            height="600px"
            style={{ cursor: 'crosshair' }}
            onChange={handleCanvasOnChange}
          />
        </Box>
      )}
      {/* <Box>
        <ReactSketchCanvas
          ref={reactSketchCanvasRef}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
          eraserWidth={eraserWidth}
          width="100%"
          height="600px"
          style={{ cursor: 'crosshair' }}
          onChange={handleCanvasOnChange}
        />
      </Box> */}
      {file && fileType?.includes('pdf') && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Button variant="contained" startIcon={<Previous />} onClick={goToPreviousPage}>
            Previous
          </Button>
          <Button variant="contained" endIcon={<Next />} onClick={goToNextPage}>
            Next
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Whiteboard;
