import { dispatch } from 'store';
import { closeSnackbar, openSnackbar } from 'store/reducers/snackbar';

const Snackbar = (message: String, color: String) => {
  dispatch(
    openSnackbar({
      open: true,
      message: message,
      // getTranslatedMessage(message, PlaceValues),
      variant: 'alert',
      alert: {
        color: color
      },
      close: true
    })
  );
  setTimeout(() => {
    dispatch(closeSnackbar());
  }, 3000);
};

export default Snackbar;
