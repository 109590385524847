import { Link } from 'react-router-dom';
import { To } from 'history';

// material-ui
import { Box, ButtonBase } from '@mui/material';
import { SxProps } from '@mui/system';

// project-imports
import Logo from './LogoMain';
import LogoIcon from './LogoIcon';
import { APP_DEFAULT_PATH } from 'config';
import useAuth from 'hooks/useAuth';
import LearnerBackToggle from 'components/third-party/dropzone/LearnerBackToggle';
import vidyalayaLogo from '../../assets/images/homepage/vid_lms_logo 1 1.svg';

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => {
  const { user }: any = useAuth();
  // const currentPath = window.location.pathname;
  // const hasLearnerRoute = currentPath.includes('/learner');
  return (
    <Box sx={{ display: 'flex' }} className="sidebar-icon">
      <ButtonBase
        disableRipple
        component={Link}
        to={!to ? (user?.type === 'student' ? '/' : user?.type === 'faculty' ? '/faculty' : '/dashboard') : to}
        sx={sx}
      >
        <Box sx={{ mr: 1}}>
          <img src={vidyalayaLogo} width="134px" height="40px" style={{ objectFit: 'contain', minHeight:'40px', maxHeight: '48px', minWidth: '140px'}} />
        </Box>
        {/* {isIcon ? <LogoIcon /> : <Logo reverse={reverse} />} */}
      </ButtonBase>
    </Box>
  );
};

export default LogoSection;
