import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import Whiteboard from './Whiteboard';
import { Toolbar } from './Toolbar';
import { ColorPicker } from './ColorPicker';
import { Tool } from 'types/canvas';
import { Box, Stack } from '@mui/system';
import { useCanvas } from 'contexts/CanvasContext';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import { Add, CloseCircle } from 'iconsax-react';
import { useNavigate } from 'react-router';
import useAuth from 'hooks/useAuth';

import { getAllClassRoom } from 'services/classRoom.service';
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import Snackbar from 'utils/Snackbar';
import { whiteBoardRecording } from 'services/whiteboard.service';
import { useExamRecorder } from 'contexts/ExamRecorder';

const CourseWhiteboard = () => {
  const navigate = useNavigate();
  const { user }: any = useAuth();

  const [open, setOpen] = useState(false);
  const [classTypeOption, setClassTypeOption] = useState<Array<any>>([]);
  const [subject, setSubject] = useState<Array<any>>([]);

  const { setFileType, setFileUrl } = useCanvas();
  const { openFullscreen } = useExamRecorder();

  const [preferences, setPreferences] = useState<any>({});

  const [isRecording, setIsRecording] = useState(false);
  const [recordingBlob, setRecordingBlob] = useState<Blob | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunks = useRef<Blob[]>([]);

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];
    console.log('event.target.files', file);
    // application/vnd.ms-powerpoint
    // application/pdf
    setFileType(file.type);
    if (file) {
      // const url = 'http://localhost:3006/occucare-brochure.pdf';
      const url = URL.createObjectURL(file);
      console.log('URL : ', url);
      setFileUrl(url);
    }
  };

  const startRecording = async () => {
    try {
      setErrorMessage(null); // Clear previous errors

      // Attempt to capture the screen
      let screenStream: MediaStream | null = null;
      try {
        screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: {
            displaySurface: 'monitor' // Prefer entire screen
            // logicalSurface: true,
            // cursor: 'always'
          },
          audio: false // Avoid requesting audio here
        });

        // Verify if the entire screen is being shared
        const videoTrack = screenStream.getVideoTracks()[0];
        const settings = videoTrack.getSettings();

        if (settings.displaySurface !== 'monitor') {
          videoTrack.stop();
          throw new Error('You must share your entire screen.');
        }

        // Stop the audio stream when the screen sharing stops
        screenStream.getVideoTracks()[0].onended = () => {
          if (audioStream) {
            audioStream.getTracks().forEach((track) => track.stop());
          }
          stopRecording(); // Ensure recording is stopped when screen sharing ends
          setErrorMessage('Screen sharing stopped. Recording has been terminated.');
          Snackbar('Screen sharing stopped. Recording has been terminated.', 'error');
        };
      } catch (error: any) {
        if (error instanceof DOMException && error.name === 'NotAllowedError') {
          setErrorMessage('Screen recording permission denied. Please allow access to your screen.');

          Snackbar('Screen recording permission denied. Please allow access to your screen.', 'error');
          return;
        } else if (error instanceof DOMException && error.name === 'NotFoundError') {
          setErrorMessage('No screen available for recording. Ensure a screen is connected and enabled.');
          return;
        } else if (error.message === 'You must share your entire screen.') {
          setErrorMessage('You must share your entire screen.');

          Snackbar('You must share your entire screen.', 'error');
          return;
        } else {
          setErrorMessage('An error occurred while trying to capture the screen.');
          console.error('Screen capture error:', error);
          return;
        }
      }

      // Attempt to capture the audio stream
      let audioStream: MediaStream | null = null;
      try {
        audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true
        });
      } catch (error) {
        if (error instanceof DOMException && error.name === 'NotAllowedError') {
          setErrorMessage('Audio recording permission denied. Please allow access to your microphone.');
          Snackbar('Audio recording permission denied. Please allow access to your microphone.', 'error');
        } else if (error instanceof DOMException && error.name === 'NotFoundError') {
          setErrorMessage('No microphone found. Ensure a microphone is connected and enabled.');
          Snackbar('No microphone found. Ensure a microphone is connected and enabled.', 'error');
        } else {
          setErrorMessage('An error occurred while trying to access the audio stream.');
          Snackbar('An error occurred while trying to access the audio stream.', 'error');
          console.error('Audio capture error:', error);
        }
      }

      // If both streams are unavailable, stop further execution
      if (!screenStream && !audioStream) {
        setErrorMessage('Screen and audio recording are both unavailable. Cannot proceed with recording.');
        return;
      } else if (!screenStream) {
        setErrorMessage('Screen recording is unavailable. Only audio will be recorded.');
      } else if (!audioStream) {
        setErrorMessage('Audio recording is unavailable. Only the screen will be recorded.');
      }

      // Stop screen stream if audio stream is unavailable
      if (!audioStream) {
        if (screenStream) {
          screenStream.getTracks().forEach((track) => track.stop());
        }
        setErrorMessage('Audio stream is unavailable. Recording cannot proceed without audio.');
        return;
      }

      // Combine the available streams
      const combinedStream = new MediaStream([
        ...(screenStream ? screenStream.getTracks() : []),
        ...(audioStream ? audioStream.getTracks() : [])
      ]);

      // Create MediaRecorder
      const mediaRecorder = new MediaRecorder(combinedStream, {
        mimeType: 'video/webm'
      });
      mediaRecorderRef.current = mediaRecorder;

      // Collect recording data
      mediaRecorder.ondataavailable = (event: BlobEvent) => {
        if (event.data.size > 0) {
          chunks.current.push(event.data);
        }
      };

      // Handle stop recording
      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks.current, { type: 'video/webm' });
        setRecordingBlob(blob);
        chunks.current = []; // Clear chunks
        if (screenStream) {
          screenStream.getTracks().forEach((track) => track.stop());
        }
        if (audioStream) {
          audioStream.getTracks().forEach((track) => track.stop());
        }

        // console.log(blob);

        submitRecording(blob);

        if (document && document?.exitFullscreen) document?.exitFullscreen();

        Snackbar('Recording stored Successfully.', 'success');
      };

      // Start recording
      mediaRecorder.start();
      setIsRecording(true);

      openFullscreen();
    } catch (error) {
      setErrorMessage('An unexpected error occurred while starting the recording.');
      console.error('Unexpected error:', error);
    }
  };

  const stopRecording = () => {
    // console.log('stopRecording called.......');

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleError = (error: unknown) => {
    console.error('Screen recording error:', error);

    // Determine error type
    if (error instanceof DOMException) {
      switch (error.name) {
        case 'NotAllowedError':
          setErrorMessage('Permission denied: Unable to access screen or audio.');
          break;
        case 'NotFoundError':
          setErrorMessage('Required devices not found: Ensure a microphone is connected.');
          break;
        case 'AbortError':
          setErrorMessage('Screen sharing request was aborted.');
          break;
        default:
          setErrorMessage('An error occurred while starting screen recording.');
      }
    } else {
      setErrorMessage('An unexpected error occurred.');
    }
  };

  const saveRecording = () => {
    if (recordingBlob) {
      const url = URL.createObjectURL(recordingBlob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = 'recording.webm';
      anchor.click();
      URL.revokeObjectURL(url);
    }
  };

  const uploadRecording = async () => {
    if (recordingBlob) {
      const formData = new FormData();
      formData.append('file', recordingBlob, 'recording.webm');

      try {
        const response = await fetch('YOUR_BACKEND_ENDPOINT', {
          method: 'POST',
          body: formData
        });
        if (response.ok) {
          console.log('Upload successful');
        } else {
          setErrorMessage('Failed to upload recording.');
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        setErrorMessage('An error occurred during upload.');
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePreferences = (key: any, value: any) => {
    setPreferences((prevPreferences: any) => ({
      ...prevPreferences,
      [key]: value ?? prevPreferences[key]
    }));
  };

  useEffect(() => {
    if (user?.type !== 'superadmin') {
      getAllClassRoom({
        organizationId: user?.organizationId?._id,
        classType: preferences?.classType ? preferences?.classType : 'class',
        active: true
      }).then((res) => {
        if (res.status === 200)
          setClassTypeOption(
            res.data.data?.classRoomData?.map((item: any) => {
              if (
                (preferences?.otherName && item._id === preferences.otherName) ||
                (preferences?.classRoomId && item._id === preferences.classRoomId) ||
                (preferences?.semester && item._id === preferences.semester)
              ) {
                setSubject(item?.subjects);
              }
              return preferences?.classType === 'class'
                ? { _id: item?._id, className: item?.className || item?.class, subject: item?.subjects, classType: item?.classType }
                : preferences?.classType === 'semester'
                ? {
                    _id: item?._id,
                    semester: item?.semester,
                    streamName: item?.streamName || item?.className,
                    subject: item?.subjects,
                    classType: item?.classType
                  }
                : preferences?.classType === 'other'
                ? { _id: item?._id, otherName: item?.className, subject: item?.subjects, classType: item?.classType }
                : {};
            })
          );
      });
    }
  }, [preferences?.classType]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const handleSave = () => {
    if (!preferences.name) {
      Snackbar('Name field is required', 'error');
      return;
    }

    if (!preferences.classType) {
      Snackbar('Please select a Class type.', 'error');
      return;
    }

    // Additional validation based on selected classType

    if (preferences.classType === 'class' && !preferences.classRoomId) {
      Snackbar('Please select a Classroom.', 'error');
      return;
    }
    if (preferences.classType === 'semester' && (!preferences.semester || !preferences.streamName)) {
      Snackbar('Please select a Semester and Stream Name.', 'error');
      return;
    }
    if (preferences.classType === 'other' && !preferences.otherName) {
      Snackbar('Please select a Ohter Name.', 'error');
      return;
    }
    if (!preferences.subject) {
      Snackbar('Please select a Subject.', 'error');
      return;
    }

    setOpen(false);

    startRecording();
  };

  const submitRecording = (mediaBlob: any) => {
    const formData: any = new FormData();
    formData.append('name', preferences.name);
    formData.append('description', preferences.description || '');
    formData.append('organizationId', user?.organizationId?._id);
    formData.append('classRoomId', preferences.classRoomId || preferences.semester || preferences.otherName);
    formData.append('subjectId', preferences.subject);
    formData.append('file', mediaBlob || recordingBlob, 'screenRecording.mp4');

    whiteBoardRecording(formData)
      .then((res) => {
        if (res.status === 200) {
          Snackbar('Recording Stored Successfully', 'success');
        }
      })
      .catch((error) => {
        Snackbar(`Error recording whiteboard: ${error}`, 'error');
      });
  };

  return (
    <>
      <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        {/* <form onSubmit={formik.handleSubmit}> */}
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4">Recording Preferences</Typography>
          </Box>
          <IconButton
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              cursor: 'pointer',
              background: 'transparent',
              border: 'none'
            }}
            // disabled={customError}
            onClick={handleClose}
          >
            <CloseCircle />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box>
            <Grid container alignItems="center" spacing={1}>
              <Grid item sm={12}>
                <Stack spacing={0}>
                  <InputLabel shrink htmlFor="name">
                    Name *
                  </InputLabel>
                  <TextField
                    id="with-label-input"
                    placeholder="With Label"
                    value={preferences?.name}
                    onChange={(event: SelectChangeEvent<any>) => handlePreferences('name', event.target.value)}
                  />
                </Stack>
              </Grid>

              <Grid item sm={12} mt={1}>
                <Stack spacing={0}>
                  <InputLabel shrink htmlFor="description">
                    Description
                  </InputLabel>
                  <TextField
                    id="with-label-input"
                    placeholder="With Label"
                    value={preferences?.description}
                    onChange={(event: SelectChangeEvent<any>) => handlePreferences('description', event.target.value)}
                  />
                </Stack>
              </Grid>
              <Grid item sm={12} mt={1}>
                <Stack spacing={1} ml={'5px'}>
                  <InputLabel>Class type</InputLabel>
                  <RadioGroup aria-label="size" name="radio-buttons-group" value={preferences?.classType} row>
                    <FormControlLabel
                      value="class"
                      // checked={formik.values.classType === 'class'}
                      control={<Radio />}
                      label="Class"
                      disabled={user?.organizationId?.allowClassTypes ? user?.organizationId?.allowClassTypes !== 'class' : false}
                      onClick={(e: any) => {
                        handlePreferences('classType', e.target.value);
                        handlePreferences('classRoomId', '');
                        handlePreferences('semester', '');
                        handlePreferences('otherName', '');
                        handlePreferences('streamName', '');
                        handlePreferences('subject', '');
                        // formik.setFieldValue('classType', e.target.value);
                      }}
                    />
                    <FormControlLabel
                      value="semester"
                      // checked={formik.values.classType === 'semester'}
                      control={<Radio />}
                      label="Semester"
                      disabled={user?.organizationId?.allowClassTypes ? user?.organizationId?.allowClassTypes !== 'semester' : false}
                      onClick={(e: any) => {
                        handlePreferences('classType', e.target.value);
                        handlePreferences('classRoomId', '');
                        handlePreferences('semester', '');
                        handlePreferences('otherName', '');
                        handlePreferences('streamName', '');
                        handlePreferences('subject', '');
                        handlePreferences('quizGroup', '');
                        // formik.setFieldValue('classType', e.target.value);
                      }}
                    />
                    <FormControlLabel
                      value="other"
                      // checked={formik.values.classType === 'other'}
                      control={<Radio />}
                      label="Other"
                      disabled={user?.organizationId?.allowClassTypes ? user?.organizationId?.allowClassTypes !== 'other' : false}
                      onClick={(e: any) => {
                        handlePreferences('classType', e.target.value);
                        handlePreferences('classRoomId', '');
                        handlePreferences('semester', '');
                        handlePreferences('otherName', '');
                        handlePreferences('streamName', '');
                        handlePreferences('subject', '');
                        handlePreferences('quizGroup', '');
                        // formik.setFieldValue('classType', e.target.value);
                      }}
                    />
                  </RadioGroup>
                </Stack>
              </Grid>

              {preferences?.classType === 'class' && (
                <Grid item sm={12} mt={1}>
                  <Stack spacing={1}>
                    <InputLabel>Classroom Name</InputLabel>
                    <Select
                      // error={formik.touched.classRoomId && Boolean(formik.errors.classRoomId)}
                      fullWidth
                      id="classRoomId"
                      value={preferences?.classRoomId}
                      name="classRoomId"
                      onChange={(event: SelectChangeEvent<any>) => {
                        const selectedValues = event.target.value;
                        if (selectedValues.includes('custom')) {
                          navigate('/classroom/add');
                        } else {
                          handlePreferences('classRoomId', selectedValues);
                          const data = classTypeOption.filter((val: { _id: string }) => val?._id === selectedValues);
                          setSubject(data?.[0]?.subject);
                          handlePreferences('subject', '');
                          handlePreferences('quizGroup', '');
                        }
                      }}
                      MenuProps={MenuProps}
                    >
                      {/* <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                        <Add />
                        <FormattedMessage id="Add Class" />
                      </MenuItem> */}
                      {classTypeOption?.map((value: any, index: number) => {
                        return (
                          <MenuItem key={index} value={value._id}>
                            {value.className}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Stack>
                </Grid>
              )}

              {preferences?.classType === 'semester' && (
                <Grid item sm={12} mt={1}>
                  <Stack spacing={1}>
                    <InputLabel>Semester Name</InputLabel>
                    <Select
                      // error={formik.touched.semester && Boolean(formik.errors.semester)}
                      fullWidth
                      id="semester"
                      value={preferences?.semester}
                      name="semester"
                      onChange={(event: SelectChangeEvent<any>) => {
                        const selectedValues = event.target.value;
                        // if (selectedValues.includes('custom')) {
                        //   navigate('/classroom/add');
                        // } else {
                        const data = classTypeOption.filter((val: { _id: string }) => val?._id === selectedValues);
                        setSubject(data?.[0]?.subject);
                        handlePreferences('subject', '');
                        handlePreferences('quizGroup', '');
                        handlePreferences('semester', selectedValues);
                        // }
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                        <Add />
                        <FormattedMessage id="Add Semester" />
                      </MenuItem>
                      {classTypeOption?.map((value: any) => {
                        return <MenuItem value={value._id}>{value.semester}</MenuItem>;
                      })}
                    </Select>
                  </Stack>
                </Grid>
              )}

              {preferences?.classType === 'semester' && (
                <Grid item sm={12} mt={1}>
                  <Stack spacing={1}>
                    <InputLabel>Stream name</InputLabel>
                    <Select
                      // error={formik.touched.streamName && Boolean(formik.errors.streamName)}
                      fullWidth
                      id="streamName"
                      value={preferences?.streamName}
                      name="streamName"
                      onChange={(event: any) => {
                        const selectedValues = event.target.value;
                        // if (selectedValues.includes('custom')) {
                        //   navigate('/classroom/add');
                        // } else {
                        handlePreferences('streamName', selectedValues);
                        // }
                      }}
                      MenuProps={MenuProps}
                    >
                      {/* <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                            <Add />
                            <FormattedMessage id="Add streamName" />
                          </MenuItem> */}
                      {classTypeOption?.map((value: any) => {
                        if (preferences?.semester === value._id) return <MenuItem value={value._id}>{value.streamName}</MenuItem>;
                      })}
                    </Select>
                    {/* <Box sx={{ color: 'red' }}>{formik.touched.streamName && formik.errors.streamName}</Box> */}
                  </Stack>
                </Grid>
              )}

              {preferences?.classType === 'other' && (
                <Grid item sm={12} mt={1}>
                  <Stack spacing={1}>
                    <InputLabel>Other class type</InputLabel>
                    <Select
                      labelId="otherName"
                      id="otherName"
                      value={preferences?.otherName ? preferences?.otherName : ''}
                      onChange={(event: SelectChangeEvent<any>) => {
                        const selectedValues = event.target.value;
                        // if (selectedValues.includes('custom')) {
                        //   navigate('/classroom/add');
                        // } else {
                        const data = classTypeOption.filter((val: { _id: string }) => val?._id === selectedValues);
                        setSubject(data?.[0]?.subject);
                        handlePreferences('subject', '');
                        handlePreferences('quizGroup', '');
                        handlePreferences('otherName', selectedValues);
                        // }
                      }}
                    >
                      <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                        <Add />
                        <FormattedMessage id="Add other classType" />
                      </MenuItem>
                      {classTypeOption?.map((value: any) => (
                        <MenuItem value={value?._id}>{value.otherName}</MenuItem>
                      ))}
                    </Select>
                    {/* <Box sx={{ color: 'red' }}>{formik.touched.otherName && formik.errors.otherName}</Box> */}
                  </Stack>
                </Grid>
              )}

              {preferences?.classType &&
                ((preferences?.classType === 'class' && preferences?.classRoomId) ||
                  (preferences?.classType === 'semester' && preferences?.semester && preferences?.streamName) ||
                  (preferences?.classType === 'other' && preferences?.otherName)) && (
                  <Grid item sm={12} mt={1}>
                    <Stack spacing={1}>
                      <InputLabel>Subject</InputLabel>
                      <Select
                        name="subject"
                        id="subject"
                        value={preferences?.subject}
                        onChange={(event) => {
                          // if (event.target?.value?.includes('custom')) {
                          //   navigate('/subject/add');
                          // } else {
                          handlePreferences('subject', event.target.value);
                          // }
                        }}
                        fullWidth
                      >
                        {/* {(preferences?.classRoomId || preferences?.semester || preferences?.otherName) && (
                          <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                            <Add />
                            <FormattedMessage id="Add Subject" />
                          </MenuItem>
                        )} */}
                        {subject?.map((item: any) => {
                          return (
                            <MenuItem key={item?._id} value={item?._id}>
                              {item?.subjectName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Stack>
                  </Grid>
                )}
            </Grid>

            {/* </Box> */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              fontSize: { xs: '12px', sm: '14px' },
              padding: { xs: '4px 8px', sm: '6px 12px' }
            }}
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleSave}
            // onClick={startRecording}
          >
            <FormattedMessage id="Save" defaultMessage={'Start'} />
          </Button>
          <Button
            sx={{
              fontSize: { xs: '12px', sm: '14px' },
              padding: { xs: '3.5px 10px', sm: '4.5px 10px' },
              ':hover': {
                color: 'white',
                backgroundColor: '#dc2626'
              }
            }}
            variant="outlined"
            color="error"
            // onClick={handleCancelClick}
            onClick={handleClose}
          >
            <FormattedMessage id="Cancel" defaultMessage={'Cancel'} />
          </Button>
        </DialogActions>
        {/* </form> */}
      </Dialog>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 3 }}>
        <Typography variant="h3">WhiteBoard</Typography>
        <Box>
          {!isRecording ? (
            <Button onClick={() => setOpen(true)} variant="contained" component="label" color="primary" sx={{ mr: 2 }}>
              Start Recording
            </Button>
          ) : (
            <Button onClick={stopRecording} variant="contained" component="label" color="error" sx={{ mr: 2 }}>
              Stop Recording
            </Button>
          )}
          <Button variant="contained" component="label" color="primary" sx={{}}>
            Upload File
            <input type="file" hidden onChange={handleFileUpload} accept=".pdf, image/png, image/jpeg" />
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          // position: 'relative'
          // inset: 0,
          // overflow: 'hidden'
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-around'
          // gap: 4
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            top: 100,
            mx: 2,
            zIndex: 3
          }}
        >
          <Toolbar />
          {/* <ColorPicker color={color} onChange={setColor} /> */}
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1
          }}
        >
          <Whiteboard />
        </Box>

        {/* Uncomment if using StatusBar */}
        {/* <StatusBar tool={tool} lineWidth={lineWidth} /> */}
      </Box>
    </>
  );
};

export default CourseWhiteboard;
