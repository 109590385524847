import { Box } from '@mui/material';

const CertificatePreview = (props: any) => {
  const certificate = props.data;
  return (
    <>
      {certificate ? (
        <div style={{ width: '100%' }}>
          <object data={certificate?.file} type="application/pdf" width="100%" height="500px" />
        </div>
      ) : (
        <Box sx={{ marginLeft: '30vw' }}>No data found</Box>
      )}
    </>
  );
};

export default CertificatePreview;
