import { Box, Button, Card, CardActions, CardContent, Grid, Skeleton, Stack, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import BackToggle from 'components/third-party/dropzone/BackToggle';
import useAuth from 'hooks/useAuth';
import { Star1, UserTick } from 'iconsax-react';
import DummyButton from 'layout/MainLayout/Header/HeaderContent/DummyButton';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getFreeCourse } from 'services/learnerDashboard.service';
import { getUserCourses } from 'services/userCourse.service';
import Snackbar from 'utils/Snackbar';

function FreeCoursesList() {
  const { user }: any = useAuth();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [freeCourses, setFreeCourses] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [userCourses, setUserCourses] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState<any>(null);

  useEffect(() => {
    getUserCourses({ userId: user?._id, classroomId: user?.classRoomId?._id }).then((res: any) => {
      const courses = res.data?.data?.courses;
      setUserCourses(courses);
    });
  }, []);

  useEffect(() => {
    if (!loading) {
      setLoading(true);
      getData();
    }
  }, [page]);

  const handleLength = () => {
    if (!loading) {
      setPage(page + 1);
    }
  };

  function getData() {
    let reqData = { orgId: user?.organizationId?._id, price: 0, isDraft: false, skip: page, limit: 8 };
    getFreeCourse(reqData)
      .then((res) => {
        if (res.status === 200) {
          setTotalCount(res?.data?.data?.count);
          setFreeCourses((prevCourses: any) => [...prevCourses, ...res?.data?.data?.data]);
          setLoading(false);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setLoader(false);
        if (error?.response?.status === 403) {
          Snackbar('Error occured', 'error');
        } else {
          Snackbar('An Error occured', 'error');
        }
      });
  }

  return (
    <MainCard
      title={
        <Stack direction={'row'} spacing={1}>
          <BackToggle />
          <Typography variant="h5">Free Courses</Typography>
        </Stack>
      }
    >
      <Grid container spacing={4}>
        {loader ? (
          <>
            {[...Array(4)].map((_, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                  sx={{
                    height: 460,
                    border: '1px solid #E8EBEE',
                    boxShadow: '0 0 5px 2px rgba(0, 0, 0, 0.1)',
                    p: 1,
                    pt: 0
                  }}
                >
                  <Skeleton sx={{ height: 200, mt: -4 }} />

                  <CardContent>
                    <Skeleton sx={{ height: 38 }} animation="wave" variant="rounded" />
                    <Skeleton sx={{ height: 20, my: 1 }} animation="wave" variant="rounded" />
                  </CardContent>
                  <Box sx={{ justifyContent: 'center' }}>
                    <Skeleton sx={{ height: 35, mx: 4, mt: 12 }} animation="wave" variant="rounded" />
                  </Box>
                </Card>
              </Grid>
            ))}
          </>
        ) : (
          <>
            {freeCourses?.map((course: any, index: any) => {
              const isSameCourse = userCourses?.find((element: any) => element?.courseId?._id === course?._id) || null;
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Card
                    key={index}
                    sx={{
                      height: 460,
                      border: '1px solid #E8EBEE',
                      boxShadow: '0 0 5px 2px rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    <Link
                      to={user?.type === 'student' ? '/learner/course/info' : '/course/catalog/info'}
                      state={{
                        rowData: course._id,
                        section: course?.section?.length,
                        content: course?.contentInfo?.length,
                        createdBy: course?.createdBy?._id,
                        completedContent: isSameCourse?.completedContent,
                        inprogressContent: isSameCourse?.inprogressContent,
                        inprogressSection: isSameCourse?.inprogressSection,
                        inprogressAssessment: isSameCourse?.inprogressAssessment,
                        completeAssessments: isSameCourse?.completeAssessments
                      }}
                      // state={
                      // completedContent: item?.completedContent,
                      // inprogressContent: item?.inprogressContent,
                      // inprogressSection: item?.inprogressSection,
                      // inprogressAssessment: item?.inprogressAssessment,
                      // completeAssessments: item?.completeAssessments
                      // }}

                      className="links"
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {course?.image && (course?.image?.file || course?.image?.path) ? (
                        <img
                          style={{
                            width: '100%',
                            height: '200px',
                            objectFit: 'fill'
                          }}
                          src={course?.image?.path ? process.env.REACT_APP_FILE_URL + course?.image?.path : `data:${course?.image?.mimetype};base64,${course?.image?.file}`}
                          title={course?.image?.filename}
                          alt={course?.image?.filename}
                        />
                      ) : (
                        <img
                          style={{
                            width: '100%',
                            height: '200px',
                            justifyContent: 'center'
                          }}
                          src={user?.organizationId?.basicsettings?.identity?.logo?.path ? process.env.REACT_APP_FILE_URL + user?.organizationId?.basicsettings?.identity?.logo?.path : `data:${user?.organizationId?.basicsettings?.identity?.logo?.mimetype};base64,${user?.organizationId?.basicsettings?.identity?.logo?.file}`}
                          title={user?.organizationId?.basicsettings?.identity?.logo?.filename}
                          alt={user?.organizationId?.basicsettings?.identity?.logo?.filename}
                        />
                      )}
                    </Link>
                    <CardContent>
                      <Link
                        to={user?.type === 'student' ? '/learner/course/info' : '/course/catalog/info'}
                        state={{
                          rowData: course._id,
                          section: course?.section?.length,
                          content: course?.contentInfo?.length,
                          createdBy: course?.createdBy?._id
                        }}
                        className="links"
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        >
                          {course.name}
                        </Typography>

                        <Typography variant="body2" color="text.secondary">
                          A Course By {course?.createdBy?.firstName} {course?.createdBy?.lastName}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            position: 'relative',
                            maxHeight: '45px',
                            minHeight: '45px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            color: 'text.primary'
                          }}
                        >
                          {course?.description}
                          <span
                            style={{
                              position: 'absolute',
                              bottom: 0,
                              right: 0,
                              padding: '0 1rem',
                              background: 'linear-gradient(to right, rgba(255, 255, 255, 0), #FFF 50%)'
                            }}
                          ></span>
                        </Typography>
                      </Link>
                    </CardContent>
                    <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button
                        size="small"
                        sx={{
                          color: '#B9962E',
                          userSelect: 'none',
                          cursor: 'default',
                          '&:hover': {
                            backgroundColor: 'transparent',
                            color: '#B9962E'
                          },
                          marginRight: '8px'
                        }}
                      >
                        <Star1 size="32" variant="Bulk" color="#F7B900" style={{ marginRight: '2px' }} />
                        {course?.courseAvgRating?.ratingCount > 0
                          ? (course?.courseAvgRating?.ratingCount).toFixed(1)
                          : course.courseAvgRating}
                      </Button>
                      <Button
                        size="small"
                        sx={{
                          color: '#218822',
                          userSelect: 'none',
                          cursor: 'default',
                          '&:hover': {
                            backgroundColor: 'transparent',
                            color: '#218822'
                          },
                          marginRight: '8px'
                        }}
                      >
                        <UserTick size="32" variant="Bulk" color="#218822" style={{ marginRight: '2px' }} />
                        {course?.userCount}
                      </Button>
                    </CardActions>
                    <CardActions sx={{ justifyContent: 'center' }}>
                      {course?.section?.length === 0 ? (
                        <Typography border={'1px solid gray'} py={0.7} px={5} borderRadius={1.5} sx={{ userSelect: 'none' }}>
                          No content Availabe
                        </Typography>
                      ) : (
                        <Button
                          variant="outlined"
                          color={isSameCourse?.inprogressSection ? 'success' : 'primary'}
                          disabled={course?.section?.length === 0}
                          sx={{
                            px: 5,
                            py: 0.5,
                            borderRadius: 1.5,
                            '&:hover': {
                              backgroundColor: isSameCourse?.inprogressSection ? '#2CA87F' : '#4680FF',
                              color: 'white'
                            }
                          }}
                          onClick={() => {
                            navigate('/learner/mylearning/course', {
                              state: {
                                courseId: course?._id,
                                userId: user?._id,
                                sectionId: isSameCourse?.inprogressSection ? isSameCourse?.inprogressSection : course?.section?.[0]?._id,
                                contentId: isSameCourse?.inprogressContent
                                  ? isSameCourse?.inprogressContent
                                  : course?.section?.[0]?.contentId?.[0]?._id,
                                assessmentId: isSameCourse?.inprogressAssessment
                                  ? isSameCourse?.inprogressAssessment
                                  : course?.section?.[0]?.assessmentId
                              }
                            });
                          }}
                        >
                          {isSameCourse?.inprogressSection ? 'Continue Learning' : 'Start Now'}
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </>
        )}
      </Grid>
      {freeCourses?.length < totalCount ? (
        <Grid xs={12} sx={{ mt: 3 }}>
          <DummyButton handleLength={handleLength}>Loading...</DummyButton>
        </Grid>
      ) : (
        <></>
      )}
    </MainCard>
  );
}

export default FreeCoursesList;
