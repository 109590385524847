import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  quizGroups: [],
  search: '',
  count: 0,
  pageIndex: 1,
  pageSize: 10,
  sortingData: []
};

const QuizGroupSlice = createSlice({
  name: 'QuizGroup',
  initialState,
  reducers: {
    setQuizGroup(state, action) {
      // return {
      //   ...state,
      //   quizGroups: action.payload
      // };
      state.quizGroups = action.payload;
    },
    setQuizGroupTableCount(state, action) {
      state.count = action.payload;
    },
    searchQuizGroupTable(state, action) {
      state.search = action.payload;
    },
    setSortingValue(state, action) {
      state.sortingData = action.payload;
    },
    setQuizGroupTablePageIndex(state, action) {
      state.pageIndex = action.payload;
    },
    setQuizGroupTablePageSize(state, action) {
      state.pageSize = action.payload;
    },
    delQuizGroup(state, action) {
      const arr = state.quizGroups.filter((item: { _id: any }) => item._id !== action.payload);
      return {
        ...state,
        quizGroups: arr
      };
    },
    // setSingleQuizGroup(state, action) {
    //   const updatedGroup = state.quizGroups.map((item: any) => {
    //     if (item._id === action.payload._id) return action.payload;
    //     return item;
    //   });
    //   state.quizGroups = updatedGroup;
    // },
    resetStateQuizGroup: (state) => {
      return initialState;
    },
  }
});

export const {
  setQuizGroup,
  setSortingValue,
  setQuizGroupTablePageIndex,
  setQuizGroupTableCount,
  setQuizGroupTablePageSize,
  delQuizGroup,
  // setSingleQuizGroup,
  searchQuizGroupTable,
  resetStateQuizGroup
} = QuizGroupSlice.actions;

export default QuizGroupSlice.reducer;
