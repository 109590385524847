import {
  Button,
  CardActions,
  CardContent,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  RadioGroup,
  Stack,
  TextField,
  Radio,
  Typography,
  Select,
  MenuItem,
  Box,
  SelectChangeEvent,
  FormHelperText
} from '@mui/material';
import MainCard from 'components/MainCard';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { Add } from 'iconsax-react';
import { useLocation, useNavigate } from 'react-router';
import useAuth from 'hooks/useAuth';
import { addGroup } from 'services/group.service';
import Snackbar from 'utils/Snackbar';
import BackToggle from 'components/third-party/dropzone/BackToggle';
import { FormattedMessage } from 'react-intl';
import useConfig from 'hooks/useConfig';
import { getLocaleName } from 'utils/helper';
import { useSelector } from 'react-redux';
import { getAllClassRoom } from 'services/classRoom.service';
import { addQuizGroup, updateQuizGroup } from 'services/quiz.service';

interface Package {
  duration: 'monthly' | 'quarterly' | 'yearly' | 'lifetime';
  price: number;
}

function AddQuizGroup() {
  const location = useLocation();
  const { i18n } = useConfig();
  const rowData = location.state?.rowData || {};
  const { user, organization }: any = useAuth();
  const navigate = useNavigate();
  const orgData = useSelector((state: any) => state.organization);
  const organizationId = user && user.organizationId ? user.organizationId._id : null;
  const [submissionStatus, setSubmissionStatus] = useState<string | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [classTypeOption, setClassTypeOption] = useState<Array<any>>([]);
  const [PlaceValues, setPlaceValues] = useState<any>();
  const [subject, setSubject] = useState([]);
  const isFromVidyalaya = organization?.vidyalayaMigration;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };
  useEffect(() => {
    getLocaleName(i18n)
      .then((result) => {
        setPlaceValues(result);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [i18n]);
  // Retrieve the stored data from local storage
  const storedData = localStorage.getItem('quizGroupData');
  let parsedData: any = {};

  // Parse the stored data back into an object
  if (storedData) {
    const quizGroupData = JSON.parse(storedData);
    // Check if the stored user ID matches the current user's ID
    if (quizGroupData.userId === user?._id) {
      parsedData = storedData === undefined ? {} : JSON.parse(storedData);
    }
  }
  localStorage.setItem('quizGroupData', JSON.stringify({ ...parsedData, userId: user?._id }));
  console.log('rowData==>', rowData);
  const formik: any = useFormik({
    initialValues: {
      name: rowData && rowData.name ? rowData.name : parsedData?.name || '',
      organizationId: user?.organizationId?._id,
      classType: rowData && rowData.classType ? rowData.classType : parsedData?.classType || '',
      classRoomId: rowData?.classRoomId?.classType === 'class' ? rowData?.classRoomId?._id : '',
      semester: rowData?.classRoomId?.classType === 'semester' ? rowData?.classRoomId?._id : '',
      otherName: rowData?.classRoomId?.classType === 'other' ? rowData?.classRoomId?._id : '',
      streamName: rowData?.classRoomId?.classType === 'semester' ? rowData?.classRoomId?._id : '',
      subjectId: rowData && rowData.subjectId ? rowData.subjectId : parsedData?.subjectId || '',
      board: rowData && rowData.board ? rowData.board : parsedData?.board || '',
      monthly: rowData && rowData?.packages?.[0]?.price ? rowData?.packages?.[0]?.price : parsedData?.monthly || '',
      quarterly: rowData && rowData?.packages?.[1]?.price ? rowData?.packages?.[1]?.price : parsedData?.quarterly || '',
      yearly: rowData && rowData?.packages?.[2]?.price ? rowData?.packages?.[2]?.price : parsedData?.yearly || '',
      lifetime: rowData && rowData?.packages?.[3]?.price ? rowData?.packages?.[3]?.price : parsedData?.lifetime || ''
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .min(3, PlaceValues?.['Name should be at least 3 characters long']) // Corrected message
        .max(50, PlaceValues?.['Name should be a maximum of 80 characters long'])
        .required(PlaceValues?.['Name is required'])
        .matches(/^[a-zA-Z0-9\s]+$/, 'Name cannot contain special characters')
        .test('no-spaces', 'Name cannot start or end with a space.', (value) => {
          return value ? !/^\s|\s$/.test(value) : true;
        })
    }),
    onSubmit: async (values, { resetForm }) => {
      console.log('Submitttt values', values);

      // Transform prices into the required packages format
        const packages: any = Object.entries(values).reduce((acc, [key, value]) => {
          if (['monthly', 'quarterly', 'yearly', 'lifetime'].includes(key) && value) {
            acc.push({ duration: key, price: parseFloat(value) });
          }
          return acc;
        }, []);

      if (user?.type !== 'superadmin') {
        if (!values.classType) {
          Snackbar('Please select a Class type.', 'error');
          return;
        }

        // Additional validation based on selected classType

        if (values.classType === 'class' && !values.classRoomId) {
          Snackbar('Please select a Classroom.', 'error');
          return;
        }
        if (values.classType === 'semester' && (!values.semester || !values.streamName)) {
          Snackbar('Please select a Semester and Stream Name.', 'error');
          return;
        }
        if (values.classType === 'batch' && !values.otherName) {
          Snackbar('Please select a Batch.', 'error');
          return;
        }
      }
      setIsButtonDisabled(true);
      if (!values) {
        setSubmissionStatus('error');
      } else {
        let addQuizGroupData: any = {
          name: values.name.trim()
        };
        if (rowData) {
          addQuizGroupData.questionBankId = rowData?._id;
        }
        if (values.classType) {
          addQuizGroupData.classType = values.classType;
        }
        if (values.subjectId) {
          addQuizGroupData.subjectId = values.subjectId;
        }
        if (organizationId) {
          addQuizGroupData.organizationId = organizationId;
        }
        if (values.board) {
          addQuizGroupData.board = values.board;
        }
        if (packages && user?.type === 'superadmin') {
          addQuizGroupData.packages = packages;
        }
        // if (values.price) {
        //   addQuizGroupData.price = values.price;
        // }
        if (values.classType === 'class') {
          addQuizGroupData.classType = values.classType;
          if (values.classRoomId) addQuizGroupData.classRoomId = values.classRoomId;
        }
        if (values.classType === 'semester') {
          addQuizGroupData.classType = values.classType;
          addQuizGroupData.classRoomId = values.streamName || values.semester;
        }
        if (values.classType === 'other') {
          addQuizGroupData.classType = values.classType;
          addQuizGroupData.classRoomId = values.otherName;
        }
        try {
          if (!rowData || Object.keys(rowData).length === 0) {
            addQuizGroup(addQuizGroupData)
              .then((res) => {
                if (res.status === 201 || res.status === 200) {
                  setIsButtonDisabled(false);
                  Snackbar(res.data.message ? res.data.message : 'QuizGroup added successfully', 'success');
                  resetForm();
                  setSubmissionStatus('success');
                  localStorage.removeItem('quizGroupData');
                  navigate(user?.type === 'superadmin' ? '/superadmin/question-group' : '/question-group');
                  //   navigate('/group/edit', {
                  //     state: {
                  //       rowData: res.data.data,
                  //       tabIndex: 1
                  //     },
                  //     replace: true
                  //   });
                } else {
                  Snackbar(res.data.message ? res.data.message : 'ERROR', 'error');
                  setIsButtonDisabled(false);
                }
              })
              .catch((err) => {
                setIsButtonDisabled(false);

                Snackbar(err.message ? err.message : 'ERROR', 'error');
              });
          } else if (rowData) {
            updateQuizGroup(addQuizGroupData)
              .then((res) => {
                if (res.status === 201 || res.status === 200) {
                  setIsButtonDisabled(false);
                  Snackbar(res.data.message ? res.data.message : 'QuizGroup Edited successfully', 'success');
                  resetForm();
                  setSubmissionStatus('success');
                  localStorage.removeItem('quizGroupData');
                  navigate(user?.type === 'superadmin' ? '/superadmin/question-group' : '/question-group');
                } else {
                  Snackbar(res.data.message ? res.data.message : 'ERROR', 'error');
                  setIsButtonDisabled(false);
                }
              })
              .catch((err) => {
                setIsButtonDisabled(false);
                Snackbar(err.message ? err.message : 'ERROR', 'error');
              });
          }
        } catch (error) {
          Snackbar('Error, while performing operation', 'error');
          setIsButtonDisabled(false);
        }
      }
    }
  });
  useEffect(() => {
    if (Object.keys(rowData).length != 0) {
      const data = classTypeOption.filter((val: { _id: string }) => val?._id === rowData?.classRoomId?._id);
      setSubject(data?.[0]?.subjects);
    }
    // const editSubjectId = formik.values.subjectId;
    //   setSelectedSubject(editSubjectId ? editSubjectId : '');
  }, [rowData, classTypeOption]);
  useEffect(() => {
    if (user?.type !== 'superadmin') {
      getAllClassRoom({
        organizationId: user?.organizationId?._id,
        classType: formik.values.classType ? formik.values.classType : 'class'
      }).then((res) => {
        if (res.status === 200)
          setClassTypeOption(
            res.data.data?.classRoomData?.map((item: any) => {
              return formik.values.classType === 'class'
                ? { _id: item?._id, className: item?.className || item?.class, subjects: item?.subjects, classType: item?.classType }
                : formik.values.classType === 'semester'
                ? {
                    _id: item?._id,
                    semester: item?.semester,
                    streamName: item?.streamName || item?.className,
                    subjects: item?.subjects,
                    classType: item?.classType
                  }
                : formik.values.classType === 'other'
                ? { _id: item?._id, otherName: item?.className, subjects: item?.subjects, classType: item?.classType }
                : {};
            })
          );
      });
    }
  }, [formik.values.classType]);
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MainCard
          content={false}
          title={
            <span style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
                <BackToggle show={true} />
              </span>
              <span style={{ marginLeft: 10, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h5">
                  {<FormattedMessage id={rowData && Object.keys(rowData).length != 0 ? 'Edit question Group' : 'Add question Group'} />}
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="Here you can Add Group with its details." />
                </Typography>
              </span>
            </span>
          }
        >
          <form onSubmit={formik.handleSubmit}>
            {submissionStatus === 'error' && (
              <Stack spacing={1} sx={{ py: 1, backgroundColor: '#ffe6e6', pl: 2 }}>
                <Typography variant="subtitle1" color="error">
                  There was an error submitting the form. Please try again later.
                </Typography>
              </Stack>
            )}
            <CardContent>
              <Stack spacing={1} sx={{ py: 1 }}>
                <InputLabel htmlFor="name">
                  <FormattedMessage id="Name" />
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={
                    i18n === 'fr'
                      ? `par exemple. Cours de démarrage`
                      : i18n === 'ro'
                      ? `de exemplu. Cursuri de pornire`
                      : i18n === 'zh'
                      ? `例如创业课程 `
                      : `e.g. Startup Courses`
                  }
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={(e) => {
                    formik.handleBlur(e); // Ensure Formik's onBlur is called
                    // Store the value in local storage
                    localStorage.setItem('quizGroupData', JSON.stringify({ ...parsedData, name: formik.values.name }));
                  }}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  // @ts-ignore
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Stack>

              {user?.type !== 'superadmin' && (
                <Grid container spacing={2} sx={{ mt: 0.5 }}>
                  <Grid item xs={12} sm={1.5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <InputLabel>
                      <FormattedMessage id="ClassType" />
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <RadioGroup aria-label="size" name="radio-buttons-group" defaultValue={formik.values.classType} row>
                      <FormControlLabel
                        value="class"
                        checked={formik.values.classType === 'class'}
                        control={<Radio />}
                        label="class"
                        disabled={user?.organizationId?.allowClassTypes ? user?.organizationId?.allowClassTypes !== 'class' : false}
                        onClick={(e: any) => {
                          formik.setFieldValue('classType', e.target.value);
                        }}
                      />
                      <FormControlLabel
                        value="semester"
                        checked={formik.values.classType === 'semester'}
                        control={<Radio />}
                        label="semester"
                        disabled={user?.organizationId?.allowClassTypes ? user?.organizationId?.allowClassTypes !== 'semester' : false}
                        onClick={(e: any) => {
                          formik.setFieldValue('classType', e.target.value);
                        }}
                      />
                      <FormControlLabel
                        value="other"
                        checked={formik.values.classType === 'other'}
                        control={<Radio />}
                        label="other"
                        disabled={user?.organizationId?.allowClassTypes ? user?.organizationId?.allowClassTypes !== 'other' : false}
                        onClick={(e: any) => {
                          formik.setFieldValue('classType', e.target.value);
                        }}
                      />
                    </RadioGroup>
                  </Grid>
                  {formik.values.classType === 'class' && (
                    <Grid item xs={12} sm={5}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="classRoomId">Classroom Name</InputLabel>
                        <Select
                          error={formik.touched.classRoomId && Boolean(formik.errors.classRoomId)}
                          fullWidth
                          id="classRoomId"
                          value={formik.values.classRoomId}
                          name="classRoomId"
                          onChange={(event: SelectChangeEvent<any>) => {
                            const selectedValues = event.target.value;
                            if (selectedValues.includes('custom')) {
                              navigate('/classroom/add');
                            } else {
                              formik.setFieldValue('classRoomId', selectedValues);
                              const data = classTypeOption.filter((val: { _id: string }) => val?._id === selectedValues);
                              setSubject(data?.[0]?.subjects);
                              formik.setFieldValue('subjectId', '');
                            }
                          }}
                          MenuProps={MenuProps}
                        >
                          <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                            <Add />
                            <FormattedMessage id="Add Class" />
                          </MenuItem>
                          {classTypeOption?.map((value: any) => {
                            return <MenuItem value={value._id}>{value.className}</MenuItem>;
                          })}
                        </Select>
                      </Stack>
                    </Grid>
                  )}
                  {formik.values.classType === 'semester' && (
                    <Grid item xs={12} sm={3.25}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="classRoomId">Semester Name</InputLabel>
                        <Select
                          error={formik.touched.semester && Boolean(formik.errors.semester)}
                          fullWidth
                          id="semester"
                          value={formik.values.semester}
                          name="semester"
                          onChange={(event: SelectChangeEvent<any>) => {
                            const selectedValues = event.target.value;
                            if (selectedValues.includes('custom')) {
                              navigate('/classroom/add');
                            } else {
                              formik.setFieldValue('semester', selectedValues);
                              const data = classTypeOption.filter((val: { _id: string }) => val?._id === selectedValues);
                              setSubject(data?.[0]?.subjects);
                              formik.setFieldValue('subjectId', '');
                            }
                          }}
                          MenuProps={MenuProps}
                        >
                          <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                            <Add />
                            <FormattedMessage id="Add Class" />
                          </MenuItem>
                          {classTypeOption?.map((value: any) => {
                            return <MenuItem value={value._id}>{value.semester}</MenuItem>;
                          })}
                        </Select>
                      </Stack>
                    </Grid>
                  )}
                  {formik.values.classType === 'semester' && (
                    <Grid item xs={12} sm={3.25}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="streamName">Learner's stream name</InputLabel>
                        <Select
                          error={formik.touched.streamName && Boolean(formik.errors.streamName)}
                          fullWidth
                          id="streamName"
                          value={formik.values.streamName}
                          name="streamName"
                          onChange={(event: SelectChangeEvent<any>) => {
                            const selectedValues = event.target.value;
                            if (selectedValues.includes('custom')) {
                              navigate('/classroom/add');
                            } else {
                              formik.setFieldValue('streamName', selectedValues);
                            }
                          }}
                          MenuProps={MenuProps}
                        >
                          {/* <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
              <Add />
              <FormattedMessage id="Add streamName" />
            </MenuItem> */}
                          {classTypeOption?.map((value: any) => {
                            if (formik.values.semester === value._id) return <MenuItem value={value._id}>{value.streamName}</MenuItem>;
                          })}
                        </Select>
                        <Box sx={{ color: 'red' }}>{formik.touched.streamName && formik.errors.streamName}</Box>
                      </Stack>
                    </Grid>
                  )}
                  {formik.values.classType === 'other' && (
                    <Grid item xs={12} sm={5}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="otherName">Learner's other classType name</InputLabel>
                        <Select
                          labelId="otherName"
                          id="otherName"
                          value={formik.values?.otherName ? formik.values.otherName : ''}
                          onChange={(event: SelectChangeEvent<any>) => {
                            const selectedValues = event.target.value;
                            if (selectedValues.includes('custom')) {
                              navigate('/classroom/add');
                            } else {
                              formik.setFieldValue('otherName', selectedValues);
                              const data = classTypeOption.filter((val: { _id: string }) => val?._id === selectedValues);
                              setSubject(data?.[0]?.subjects);
                              formik.setFieldValue('subjectId', '');
                            }
                          }}
                        >
                          <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                            <Add />
                            <FormattedMessage id="Add other classType" />
                          </MenuItem>
                          {classTypeOption?.map((value: any) => (
                            <MenuItem value={value._id}>{value.otherName}</MenuItem>
                          ))}
                        </Select>
                        <Box sx={{ color: 'red' }}>{formik.touched.otherName && formik.errors.otherName}</Box>
                      </Stack>
                    </Grid>
                  )}
                  {(formik.values.classRoomId || formik.values.semester || formik.values.otherName) && formik.values.classType && (
                    <Grid item xs={12} sm={4}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="subjectId">Subject Name</InputLabel>
                        <Select
                          error={formik.touched.subjectId && Boolean(formik.errors.subjectId)}
                          fullWidth
                          id="subjectId"
                          value={formik.values.subjectId}
                          name="subjectId"
                          onBlur={(e: any) => {
                            formik.handleBlur(e);
                            localStorage.setItem('quizGroupData', JSON.stringify({ ...parsedData, subjectId: e.target.value }));
                          }}
                          onChange={(event: SelectChangeEvent<any>) => {
                            const selectedValues = event.target.value;
                            if (selectedValues.includes('custom')) {
                              navigate('/subject/add');
                            } else {
                              formik.setFieldValue('subjectId', selectedValues);
                            }
                          }}
                          // onBlur={(e) => {
                          //   // handleBlur(e);
                          //   if (!rowData?.firstName) {
                          //     parsedData.semester = formik.values.semester;
                          //     localStorage.setItem('userData', JSON.stringify(parsedData));
                          //   }
                          // }}
                          MenuProps={MenuProps}
                        >
                          {(formik.values.classRoomId || formik.values.semester || formik.values.otherName) && (
                            <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                              <Add />
                              <FormattedMessage id="Add Subject" />
                            </MenuItem>
                          )}
                          {subject?.map((item: any) => {
                            return <MenuItem value={item?._id}>{item?.subjectName}</MenuItem>;
                          })}
                        </Select>
                        {formik.touched.subjectId && formik.errors.subjectId && (
                          <FormHelperText error>{formik.errors.subjectId}</FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              )}

              {user?.type === 'superadmin' && (
                <Grid container spacing={2} sx={{ mt: 0.5 }}>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="board">
                        <FormattedMessage id="Board" />
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="board"
                        name="board"
                        placeholder={PlaceValues?.['Enter board']}
                        value={formik.values.board}
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                          formik.handleBlur(e); // Ensure Formik's onBlur is called
                          // Store the value in local storage
                          if (!rowData) {
                            localStorage.setItem('quizGroupData', JSON.stringify({ ...parsedData, board: formik.values.board }));
                          }
                        }}
                        error={formik.touched.board && Boolean(formik.errors.board)}
                        // @ts-ignore
                        helperText={formik.touched.board && formik.errors.board}
                      />
                    </Stack>
                  </Grid>

                  {/* <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="price">
                        <FormattedMessage id="Price" />
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="price"
                        name="price"
                        placeholder={PlaceValues?.['Enter price']}
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                          formik.handleBlur(e); // Ensure Formik's onBlur is called
                          // Store the value in local storage
                          if (!rowData) {
                            localStorage.setItem('quizGroupData', JSON.stringify({ ...parsedData, price: formik.values.price }));
                          }
                        }}
                        error={formik.touched.price && Boolean(formik.errors.price)}
                        // @ts-ignore
                        helperText={formik.touched.price && formik.errors.price}
                      />
                    </Stack>
                  </Grid> */}

                  <Grid item xs={12} md={6}>
                    <Stack spacing={2}>
                      <InputLabel htmlFor="prices">
                        <FormattedMessage id="Prices" />
                      </InputLabel>

                      {/* Monthly Price */}
                      <TextField
                        fullWidth
                        id="price-monthly"
                        name="monthly"
                        label="Monthly Price"
                        placeholder={PlaceValues?.['Enter monthly price']}
                        value={formik.values.monthly || ''}
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          // Store the value in local storage
                          if (!rowData) {
                            localStorage.setItem('quizGroupData', JSON.stringify({ ...parsedData, prices: formik.values.prices }));
                          }
                        }}
                        error={formik.touched.monthly && Boolean(formik.errors.monthly)}
                        helperText={formik.touched.monthly && formik.errors.monthly}
                      />

                      {/* Quarterly Price */}
                      <TextField
                        fullWidth
                        id="price-quarterly"
                        name="quarterly"
                        label="Quarterly Price"
                        placeholder={PlaceValues?.['Enter quarterly price']}
                        value={formik.values.quarterly || ''}
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          if (!rowData) {
                            localStorage.setItem('quizGroupData', JSON.stringify({ ...parsedData, prices: formik.values.prices }));
                          }
                        }}
                        error={formik.touched.quarterly && Boolean(formik.errors.quarterly)}
                        helperText={formik.touched.quarterly && formik.errors.quarterly}
                      />

                      {/* Yearly Price */}
                      <TextField
                        fullWidth
                        id="price-yearly"
                        name="yearly"
                        label="Yearly Price"
                        placeholder={PlaceValues?.['Enter yearly price']}
                        value={formik.values.yearly || ''}
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          if (!rowData) {
                            localStorage.setItem('quizGroupData', JSON.stringify({ ...parsedData, prices: formik.values.prices }));
                          }
                        }}
                        error={formik.touched.yearly && Boolean(formik.errors.yearly)}
                        helperText={formik.touched.yearly && formik.errors.yearly}
                      />

                      {/* Lifetime Price */}
                      <TextField
                        fullWidth
                        id="price-lifetime"
                        name="lifetime"
                        label="Lifetime Price"
                        placeholder={PlaceValues?.['Enter lifetime price']}
                        value={formik.values.lifetime || ''}
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          if (!rowData) {
                            localStorage.setItem('quizGroupData', JSON.stringify({ ...parsedData, prices: formik.values.prices }));
                          }
                        }}
                        error={formik.touched.lifetime && Boolean(formik.errors.lifetime)}
                        helperText={formik.touched.lifetime && formik.errors.lifetime}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </CardContent>

            <Divider />

            {/* <CardActions> */}
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ p: 1.5 }}>
              <Button
                sx={{
                  ml: 1,
                  fontSize: { xs: '12px', sm: '14px' },
                  padding: { xs: '4px 8px', sm: '6px 12px' },
                  ':hover': {
                    backgroundColor: '#3c6dd9'
                  }
                }}
                variant="contained"
                size="small"
                type="submit"
                disabled={isButtonDisabled}
              >
                <FormattedMessage id={rowData && Object.keys(rowData).length != 0 ? 'Edit' : 'Add'} />
              </Button>
              <Button
                sx={{
                  fontSize: { xs: '12px', sm: '14px' },
                  padding: { xs: '3.5px 10px', sm: '4.5px 10px' },
                  ':hover': {
                    color: 'white',
                    backgroundColor: '#dc2626'
                  }
                }}
                variant="outlined"
                color="error"
                size="small"
                onClick={() => navigate(-1)}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </Stack>
            {/* </CardActions> */}
            {submissionStatus === 'error' && <p style={{ color: 'red', marginLeft: '15px' }}> An error occurred</p>}
          </form>
        </MainCard>
      </Grid>
    </Grid>
  );
}

export default AddQuizGroup;
