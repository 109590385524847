import { useEffect, useState } from 'react';

// material-ui
import { Box, Drawer, Grid, Stack, Tooltip, Typography } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import SimpleBar from 'components/third-party/SimpleBar';
import MessageCard from 'components/cards/statistics/MessageCard';

// assets
import { CloseSquare, VolumeHigh } from 'iconsax-react';
import { getAllAnnouncements } from 'services/announcement.service';
import useAuth from 'hooks/useAuth';
import '../../../../pages/Settings/Style/themes.scss';
import Snackbar from 'utils/Snackbar';
import Loader2 from 'components/Loader2';
import NoAnnouncement from '../../../../assets/images/NoAnnouncement.svg';
import DummyButton from './DummyButton';
import { FormattedMessage } from 'react-intl';
// ==============================|| HEADER CONTENT - CUSTOMIZATION ||============================== //

const Customization = () => {
  // const theme = useTheme();

  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };
  const { user }: any = useAuth();

  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [length, setLength] = useState<number>(5);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const iconBackColorOpen = theme.palette.mode === 'dark' ? 'secondary.200' : 'secondary.200';
  // const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'secondary.100';

  function getAnouncementDetail() {
    if (user?.organizationId?._id) {
      getAllAnnouncements({ orgId: user?.organizationId?._id, limit: 5, page: page, classroomId: user?.classRoomId?._id })
        .then((result) => {
          setData([...data, ...result?.data?.data?.announcementData]);
          setCount(result?.data?.data?.count);
          setLoading(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setLoading(false);
          if (err?.response?.status === 403) {
            const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
            Snackbar(errorMessage, 'warning');
          } else {
            const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
            Snackbar(errorMessage, 'error');
          }
        });
    }
  }

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      getAnouncementDetail();
    }
  }, [page]);

  const handleLength = () => {
    if (!isLoading) {
      setPage(page + 1);
      setLength(length + 5);
    }
  };
  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.75 }}>
        <Tooltip title={<FormattedMessage id="Announcement" />}>
          <IconButton
            onClick={handleToggle}
            sx={{
              width: user?.type === 'student' ? '32px' : '40px',
              height: user?.type === 'student' ? '32px' : '40px',
              border: user?.type === 'student' ? '1px solid white' : 'none',
              color: 'secondary.main',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: user?.type === 'student' ? '#0f8a7e' : 'transparent',
              },
              p: 0.75,
            }}
          >
            <VolumeHigh color={user?.type === 'student' ? 'white' : undefined} className="mainlogoicon" variant={user?.type === 'student' ? undefined : "Bulk"} size={26} />
          </IconButton>
        </Tooltip>
      </Box>
      <Drawer sx={{ zIndex: 2001 }} anchor="right" onClose={handleToggle} open={open} PaperProps={{ sx: { width: { xs: 350, sm: 474 } } }}>
        {open && (
          <MainCard content={false} sx={{ border: 'none', borderRadius: 0, height: '100vh' }}>
            <SimpleBar
              sx={{
                '& .simplebar-content': {
                  display: 'flex',
                  flexDirection: 'column'
                }
              }}
            >
              <Box sx={{ p: 2.5 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5}>
                  <Typography variant="h5">What’s new announcement?</Typography>
                  <IconButton variant='text' color="secondary" sx={{ p: 0 }} onClick={handleToggle}>
                    <CloseSquare size={32} variant='Bulk' />
                  </IconButton>
                </Stack>
                {data && data.length > 0 ? (
                  <Grid container spacing={1.5} sx={{ mt: 2 }}>
                    {data.map((data: any) => (
                      <Grid item xs={12}>
                        <MessageCard
                          title={data?.title}
                          message={data?.description}
                          src={
                            data?.courseId?.image
                              ? 'data:' + data?.courseId?.image?.mimetype + ';base64,' + data?.courseId?.image?.file
                              : null
                          }
                          name={data?.userId?.firstName + ' ' + data?.userId?.lastName}
                          courseName={data?.courseId?.name}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Stack alignItems="center" justifyContent="center" sx={{ mt: 5 }}>
                    <Box
                      component="img"
                      src={NoAnnouncement} // Replace with the path to your image
                      alt="No Announcements"
                      sx={{ width: 200, height: 200, mb: 2 }}
                    />
                    <Typography variant="h5" color="textSecondary" sx={{ fontWeight: 600 }}>
                      No announcements found!
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      Stay tuned for upcoming announcements.
                    </Typography>
                  </Stack>
                )}

                {loading ? (
                  <Grid xs={12}>
                    <Loader2 />
                  </Grid>
                ) : null}
                {count > data?.length ? (
                  <Grid xs={12} sx={{ mt: 3 }}>
                    <DummyButton handleLength={handleLength}>Loading...</DummyButton>
                  </Grid>
                ) : null}
              </Box>
            </SimpleBar>
          </MainCard>
        )}
      </Drawer>
    </>
  );
};

export default Customization;
