import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    whiteboards: [],
    ViewWhiteboards: [],
    search: '',
    pageIndex: 1,
    pageSize: 10
};

const whiteboardSlice = createSlice({
    name: 'whiteboards',
    initialState,
    reducers: {
        getWhiteboards(state, action) {
            return {
                ...state,
                whiteboards: action.payload
            };
        },
        setWhiteboardPageIndex(state, action) {
            state.pageIndex = action.payload;
        },
        setWhiteboardPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setWhiteboardSearch(state, action) {
            state.search = action.payload;
        },
        setWhiteboardStatus(state: any, action: any) {
            const whiteboardData = state.whiteboards.map((whiteboard: any) => {
                if (whiteboard._id === action.payload) return { ...whiteboard, isPublished: !whiteboard.isPublished };
                return whiteboard;
            });
            state.whiteboards = whiteboardData;
        },
        delWhiteboards(state, action) {
            return {
                ...state,
                whiteboards: state.whiteboards.filter((item: { _id: any }) => item._id !== action.payload)
            };
        },
        setViewWhiteboards(state, action) {
            return {
                ...state,
                ViewWhiteboards: action.payload
            };
        },
        resetStateWhiteboards: (state) => {
            return initialState;
        }
    }
});

export const { getWhiteboards, delWhiteboards, resetStateWhiteboards, setViewWhiteboards, setWhiteboardPageIndex, setWhiteboardPageSize, setWhiteboardSearch, setWhiteboardStatus } =
    whiteboardSlice.actions;

export default whiteboardSlice.reducer;
