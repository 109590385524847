import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import {
  setorganizationsdataPageIndex,
  setorganizationsdatastatus,
  setorganizationsdataSearch,
  setorganizationsdataTableSize,
  updateorganizationsdata,
  delorganizationsdata,
  setOrganizationTotalCount,
  resetStateorganizationsdata,
  setorganizationsdataFilterType
} from 'store/reducers/organizationsdata';
import { getAllOrganizations, organizationUpdateStatus } from 'services/superAdminDashboard.service';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Add, AddCircle, SearchNormal1, Send2 } from 'iconsax-react';
import IconButton from 'components/@extended/IconButton';
import { dispatch } from 'store';
import { useSelector } from 'react-redux';
import Snackbar from 'utils/Snackbar';
import Filters from './Filters';
import SkeletonTable from 'components/SkeletonTable';
import { FormattedMessage } from 'react-intl';
import useConfig from 'hooks/useConfig';
import CustomTable from 'components/@extended/CustomTable';
import useContemp from 'hooks/useContemp';
import { formatDateBasedOnOrgSettings } from 'utils/helper';
import useAuth from 'hooks/useAuth';
import { assignQuestionBank } from 'services/quiz.service';
import BackToggle from 'components/third-party/dropzone/BackToggle';

const AssignedOrgnizationList = () => {
  const { user, organization } = useAuth();
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const { i18n } = useConfig();
  const [value, setValue] = useState('');
  const pageIndex = useSelector((state: any) => state?.organizationsdata)?.pageIndex;
  const size = useSelector((state: any) => state?.organizationsdata)?.size;
  const search = useSelector((state: any) => state?.organizationsdata)?.search;
  const status = useSelector((state: any) => state?.organizationsdata)?.deleted;
  const orgDataSet = useSelector((state: any) => state?.organizationsdata)?.organizationsdata?.data;
  const count = useSelector((state: any) => state?.organizationsdata)?.count;
  const selectedFilter = useSelector((state: any) => state?.organizationsdata)?.filter;
  const [isLoading, setLoading] = useState(orgDataSet && orgDataSet.length > 0 ? false : true);
  const quizGroupData = location?.state?.quizGroup;

  // const [selectedFilter, setSelectedFilter] = useState(undefined);
  const { setData, prevStoredData } = useContemp();

  const handleSelectFilter = (filter: any) => {
    dispatch(setorganizationsdataFilterType(filter));
  };
  const handleSwitchClick = () => {
    dispatch(setorganizationsdatastatus(!status));
    if (pageIndex !== 1 && currentPage !== 1) {
      dispatch(setorganizationsdataPageIndex(1));
    }
  };
  const fetchOrganizations = async () => {
    setLoading(true);
    let reqData: any = {
      limit: size,
      skip: pageIndex,
      deleted: status,
      questionBankId: quizGroupData?._id
    };
    if (value) reqData.search = search;
    if (selectedFilter) reqData.type = selectedFilter;
    try {
      getAllOrganizations(reqData).then((result: any) => {
        setLoading(false);
        dispatch(updateorganizationsdata(result?.data?.data));
        dispatch(setOrganizationTotalCount(result?.data?.data?.count));
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchOrganizations();
  }, [currentPage, pageSize, status, search, selectedFilter]);

  useEffect(() => {
    if (typeof prevStoredData?.[pathname]?.currentPage === 'number')
      dispatch(setorganizationsdataPageIndex(prevStoredData?.[pathname]?.currentPage));
    if (typeof prevStoredData?.[pathname]?.pageSize === 'number')
      dispatch(setorganizationsdataTableSize(prevStoredData?.[pathname]?.pageSize));
    if (typeof prevStoredData?.[pathname]?.status === 'boolean') dispatch(setorganizationsdatastatus(prevStoredData?.[pathname]?.status));
    if (typeof prevStoredData?.[pathname]?.selectedFilter === 'string')
      dispatch(setorganizationsdataFilterType(prevStoredData?.[pathname]?.selectedFilter));
    return () => {
      dispatch(resetStateorganizationsdata());
    };
  }, [dispatch]);

  console.log('quizGroupData:::', quizGroupData);

  const navigate = useNavigate();
  const columns: any = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Admin User',
        accessor: (row: any) => (
          <>
            <Tooltip title={row?.firstAdminUser?.email}>
              <span>
                <Typography>{row?.firstAdminUser?.firstName + ' ' + row?.firstAdminUser?.lastName}</Typography>
              </span>
            </Tooltip>
          </>
        )
      },
      {
        Header: 'Type',
        accessor: 'type'
      },
      {
        Header: 'Options',
        Cell: ({ row }: { row: any }) => {
          const [isDialogOpen, setDialogOpen] = useState(false);
          const [orgId, setOrgId] = useState('');
          const [alreadyAssigned, setAlreadyAssigned] = useState(false);

          useEffect(() => {
            if (quizGroupData?.assignedTo?.some((assigned: any) => assigned.organizationId === row.original._id)) {
              setAlreadyAssigned(true);
            } else {
              setAlreadyAssigned(false);
            }
          }, [quizGroupData, row.original._id]);

          const openDialog: any = (organizationId: any) => {
            setOrgId(organizationId);
            setDialogOpen(true);
          };
          const closeDialog = () => {
            setDialogOpen(false);
          };
          const AssignQuestionsGroup = () => {
            console.log('orgId', orgId);
            let reqData = {
              questionBankId: [{ id: quizGroupData?._id }],
              organizationId: orgId
            };
            if (quizGroupData) {
              assignQuestionBank(reqData)
                .then((res) => {
                  if (res.status === 201 || res.status === 200) {
                    setAlreadyAssigned(true);
                    Snackbar(res.data.message ? res.data.message : 'QuizGroup added successfully', 'success');
                  } else {
                    Snackbar(res.data.message ? res.data.message : 'ERROR', 'error');
                  }
                })
                .catch((err) => {
                  Snackbar(err.message ? err.message : 'ERROR', 'error');
                });
            }
          };

          return (
            <>
              <Stack direction="row" spacing={0}>
                <Tooltip title="Assign Question Group">
                  <Button
                    variant="contained"
                    color={'primary'}
                    disabled={alreadyAssigned || row?.original?.assigned}
                    onClick={() => {
                      openDialog(row?.original?._id);
                    }}
                  >
                    Assign
                  </Button>
                </Tooltip>
              </Stack>

              <Dialog
                open={isDialogOpen}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <Box sx={{ p: 1, pb: 1.2, textAlign: 'center' }}>
                  <DialogTitle id="alert-dialog-title" sx={{ fontSize: '20px' }}>
                    <b>Assign to Organization</b>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">Are you sure you want to Assign this Organization ?</DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button
                      color="error"
                      sx={{
                        fontSize: { xs: '12px', sm: '14px' },
                        padding: { xs: '4px 8px', sm: '6px 12px' }
                      }}
                      variant="outlined"
                      onClick={closeDialog}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        fontSize: { xs: '12px', sm: '14px' },
                        padding: { xs: '4px 8px', sm: '6px 12px' },
                        ':hover': {
                          color: 'white',
                          backgroundColor: '#dc2626'
                        }
                      }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        AssignQuestionsGroup();
                        closeDialog();
                      }}
                      autoFocus
                    >
                      Assign
                    </Button>
                  </DialogActions>
                </Box>
              </Dialog>
            </>
          );
        }
      }
    ],
    [dispatch, currentPage, pageIndex, status, selectedFilter]
  );
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleChange = (event: SelectChangeEvent<number>) => {
    setPageSize(+event.target.value);
    setCurrentPage(1);
    dispatch(setorganizationsdataPageIndex(1));
    dispatch(setorganizationsdataTableSize(+event.target.value));
  };
  const handlePageChange = (event: any, page: number) => {
    setCurrentPage(page);
    dispatch(setorganizationsdataPageIndex(page));
  };
  const setSearchOption = () => {
    if (pageIndex !== 1 && currentPage !== 1) {
      setCurrentPage(1);
      dispatch(setorganizationsdataPageIndex(1));
    } else {
      dispatch(setorganizationsdataSearch(value));
    }
  };
  return (
    <MainCard
      title={
          <span style={{ display: 'flex', flexDirection: 'row' }}>
            <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
              <BackToggle show={true} />
            </span>
            <span style={{ marginLeft: 10, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h5">
                <FormattedMessage id={quizGroupData?.name} />
              </Typography>
              <Typography variant="body2">
                <FormattedMessage id="Here you can Assign Group with to the Organizations." />
              </Typography>
            </span>
          </span>
      }
      content={false}
      // secondary={
      //   <Tooltip title="Add Organization">
      //     <span>
      //       <IconButton
      //         variant="dashed"
      //         color="primary"
      //         onClick={() => {
      //           navigate('/superadmin/organization/add', { state: {} });
      //         }}
      //       >
      //         <AddCircle />
      //       </IconButton>
      //     </span>
      //   </Tooltip>
      // }
    >
      <>
        <Stack alignItems="center" justifyContent="space-between" direction="row" sx={{ py: 1, mx: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Assign the Group to Organizations:
          </Typography>
          <Stack alignItems="center" direction="row-reverse" sx={{ py: 1, mr: 2 }}>
            <Stack sx={{ alignItems: 'center' }} ml={2.5}>
              <Filters Filters={['public', 'private']} selectedFilter={selectedFilter} handleSelect={handleSelectFilter} />
            </Stack>
            <Stack sx={{ alignItems: 'center' }} ml={2.5}>
              <Switch checked={status} onChange={handleSwitchClick} />
              Show Deleted
            </Stack>
            <Stack>
              <Tooltip title="Search">
                <span>
                  <IconButton
                    variant="outlined"
                    color="primary"
                    sx={{ ml: 0.5 }}
                    onClick={setSearchOption}
                    disabled={!value || value === ''}
                  >
                    <SearchNormal1 />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
            <Stack>
              <TextField
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  if (e.target.value === '') {
                    dispatch(setorganizationsdataSearch(undefined));
                    dispatch(setorganizationsdataPageIndex(1));
                  }
                }}
                placeholder={
                  i18n === 'fr'
                    ? `recherche à partir de ${count || 0} enregistrements`
                    : i18n === 'ro'
                    ? `cauta din ${count || 0} inregistrari`
                    : i18n === 'zh'
                    ? `从 ${count || 0} 条记录中搜索`
                    : i18n === 'ar'
                    ? 'بحث في السجلات...'
                    : i18n === 'hindi'
                    ? '${count || 0}रिकॉर्ड खोजें...'
                    : `Search ${count || 0} records...`
                }
              />
            </Stack>
          </Stack>
        </Stack>

        <Divider />
        {isLoading ? (
          <SkeletonTable columns={columns} />
        ) : (
          <CustomTable
            columns={columns}
            data={orgDataSet || []}
            sortArray={[]}
            sort={undefined}
            setSortingData={undefined}
            count={count}
            size={size}
            pageIndex={pageIndex}
            handlePageChange={handlePageChange}
            handleSizeChange={handleChange}
          />
        )}
      </>
    </MainCard>
  );
};

export default AssignedOrgnizationList;
