import { useEffect, useState } from 'react';

// material-ui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Typography,
  List,
  FormGroup,
  Divider,
  Tooltip,
  OutlinedInput,
  Stack,
  FormControlLabel,
  Checkbox,
  Radio,
  IconButton,
  Chip,
  InputLabel,
  TextField,
  InputAdornment,
  useTheme
} from '@mui/material';
import { Calendar, CloseCircle, FilterAdd, SearchNormal1, TagCross } from 'iconsax-react';
import useConfig from 'hooks/useConfig';
import { FormattedMessage } from 'react-intl';
import { dispatch } from 'store';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import useAuth from 'hooks/useAuth';

// ==============================|| DIALOG - SIZES ||============================== //
interface FilterProps {
  type: { val1: Array<string>; val2: string };
  // data?: Array<{ label: string; value: boolean }>;
  title: string;
  count?: number;
  setFilterKey: any;
  value: any;
  handleCloseOnSearch: any;
  disabled: any;
  setDisabled: any;
  pageIndex?: any;
  selectedEndDate?: any;
  selectedStartDate?: any;
  handleApplyFilters?: any;
}

const FilterComponent = ({
  type,
  title,
  count,
  setFilterKey,
  value,
  handleCloseOnSearch,
  disabled,
  setDisabled,
  pageIndex,
  selectedStartDate,
  selectedEndDate,
  handleApplyFilters
}: FilterProps) => {
  const { i18n } = useConfig();
  const [search, setSearch] = useState('');
  const [range, setRange] = useState(selectedStartDate || selectedEndDate ? true : false);
  function capitalizeFirstLetter(str: any) {
    return str
      .split(' ')
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  const [startDate, setStartDate] = useState(selectedStartDate);
  const [endDate, setEndDate] = useState(selectedEndDate);

  // Handler for date change
  const handleDateChange = (date: any, type: any) => {
    if (type === 'start') {
      setStartDate(date);
    } else if (type === 'end') {
      setEndDate(date);
      // handleCloseOnSearch();
    }
  };

  useEffect(() => {
    endDate && setFilterKey('endDate', endDate);
    startDate && setFilterKey('startDate', startDate);
  }, [endDate, startDate]);

  // useEffect(() => {
  //   search && setFilterKey('search', search);
  // }, [search]);

  // setFilterKey('endDate', endDate);

  return (
    <>
      <Divider sx={{ my: 2 }} />
      <Typography variant="h5">{capitalizeFirstLetter(title)}</Typography>
      {typeof type === 'string' && (
        <Stack direction="row" spacing={1} sx={{ py: 1, mr: 2, justifyContent: 'left', alignItems: 'center' }} alignItems="center">
          <Stack>
            <OutlinedInput
              // value={value || search || ''}
              value={search || value || ''}
              onChange={(e) => {
                setSearch(e.target.value);
                setFilterKey('search', e.target.value);
              }}
              placeholder={
                i18n === 'fr'
                  ? `recherche à partir de ${count} enregistrements`
                  : i18n === 'ro'
                  ? `cauta din ${count} inregistrari`
                  : i18n === 'zh'
                  ? `从 ${count} 条记录中搜索`
                  : `Search ${count} records...`
              }
              id="start-adornment-email"
            />
          </Stack>
          <Stack>
            <Tooltip title={<FormattedMessage id="Search" />}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  if (search) {
                    if (pageIndex) dispatch(pageIndex(1));
                    // handleCloseOnSearch();
                    handleApplyFilters();
                  }
                }}
                sx={{ p: '9px', minWidth: 'auto' }}
              >
                <SearchNormal1 />
              </Button>
            </Tooltip>
          </Stack>
          {range ? (
            <></>
          ) : (
            <Stack>
              <Tooltip title={<FormattedMessage id="Select Range" />}>
                <Button variant="outlined" color="primary" onClick={() => setRange(true)} sx={{ p: '9px', minWidth: 'auto' }}>
                  <Calendar />
                </Button>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      )}
      {typeof type === 'string' && (
        <>
          {range ? (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h5">Range</Typography>
              <Stack direction={'row'} spacing={1} mt={1} mb={1}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack>
                    <InputLabel htmlFor="cal-startDate-date">Start Date</InputLabel>
                    <MobileDatePicker
                      value={startDate}
                      inputFormat="dd/MM/yyyy"
                      onChange={(date) => handleDateChange(date, 'start')}
                      disabled={disabled}
                      closeOnSelect={true}
                      // maxDate={endDate}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          id="cal-startDate-date"
                          placeholder="Start date"
                          fullWidth
                          // error={Boolean(touched.startDate && errors.startDate)}
                          // helperText={touched.startDate && (errors.startDate as String)}

                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Calendar />
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack>
                    <InputLabel htmlFor="cal-endDate-date">End Date</InputLabel>
                    <MobileDatePicker
                      value={endDate}
                      inputFormat="dd/MM/yyyy"
                      onChange={(date) => handleDateChange(date, 'end')}
                      closeOnSelect={true}
                      minDate={startDate}
                      disabled={!startDate}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          id="cal-endDate-date"
                          placeholder="End date"
                          fullWidth
                          // error={Boolean(formik.touched.endDate && formik.errors.endDate)}
                          // helperText={formik.touched.endDate && (formik.errors.endDate as String)}

                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Calendar style={{ color: !startDate ? 'grey' : 'inherit' }} />
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    />
                  </Stack>

                  <Stack>
                    <IconButton
                      sx={{ mt: 2.5 }}
                      onClick={() => {
                        setRange(false);
                        setStartDate(null);
                        setEndDate(null);
                        setFilterKey('startDate', null);
                        setFilterKey('endDate', null);
                      }}
                      size="large"
                      color="error"
                    >
                      <TagCross size={44} />
                    </IconButton>
                  </Stack>
                </LocalizationProvider>
              </Stack>
              <Typography variant="caption" color={'red'} sx={{mt:2}}> *note: End date can only be selected after the Start Date</Typography>
            </>
          ) : (
            <></>
          )}
        </>
      )}
      <List disablePadding component="nav" aria-label="main mailbox folders" sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {type &&
          Array.isArray(type?.val1) &&
          type?.val1 &&
          type?.val1?.length > 0 &&
          type?.val1.map((item, index: any) => (
            <Box key={index}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    key={item}
                    id={index}
                    name={title}
                    value={item}
                    control={type?.val2 === 'checkbox' ? <Checkbox disabled={disabled} /> : <Radio disabled={disabled} />}
                    label={capitalizeFirstLetter(item)}
                    labelPlacement="end"
                    sx={{ ml: 1 }}
                    checked={
                      type?.val2 === 'checkbox' ? (value?.names ? value?.names?.includes(item) : value.includes(item)) : value === item
                    }
                    onChange={(e: any) => {
                      setFilterKey(title, index);
                      if (pageIndex) dispatch(pageIndex(1));
                      setDisabled(true);
                    }}
                  />
                </FormGroup>
              </FormControl>
            </Box>
          ))}
      </List>
    </>
  );
};

export default function ModalFilter({
  filterKeywords,
  onFilterKeywordChange,
  values,
  count,
  disabled,
  setDisabled,
  pageIndex,
  applyFilter,
  filterFlag,
  filterValuesSet,
  setFilterSubmitValues,
  submittedFilterValues,
  setTabNotChange
}: {
  filterKeywords?: any;
  onFilterKeywordChange: any;
  values: any;
  count: number;
  disabled?: any;
  setDisabled?: any;
  pageIndex?: any;
  applyFilter?: any;
  filterFlag?: any;
  filterValuesSet?: any;
  setFilterSubmitValues?: any;
  submittedFilterValues?: any;
  setTabNotChange?: any;
}) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const { organization, user } = useAuth();
  const theme = useTheme();
  const primaryColor = user?.type === 'student' ? '#13AC9E' : theme.palette.primary.main;

  const handleClose = () => {
    setOpen(false);
    // setTabNotChange();
    // applyFilter(!filterFlag);
    filterValuesSet(submittedFilterValues);
  };
  const handleCloseOnSearch = () => {
    applyFilter(!filterFlag);
    setOpen(false);
    setTabNotChange && setTabNotChange();
  };
  const handleApplyFilters = () => {
    applyFilter(!filterFlag);
    setTabNotChange && setTabNotChange();
    setOpen(false);
    setFilterSubmitValues(values);
  };
  return (
    <>
      <Box sx={{ mr: 2, ml: 2, justifyContent: 'center', display: 'flex' }}>
        {/* <Tooltip title={<FormattedMessage id="Filters" />}> */}
        <Chip
          color="primary"
          variant={user?.type === 'student' ? 'filled' : 'light'}
          icon={<FilterAdd variant="Bold" />}
          onClick={handleClickOpen}
          label="Filter"
          // sx={{ py: 1, cursor: 'pointer', backgroundColor: primaryColor, "& .MuiChip-label": { color: primaryColor } }}
        />
        {/* <Button variant="outlined" color="primary" onClick={handleClickOpen} sx={{ p: '9px', minWidth: 'auto' }}>
            <Filter size="32" />
          </Button> */}
        {/* </Tooltip> */}
      </Box>
      {/* <Button sx={{ mr: 2, ml: 2, mt: 1 }} variant="contained" onClick={handleClickOpen}></Button> */}
      <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
        <IconButton
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
            background: 'transparent',
            border: 'none'
          }}
          onClick={handleClose}
        >
          <CloseCircle />
        </IconButton>
        <Box sx={{ p: 1, py: 1.5 }}>
          <DialogTitle>Filters</DialogTitle>

          <DialogContent>
            {Object.entries(filterKeywords).map(([key, value]: any) => (
              <FilterComponent
                key={key} // Make sure to add a unique key for each component in the array
                type={value}
                title={key}
                value={values[key]}
                count={count}
                setFilterKey={onFilterKeywordChange}
                handleCloseOnSearch={handleCloseOnSearch}
                disabled={disabled}
                setDisabled={setDisabled}
                pageIndex={pageIndex}
                selectedStartDate={values?.startDate}
                selectedEndDate={values?.endDate}
                handleApplyFilters={handleApplyFilters}
              />
            ))}

            {/* <FilterComponent
              type={'category'}
              title={'Search from catageory'}
              count={count}
              setFilterKey={onFilterKeywordChange}
              filterKeywords={filterKeywords}
            /> */}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleApplyFilters}>
              Apply Filter
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
