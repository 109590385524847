// render - dashboard
import { Children, lazy } from 'react';

// project-imports
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import ContentLayout from 'layout/ContentLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import QuizView from 'pages/quiz/QuizView';
import AddBadge from 'pages/badges/AddBadge';
import BadgesList from 'pages/badges/BadgesList';
import UserTypeList from 'pages/userType/userTypeList';
import BadgesAchieved from 'pages/badges/BadgesAchieved';
import useAuth from 'hooks/useAuth';
// import superadmin from 'store/reducers/superadmin';
import QuestionList from 'pages/quiz/QuestionList';
import AddExam from 'pages/Exam/AddExam';
import AddExamSection from 'pages/Exam/AddSections';
import ExamTable from 'pages/Exam/ExamTable';
import ExamStepper from 'pages/Exam/ExamStepper';
import FreeCoursesList from 'pages/quiz/FreeCoursesList';
import AddBadgeForm from 'pages/badges/NewAddBadge';
import ticket from 'store/reducers/ticket';
import AllTicket from 'pages/Support-Ticket/allTicket';
import DemoExam from 'pages/Exam/DemoExam';
import SubjectForm from 'pages/Subject/SubjectForm';
import SubjectList from 'pages/Subject/SubjectList';
import ViewBlog from 'pages/LandingPage/ViewBlog';
import LandingPageList from 'pages/superAdmin/LandingPageList';
import LandingPage from 'pages/LandingPage';
import FormRenderer from 'pages/superAdmin/LandingPageForm';
import LandingPageTabs from 'pages/superAdmin/LandingPageTabs';
import TimeLineTable from 'pages/timeline/TimeLine';
import SkillMatrixStepper from 'pages/quiz/SkillMatixStepper';
import { TabProvider } from 'contexts/TabContext';
import AddQuizGroup from 'pages/quiz/AddQuizGroup';
import VerifyCertificate from 'pages/Certificate/VerifyCertificate';
import ViewCertificate from 'pages/Certificate/viewCertificate';
import AssignedOrgnizationList from 'pages/superAdmin/AssignedOrganizations';
import CourseWhiteboard from 'pages/course/whiteboard/CourseWhiteboard';
import QuizGroupList from 'pages/quiz/quizGroupList';
import QuizGroupTabs from 'pages/quiz/QuizGroupTabs';

// import DashBoardAdminPanel from 'pages/dashboard/DashBoardAdminPanel';
const Home = Loadable(lazy(() => import('pages/CustomizeLandingPage/index')));
const PrivacyPolicy = Loadable(lazy(() => import('pages/CustomizeLandingPage/privacyPolicy')));
const TermsOfServices = Loadable(lazy(() => import('pages/CustomizeLandingPage/termsOfServices')));
const FeedbackForm = Loadable(lazy(() => import('pages/feedback/FeedbackForm')));
const FeedbackTable = Loadable(lazy(() => import('pages/feedback/FeedbackTable')));
const ContactTable = Loadable(lazy(() => import('pages/contact/ContactList')));
const SubscriptionTable = Loadable(lazy(() => import('pages/subscription/subscriptionTable')));
const SubscriptionForm = Loadable(lazy(() => import('pages/subscription/subscriptionForm')));
const StudentPackages = Loadable(lazy(() => import('pages/packages/studentPackages')));

//Email Templates
const EmailTemplateForm = Loadable(lazy(() => import('pages/EmailTemplate/EmailTemplateForm')));
const EmailTemplates = Loadable(lazy(() => import('pages/EmailTemplate/EmailTemplates')));
const AssignmentList = Loadable(lazy(() => import('pages/Assignment/AssignmentList')));
const CheckAssignment = Loadable(lazy(() => import('pages/Assignment/CheckAssignment')));
const AssignmentUserTable = Loadable(lazy(() => import('pages/course/Content/CourseAssignmentUserTable')));

//Certificate Templates
const CertificateTable = Loadable(lazy(() => import('pages/Certificate/CertificateTable')));
const UserCertificateTable = Loadable(lazy(() => import('pages/Certificate/UserCertificateTable')));
const MainCertificate = Loadable(lazy(() => import('pages/Certificate/CertificateOperations')));

// import DashBoardAdminPanel from 'pages/dashboard/DashBoardAdminPanel';
const AddQuestions = Loadable(lazy(() => import('pages/quiz/AddQuestions')));
const OverallAssessment = Loadable(lazy(() => import('pages/quiz/OverallAssessment')));
const AssessmentList = Loadable(lazy(() => import('pages/quiz/AssessmentList')));
const QuizStepper = Loadable(lazy(() => import('pages/quiz/QuizStepper')));
const QuestionTable = Loadable(lazy(() => import('pages/quiz/QuestionTable')));
const ResultHistory = Loadable(lazy(() => import('pages/quiz/ResultHistory')));
const LearnerDashboard = Loadable(lazy(() => import('pages/dashboard/StudentDashboard')));
const AddUser = Loadable(lazy(() => import('pages/users/AddUser')));
const GroupData = Loadable(lazy(() => import('pages/group/GroupData')));
const VideoPlayer = Loadable(lazy(() => import('pages/course/myCourse/VideoPlayer')));
const CourseData = Loadable(lazy(() => import('pages/course/courseData/courseData')));
const PrivateRoute = Loadable(lazy(() => import('utils/route-guard/privateRoute')));

//Discussion
const DiscussionHome = Loadable(lazy(() => import('pages/discussion/DiscussionHome')));
const DiscussionPage = Loadable(lazy(() => import('pages/discussion/DiscussionPage')));

//Groups
const GroupsList = Loadable(lazy(() => import('pages/myGroup/GroupsList')));
const GroupCatalog = Loadable(lazy(() => import('pages/myGroup/GroupCatalog')));

//Dictionary
const Dictionary = Loadable(lazy(() => import('pages/Dictionary')));

//Notification view
const AllNotify = Loadable(lazy(() => import('pages/notify/index')));
const CustomNotification = Loadable(lazy(() => import('pages/notification/CustomNotification')));
const AddCustomNotification = Loadable(lazy(() => import('pages/notification/SendCustomNotification')));

//Blog routes
const BlogCreate = Loadable(lazy(() => import('pages/Blogs/createBlogs')));
const BlogForm = Loadable(lazy(() => import('pages/Blogs/blogForm')));
const Blogs = Loadable(lazy(() => import('pages/Blogs/blogs')));
const BlogView = Loadable(lazy(() => import('pages/Blogs/blogView')));
// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const GetStarted = Loadable(lazy(() => import('pages/GetStarted/GetStarted')));
const FacultyDashboard = Loadable(lazy(() => import('pages/dashboard/instructor')));
// const StudentDashboard = Loadable(lazy(() => import('pages/dashboard/learner')));
// const Logs = Loadable(lazy(() => import('pages/dashboard/Logs')));
// const DashboardAnalytics = Loadable(lazy(() => import('pages/dashboard/analytics')));
//render - ourworks
// const OurWork = Loadable(lazy(() => import('pages/ourwork/trial')));
const AccountSettings = Loadable(lazy(() => import('pages/Settings/AccountSettings')));

// const CertificateEdditor = Loadable(lazy(() => import('components/EmailEdditor/EmailEdditor')));
//render - category
const CategoryList = Loadable(lazy(() => import('pages/category/index')));
const AddCategory = Loadable(lazy(() => import('pages/category/AddCategory')));
//render - users
const UserList = Loadable(lazy(() => import('pages/users/index')));
//render - group
const GroupList = Loadable(lazy(() => import('pages/group/index')));
const AddGroup = Loadable(lazy(() => import('pages/group/AddGroup')));
// const UpdateGroup = Loadable(lazy(() => import('pages/group/GroupData/UpdateGruop')));
//render - course
const CourseForm = Loadable(lazy(() => import('pages/course/courseForm')));
// const CourseData = Loadable(lazy(() => import('pages/course/CourseData')));
// const EditCourseForm = Loadable(lazy(() => import('pages/course/editCourseForm')));
const CourseListTable = Loadable(lazy(() => import('pages/course/courseListTable')));
const CourseDetails = Loadable(lazy(() => import('pages/course/courseDetails')));
const EditContentDetail = Loadable(lazy(() => import('pages/course/contents/editContent')));
const MyCourse = Loadable(lazy(() => import('pages/course/myCourse/MyCourse')));
const Checkout = Loadable(lazy(() => import('pages/course/courseCheckout/checkout')));
const CourseCatalogList = Loadable(lazy(() => import('pages/course/courseCatalog/courseCatalogList')));
const Wishlist = Loadable(lazy(() => import('pages/course/wishlist/wishlist')));
const CourseCatalog = Loadable(lazy(() => import('pages/course/courseCatalog/CourseCatalog')));
const OrderHistory = Loadable(lazy(() => import('layout/MainLayout/Header/HeaderContent/Profile/orderHistory')));
const MyActivity = Loadable(lazy(() => import('pages/dashboard/MyActivity')));
// const MyCourse = Loadable(lazy(() => import('pages/course/MyCourse')));
const CourseReAssign = Loadable(lazy(() => import('pages/course/CourseReAssignment/CourseReAssign')));
const AddCourseReAssign = Loadable(lazy(() => import('pages/course/CourseReAssignment/AddEditCourseToReAssign')));
const WhiteboardList = Loadable(lazy(() => import('pages/course/whiteboard')));

const CourseTableSA = Loadable(lazy(() => import('pages/course/SuperAdmin/courseTableSA')));
const CourseFormSA = Loadable(lazy(() => import('pages/course/SuperAdmin/courseFromSA')));

//Certificate Edditor
// const certificateEdditor = Loadable(lazy(() => import('components/EmailEdditor/EmailEdditor')));
//render - Offers
const OfferCard = Loadable(lazy(() => import('pages/offer/OfferCard')));
const AddOffer = Loadable(lazy(() => import('pages/offer/AddOffer')));
const OfferTable = Loadable(lazy(() => import('pages/offer/OfferTable')));
// const VideoDrop = Loadable(lazy(() => import('pages/components-overview/videodrop')));
// const Course = Loadable(lazy(() => import('pages/course/index')));
// const AddCourse = Loadable(lazy(() => import('pages/course/add')));

const CourseDashboard = Loadable(lazy(() => import('pages/course/Content/CourseDashboard')));
const AssessmentDashboard = Loadable(lazy(() => import('pages/course/Assessment/AssessmentDashboard')));
const CourseOverView = Loadable(lazy(() => import('pages/course/Content/Overview')));
const CourseReports = Loadable(lazy(() => import('pages/course/Content/CourseReports')));
const AssessmentReport = Loadable(lazy(() => import('pages/course/Assessment/AssessmentReport')));
const UserReports = Loadable(lazy(() => import('pages/course/UserReport/UserReports')));
const UserDashboard = Loadable(lazy(() => import('pages/users/UserDashboard')));
//organization details
const Organization = Loadable(lazy(() => import('pages/superAdmin/OrgnizationList')));
const AddOrganization = Loadable(lazy(() => import('pages/superAdmin/AddOrganization')));
const SuperUsers = Loadable(lazy(() => import('pages/superAdmin/SuperAdminUserList')));
const SuperUsersAdd = Loadable(lazy(() => import('pages/superAdmin/SuperAdminUserAdd')));
const ContentManagement = Loadable(lazy(() => import('pages/superAdmin/ContentManagement')));
const ContentManagementList = Loadable(lazy(() => import('pages/superAdmin/ContentManagementList')));

//render Contest page
const Contest = Loadable(lazy(() => import('pages/contest/contests')));
const AllContest = Loadable(lazy(() => import('pages/contest/AllContest')));
const ViewContest = Loadable(lazy(() => import('pages/contest/viewContest')));
const LeaderBoard = Loadable(lazy(() => import('pages/contest/LeaderBoard')));
const ContestForm = Loadable(lazy(() => import('pages/contest/contestForm')));
const ContestData = Loadable(lazy(() => import('pages/contest/contestData')));
const ContestTable = Loadable(lazy(() => import('pages/contest/contestTable')));
const QuizLeaderboard = Loadable(lazy(() => import('pages/contest/QuizLeaderboard')));
const ContestleaderBoard = Loadable(lazy(() => import('pages/contest/ContestLeaderboard')));
//render - course
const Export = Loadable(lazy(() => import('pages/importExport/export')));
const Import = Loadable(lazy(() => import('pages/importExport/import')));
// render - userType
const UserTypeForm = Loadable(lazy(() => import('pages/userType/userTypeForm')));

// render - meeting
const MeetingForm = Loadable(lazy(() => import('pages/meeting/meetingForm')));
const MeetingList = Loadable(lazy(() => import('pages/meeting/meetingList')));
const MeetingReports = Loadable(lazy(() => import('pages/meeting/meetingReport')));
const MeetingSummary = Loadable(lazy(() => import('pages/meeting/meetingSummary')));

//render - notification
const Notification = Loadable(lazy(() => import('pages/notification/index')));
const Event = Loadable(lazy(() => import('pages/event/calendar')));
const EventTable = Loadable(lazy(() => import('pages/event/EventTable')));
const RequestTable = Loadable(lazy(() => import('pages/event/RequestTable')));
const RequestTabs = Loadable(lazy(() => import('pages/event/RequestTabs')));

const AddNotification = Loadable(lazy(() => import('pages/notification/AddNotification')));
// pages routing
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceAwayScreen = Loadable(lazy(() => import('pages/maintenance/away-screen')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));
// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));
const PricingPage = Loadable(lazy(() => import('pages/extra-pages/pricing')));
const Badges = Loadable(lazy(() => import('pages/badges/badges')));
const ResultDetailsTable = Loadable(lazy(() => import('pages/Exam/ResultDetails')));
const AnswerChecking = Loadable(lazy(() => import('pages/Exam/AnswerChecking')));
const OrgTabs = Loadable(lazy(() => import('pages/superAdmin/organizationTabs')));
const Packages = Loadable(lazy(() => import('pages/packages/packages')));

const addPackage = Loadable(lazy(() => import('pages/packages/addPackages')));
const packageList = Loadable(lazy(() => import('pages/packages/packageList')));
const assignPackage = Loadable(lazy(() => import('pages/packages/AssignPackage')));
// render = time-table page
const TimeTableList = Loadable(lazy(() => import('pages/time-table/TimeTable-Table')));
const ResultTable = Loadable(lazy(() => import('pages/Exam/ResultTable')));
const ResultReportCard = Loadable(lazy(() => import('pages/Exam/ResultReportCard')));
const FinalReportCard = Loadable(lazy(() => import('pages/Exam/FinalReportCard')));
const AddTimeTable = Loadable(lazy(() => import('pages/time-table/AddTimeTable')));
const StudentList = Loadable(lazy(() => import('pages/time-table/studentList')));
const timeTable = Loadable(lazy(() => import('pages/timeTable/timeTableView')));
const Assignment = Loadable(lazy(() => import('pages/Assignment/Assignment')));
const AssignmentView = Loadable(lazy(() => import('pages/course/courseData/ViewAssignment')));
const AddAssignment = Loadable(lazy(() => import('pages/course/courseData/AddAssignment')));
const ViewAssignment = Loadable(lazy(() => import('pages/Assignment/viewAssignment')));
const AddTicket = Loadable(lazy(() => import('pages/Support-Ticket/addTicket')));
const TcketTable = Loadable(lazy(() => import('pages/Support-Ticket/ticketTable')));
const ClassRoomList = Loadable(lazy(() => import('pages/classRoom/classRoomList')));
const ClassRoomForm = Loadable(lazy(() => import('pages/classRoom/classRoomForm')));

const AppChat = Loadable(lazy(() => import('pages/chat/Chat')));
const TransferStudents = Loadable(lazy(() => import('pages/transferStudents/UserTable')));

const Skill = Loadable(lazy(() => import('pages/skill/Skill')));
// const AddClass = Loadable(lazy(() => import('pages/class/AddClass')));
// const ClassList = Loadable(lazy(() => import('pages/class/ClassList')));

const TimeTableScheduler = Loadable(lazy(() => import('pages/timetable-scheduler/index')));
const TimeTableForm = Loadable(lazy(() => import('pages/timetable-scheduler/TimeTableForm')));

// ==============================|| MAIN ROUTES ||============================== //
const Route = () => {
  const { user, organization }: any = useAuth();
  const permission = user?.userTypeId?.permission;
  const isSuperAdmin = user?.type == 'superadmin';
  const isTrainingOrg = organization?.trainingMode;

  const MainRoutes = {
    path: '/',
    children: [
      { path: '', element: <Home /> },
      { path: 'privacy-policy', element: <PrivacyPolicy /> },
      { path: 'terms-of-service', element: <TermsOfServices /> },
      { path: 'account/accomplishments/verify/:id', element: <VerifyCertificate /> },
      { path: 'account/accomplishments/certificate/:id', element: <ViewCertificate /> },

      {
        path: '/',
        element: (
          <AuthGuard>
            <MainLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'dashboard',
            children: [
              {
                path: '',
                element: (
                  <PrivateRoute
                    component={DashboardDefault}
                    permission={user?.type === 'superadmin' ? true : permission?.Dashboard?.View}
                  />
                )
              }
            ]
          },
          {
            path: 'ticket',
            children: [
              {
                path: '',
                element: <PrivateRoute component={AllTicket} permission={permission?.Tickets?.View} />
              },
              {
                path: 'add',
                element: <PrivateRoute component={AddTicket} permission={permission?.Tickets?.Create} />
              },
              {
                path: 'edit',
                element: <PrivateRoute component={AddTicket} permission={permission?.Tickets?.Update} />
              }
            ]
          },
          {
            path: 'transferstudents',
            children: [
              {
                path: '',
                element: <PrivateRoute component={TransferStudents} permission={permission?.TransferStudents?.View} />
              }
            ]
          },
          {
            path: 'mylearning',
            children: [
              {
                path: '',
                element: <PrivateRoute component={MyCourse} permission={permission?.UserCourses?.View && isTrainingOrg} />
              },
              {
                path: 'course',
                element: <PrivateRoute component={VideoPlayer} permission={permission?.UserCourses?.View && isTrainingOrg} />
              }
            ]
          },
          {
            path: 'subject',
            children: [
              {
                path: '',
                element: <PrivateRoute component={SubjectList} permission={permission?.Subjects?.View} />
              },
              {
                path: 'add',
                element: <PrivateRoute component={SubjectForm} permission={permission?.Subjects?.View} />
              },
              {
                path: 'edit',
                element: <PrivateRoute component={SubjectForm} permission={permission?.Subjects?.View} />
              }
            ]
          },
          {
            path: 'exam',
            children: [
              {
                path: '',
                element: <PrivateRoute component={ExamTable} permission={permission?.Exam?.View} />
              },
              {
                path: 'add',
                element: <PrivateRoute component={AddExam} permission={permission?.Exam?.Create} />
              },
              {
                path: 'edit',
                element: <PrivateRoute component={AddExam} permission={permission?.Exam?.Update} />
              },
              {
                path: 'section',
                element: <PrivateRoute component={AddExamSection} permission={permission?.Exam?.Create} />
              },
              {
                path: 'assess',
                element: <PrivateRoute component={ResultDetailsTable} permission={permission?.Exam?.View} />
              },
              {
                path: 'checking',
                element: <PrivateRoute component={AnswerChecking} permission={permission?.Exam?.View} />
              }
            ]
          },
          {
            path: 'time-table',
            children: [
              {
                path: '',
                element: <PrivateRoute component={TimeTableList} permission={permission?.TimeTable?.View} />
              },
              {
                path: 'add',
                element: <PrivateRoute component={AddTimeTable} permission={permission?.TimeTable?.Create} />
              },
              {
                path: 'edit',
                element: <PrivateRoute component={AddTimeTable} permission={permission?.TimeTable?.Update} />
              },
              {
                path: 'students',
                element: <PrivateRoute component={StudentList} permission={permission?.TimeTable?.Update} />
              }
            ]
          },
          {
            path: '/timetable-scheduler',
            children: [
              {
                path: '',
                element: <PrivateRoute component={TimeTableScheduler} permission={permission?.TimetableScheduler?.View} />
              },
              {
                path: '/timetable-scheduler/add',
                element: <PrivateRoute component={TimeTableForm} permission={permission?.TimetableScheduler?.Create} />
              },
              {
                path: '/timetable-scheduler/edit',
                element: <PrivateRoute component={TimeTableForm} permission={permission?.TimetableScheduler?.Update} />
              }
            ]
          },
          {
            path: '/profile',
            element: <PrivateRoute component={AddUser} permission={true} />
          },
          {
            path: 'superuser',
            children: [
              {
                path: '',
                element: <PrivateRoute component={SuperUsers} permission={true} />
              },
              {
                path: 'add',
                element: <PrivateRoute component={SuperUsersAdd} permission={true} />
              }
            ]
          },
          {
            path: 'discussion',
            children: [
              {
                path: '',
                element: <PrivateRoute component={DiscussionHome} permission={permission?.Discussions?.View} />
              },
              {
                path: 'view',
                element: <PrivateRoute component={DiscussionPage} permission={permission?.Discussions?.View} />
              }
            ]
          },
          {
            path: 'feedback',
            children: [
              {
                path: '',
                element: <PrivateRoute component={FeedbackForm} permission={permission?.Feedback?.View} />
              },
              {
                path: '/feedback/feedbackTable',
                element: <PrivateRoute component={FeedbackTable} permission={permission?.Feedback?.View} />
              }
            ]
          },
          {
            path: 'contact',
            children: [
              {
                path: '/contact/contactTable',
                element: <PrivateRoute component={ContactTable} permission={permission?.Contact?.View} />
              }
            ]
          },
          {
            path: 'Settings',
            children: [
              {
                path: 'accountsettings',
                element: <PrivateRoute component={AccountSettings} permission={permission?.AccountSettings?.View} />
              },
              {
                path: 'contentmanagement',
                children: [
                  {
                    path: '',
                    element: <PrivateRoute component={ContentManagementList} permission={permission?.AccountSettings?.View} />
                  }
                  // {
                  //   path: 'view',
                  //   element: <PrivateRoute component={ContentManagement} permission={permission?.AccountSettings?.View} />
                  // }
                ]
              }
            ]
          },

          {
            path: 'notify',
            element: <PrivateRoute component={AllNotify} permission={permission?.Dashboard?.View} />
          },
          {
            path: 'blog',
            children: [
              {
                path: '/blog',
                element: <PrivateRoute component={Blogs} permission={permission?.Blogs?.View} />
              },
              {
                path: '/blog/view',
                element: <PrivateRoute component={BlogView} permission={permission?.Blogs?.View} />
              },
              {
                path: '/blog/list',
                element: <PrivateRoute component={BlogCreate} permission={permission?.Blogs?.View} />
              },
              {
                path: '/blog/add',
                element: <PrivateRoute component={BlogForm} permission={permission?.Blogs?.Create} />
              },
              {
                path: '/blog/edit',
                element: <PrivateRoute component={BlogForm} permission={permission?.Blogs?.Update} />
              }
            ]
          },
          {
            path: 'contest',
            children: [
              {
                path: '/contest/add',
                element: <PrivateRoute component={ContestForm} permission={permission?.Quiz?.Create} />
              },
              {
                path: '/contest/edit',
                element: <PrivateRoute component={ContestData} permission={permission?.Quiz?.Update} />
              },
              {
                path: '/contest/all',
                element: <PrivateRoute component={AllContest} permission={permission?.Quiz?.View} />
              },
              {
                path: '/contest/leaderboard',
                element: <PrivateRoute component={ContestleaderBoard} permission={permission?.Quiz?.View} />
              },
              {
                path: '/contest',
                element: <PrivateRoute component={ContestTable} permission={permission?.Quiz?.View} />
              },
              {
                path: '/contest/leaderboard',
                element: <PrivateRoute component={ContestleaderBoard} permission={permission?.Quiz?.View} />
              }
            ]
          },
          {
            path: 'users',
            children: [
              {
                path: '/users/admin',
                element: <PrivateRoute component={UserList} permission={permission?.Users?.View} />
              },
              {
                path: '/users/faculty',
                element: <PrivateRoute component={UserList} permission={permission?.Users?.View} />
              },
              {
                path: '/users/learner',
                element: <PrivateRoute component={UserList} permission={permission?.Users?.View} />
              },
              {
                path: '/users/applied_Learner',
                element: <PrivateRoute component={UserList} permission={permission?.Users?.View} />
              },
              {
                path: '/users/admin',
                element: <PrivateRoute component={UserList} permission={permission?.Users?.View} />
              },
              {
                path: '/users/add',
                element: <PrivateRoute component={AddUser} permission={permission?.Users?.Create} />
              },
              {
                path: '/users/edit',
                element: <PrivateRoute component={AddUser} permission={permission?.Users?.Update} />
              }
            ]
          },
          {
            path: 'category',
            children: [
              {
                path: '/category/',
                element: <PrivateRoute component={CategoryList} permission={permission?.Categories?.View} />
              },
              {
                path: '/category/add',
                element: <PrivateRoute component={AddCategory} permission={permission?.Categories?.Create} />
              },
              {
                path: '/category/edit',
                element: <PrivateRoute component={AddCategory} permission={permission?.Categories?.Update} />
              }
            ]
          },
          {
            path: 'certificate',
            children: [
              {
                path: '/certificate',
                element: <PrivateRoute component={CertificateTable} permission={permission?.Certificates?.View} />
              },
              {
                path: '/certificate/mycertificate',
                element: <PrivateRoute component={UserCertificateTable} permission={permission?.Certificates?.View} />
              },
              {
                path: '/certificate/add',
                element: <PrivateRoute component={MainCertificate} permission={permission?.Certificates?.Create} />
              },
              {
                path: '/certificate/edit',
                element: <PrivateRoute component={MainCertificate} permission={permission?.Certificates?.Update} />
              }
            ]
          },
          {
            path: 'assignment',
            children: [
              {
                path: '',
                element: <PrivateRoute component={AssignmentList} permission={permission?.Assignment?.View} />
              },
              {
                path: 'studentView',
                element: <PrivateRoute component={CheckAssignment} permission={permission?.Assignment?.View} />
              }
            ]
          },
          {
            path: 'emailTemplates',
            children: [
              {
                path: '/emailTemplates',
                element: <PrivateRoute component={EmailTemplates} permission={permission?.EmailTemplates?.View} />
              },
              {
                path: '/emailTemplates/add',
                element: <PrivateRoute component={EmailTemplateForm} permission={permission?.EmailTemplates?.Create} />
              },
              {
                path: '/emailTemplates/edit',
                element: <PrivateRoute component={EmailTemplateForm} permission={permission?.EmailTemplates?.Update} />
              }
            ]
          },
          {
            path: 'group',
            children: [
              {
                path: '/group',
                element: <PrivateRoute component={GroupList} permission={permission?.Groups?.View} />
              },
              {
                path: '/group/add',
                element: <PrivateRoute component={AddGroup} permission={permission?.Groups?.Create} />
              },
              {
                path: '/group/edit',
                element: <PrivateRoute component={GroupData} permission={permission?.Groups?.Update} />
              }
            ]
          },
          {
            path: 'question-group',
            children: [
              {
                path: '',
                element: <PrivateRoute component={QuizGroupTabs} permission={permission?.QuestionGroup?.View} />
              },
              {
                path: '/question-group/add',
                element: <PrivateRoute component={AddQuizGroup} permission={permission?.QuestionGroup?.Create} />
              }
              // {
              //   path: '/question-group/edit',
              //   element: <PrivateRoute component={AddQuizGroup} permission={permission?.QuestionGroup?.Update} />
              // }
            ]
          },
          {
            path: 'classroom',
            children: [
              {
                path: '/classroom',
                element: <PrivateRoute component={ClassRoomList} permission={permission?.ClassRoom?.View} />
              },
              {
                path: '/classroom/add',
                element: <PrivateRoute component={ClassRoomForm} permission={permission?.ClassRoom?.Create} />
              },
              {
                path: '/classroom/edit',
                element: <PrivateRoute component={ClassRoomForm} permission={permission?.ClassRoom?.Update} />
              }
            ]
          },
          {
            path: 'importExport',
            children: [
              {
                path: '/importExport/import',
                element: <Import />
              },
              {
                path: '/importExport/export',
                element: <Export />
              }
            ]
          },
          {
            path: 'notification',
            children: [
              {
                path: '',
                element: <PrivateRoute component={Notification} permission={permission?.EventNotification?.View} />
                // element: <Notification />
              },
              {
                path: '/notification/add',
                element: <PrivateRoute component={AddNotification} permission={permission?.EventNotification?.Create} />
              },
              {
                path: '/notification/edit',
                element: <PrivateRoute component={AddNotification} roles={permission?.EventNotification?.Update} />
              }
            ]
          },
          {
            path: 'customNotification',
            children: [
              {
                path: '',
                element: <PrivateRoute component={CustomNotification} permission={permission?.CustomNotification?.View} />
                // element: <Notification />
              },
              {
                path: '/customNotification/add',
                element: <PrivateRoute component={AddCustomNotification} permission={permission?.CustomNotification?.Create} />
              }
              // {
              //   path: '/notification/edit',
              //   element: <PrivateRoute component={AddNotification} roles={permission?.EventNotification?.Update} />
              // }
            ]
          },
          {
            path: 'event',
            children: [
              {
                path: '',
                element: <PrivateRoute component={Event} permission={permission?.Events?.View} />
              },
              {
                path: '/event/list',
                element: <PrivateRoute component={EventTable} permission={permission?.Events?.View} />
              },
              {
                path: '/event/myEvents',
                element: <PrivateRoute component={RequestTable} permission={permission?.Events?.View} />
              },
              {
                path: '/event/request',
                element: <PrivateRoute component={RequestTabs} permission={permission?.Events?.View} />
              }
            ]
          },
          {
            path: 'meeting',
            children: [
              {
                path: '',
                element: <PrivateRoute component={MeetingList} permission={permission?.Meeting?.View} />
              },
              {
                path: '/meeting/add',
                element: <PrivateRoute component={MeetingForm} permission={permission?.Meeting?.Create} />
              },
              {
                path: '/meeting/edit',
                element: <PrivateRoute component={MeetingForm} permission={permission?.Meeting?.Update} />
              }
            ]
          },
          {
            path: 'course',
            children: [
              {
                path: '/course/add',
                element: <PrivateRoute component={CourseForm} permission={permission?.Courses?.Create} />
              },
              {
                path: '/course/wishlist',
                element: <PrivateRoute component={Wishlist} permission={permission?.Courses?.View} />
              },
              {
                path: '/course/edit',
                element: <PrivateRoute component={CourseData} permission={permission?.Courses?.Update} />
              },
              {
                path: '/course',
                element: <PrivateRoute component={CourseListTable} permission={permission?.Courses?.View} />
              },
              {
                path: '/course/assignment',
                element: <PrivateRoute component={AssignmentView} permission={permission?.Assignment?.View} />
              },
              {
                path: '/course/assignment/add',
                element: <PrivateRoute component={AddAssignment} permission={permission?.Assignment?.View} />
              },
              {
                path: '/course/assignment/edit',
                element: <PrivateRoute component={AddAssignment} permission={permission?.Assignment?.View} />
              },
              {
                path: '/course/content',
                element: <PrivateRoute component={CourseDetails} permission={permission?.Courses?.Update || isSuperAdmin} />
              },
              {
                path: '/course/content/add',
                element: <PrivateRoute component={EditContentDetail} permission={permission?.Courses?.Update || isSuperAdmin} />
              },
              {
                path: '/course/content/edit',
                element: <PrivateRoute component={EditContentDetail} permission={permission?.Courses?.Update || isSuperAdmin} />
              },
              {
                path: '/course/catalog',
                element: <PrivateRoute component={CourseCatalogList} permission={permission?.Courses?.View || isSuperAdmin} />
              },
              {
                path: '/course/catalog/info',
                element: <PrivateRoute component={CourseCatalog} permission={permission?.Courses?.View || isSuperAdmin} />
              },
              {
                path: '/course/Content/OverView',
                element: <CourseOverView />
              },

              {
                path: '/course/reassign',
                element: (
                  <PrivateRoute
                    component={CourseReAssign}
                    permission={(permission?.CourseReassign?.View || isSuperAdmin) && isTrainingOrg}
                  />
                )
              },
              {
                path: '/course/reassign/add',
                element: (
                  <PrivateRoute
                    component={AddCourseReAssign}
                    permission={(permission?.CourseReassign?.Create || isSuperAdmin) && isTrainingOrg}
                  />
                )
              },
              {
                path: '/course/reassign/edit',
                element: <PrivateRoute component={AddCourseReAssign} permission={permission?.Courses?.Create && isTrainingOrg} />
              },
              {
                path: '/course/whiteboardRecordings',
                element: <PrivateRoute component={WhiteboardList} permission={permission?.Whiteboard?.View || isSuperAdmin} />
              },
              {
                path: '/course/whiteboard',
                element: <PrivateRoute component={CourseWhiteboard} permission={permission?.Courses?.Create && isTrainingOrg} />
              }
            ]
          },
          {
            path: 'offer',
            children: [
              {
                path: '/offer',
                element: <PrivateRoute component={OfferTable} permission={permission?.Offers?.View} />
              },
              {
                path: '/offer/add',
                element: <PrivateRoute component={AddOffer} permission={permission?.Offers?.Create} />
              },
              {
                path: '/offer/edit',
                element: <PrivateRoute component={AddOffer} permission={permission?.Offers?.Update} />
              },
              {
                path: '/offer/view',
                element: <OfferCard />
              }
            ]
          },
          {
            path: 'reports',
            children: [
              {
                path: '/reports/course',
                element: <PrivateRoute component={CourseReports} permission={permission?.Reports?.Courses} />
              },
              {
                path: '/reports/assessment',
                element: <PrivateRoute component={AssessmentReport} permission={permission?.Reports?.Assessments} />
              },
              {
                path: '/reports/user',
                element: <PrivateRoute component={UserReports} permission={permission?.Reports?.Users} />
              },
              {
                path: '/reports/meeting',
                element: <PrivateRoute component={MeetingReports} permission={permission?.Reports?.Meeting} />
              },
              {
                path: '/reports/meeting/usersummary',
                element: <PrivateRoute component={MeetingSummary} permission={permission?.Reports?.Meeting} />
              },
              {
                path: '/reports/userdashboard',
                element: <PrivateRoute component={UserDashboard} permission={permission?.Reports?.Users} />
              },
              {
                path: '/reports/coursedashboard',
                element: <PrivateRoute component={CourseDashboard} permission={permission?.Reports?.Courses} />
              },
              {
                path: '/reports/coursedashboard/assignmentUserTable',
                element: <PrivateRoute component={AssignmentUserTable} permission={permission?.Reports?.Courses} />
              },
              {
                path: '/reports/assessmentdashboard',
                element: <PrivateRoute component={AssessmentDashboard} permission={permission?.Reports?.Assessments} />
              },
              {
                path: '/reports/badges',
                element: <Badges />
              }
            ]
          },
          {
            path: 'quiz',
            children: [
              // {
              //   path: '',
              //   element: <PrivateRoute component={QuizView} permission={true} />
              // },
              {
                path: 'assessment/add',
                element: <PrivateRoute component={OverallAssessment} permission={permission?.Questions?.Create} />
              },
              {
                path: 'assessment/edit',
                element: <PrivateRoute component={OverallAssessment} permission={permission?.Questions?.Update} />
              },
              {
                path: 'assessment',
                element: <PrivateRoute component={AssessmentList} permission={permission?.Questions?.View} />
              },
              // {
              //   path: 'test',
              //   element: <PrivateRoute component={QuizStepper} permission={true} />
              // },
              {
                path: 'questions',
                element: <PrivateRoute component={QuestionTable} permission={permission?.Questions?.View} />
              },
              // {
              //   path: 'select',
              //   element: <PrivateRoute component={QuestionList} permission={permission?.Questions?.View} />
              // },
              {
                path: 'questions/add',
                element: <PrivateRoute component={AddQuestions} permission={permission?.Questions?.Create} />
              },
              {
                path: 'questions/edit',
                element: <PrivateRoute component={AddQuestions} permission={permission?.Questions?.Update} />
              },
              // {
              //   path: 'report',
              //   element: <PrivateRoute component={Report} permission={true} />
              // },
              // {
              //   path: 'resulthistory',
              //   element: <PrivateRoute component={ResultHistory} permission={true} />
              // },
              {
                path: 'leaderboard',
                element: <PrivateRoute component={QuizLeaderboard} permission={permission?.Quiz?.View} />
              }
            ]
          },
          {
            path: 'badges',
            children: [
              {
                path: 'add',
                element: <PrivateRoute component={AddBadgeForm} permission={permission?.Badges?.Create} />
              },
              {
                path: 'edit',
                element: <PrivateRoute component={AddBadgeForm} permission={permission?.Badges?.Update} />
              },
              {
                path: '',
                element: <PrivateRoute component={BadgesList} permission={permission?.Badges?.View} />
              }
            ]
          },
          {
            path: 'permissions',
            children: [
              {
                path: '/permissions',
                element: <PrivateRoute component={UserTypeList} permission={permission?.UserTypes?.View} />
              },
              {
                path: '/permissions/add',
                element: <PrivateRoute component={UserTypeForm} permission={permission?.UserTypes?.Create} />
              },
              {
                path: '/permissions/edit',
                element: <PrivateRoute component={UserTypeForm} permission={permission?.UserTypes?.Update} />
              }
            ]
          },
          {
            path: 'checkout',
            element: <PrivateRoute component={Checkout} permission={permission?.UserCourses?.Purchase} />
          },
          {
            path: 'order-history',
            element: <PrivateRoute component={OrderHistory} permission={permission?.UserCourses?.Purchase} />
          },
          {
            path: 'sample-page',
            element: <SamplePage />
          },
          {
            path: 'pricing',
            element: <PricingPage />
          },
          {
            path: 'package',
            element: <PrivateRoute component={Packages} permission={permission?.Packages?.View} />
          },
          {
            path: 'exam',
            children: [
              {
                path: '',
                element: <PrivateRoute component={ExamTable} permission={permission?.Exam?.View} />
              },
              {
                path: 'add',
                element: <PrivateRoute component={AddExam} permission={permission?.Exam?.Create} />
              },
              {
                path: 'edit',
                element: <PrivateRoute component={AddExam} permission={permission?.Exam?.Update} />
              },
              {
                path: 'section',
                element: <PrivateRoute component={AddExamSection} permission={permission?.Exam?.Create} />
              },
              {
                path: 'assess',
                element: <PrivateRoute component={ResultDetailsTable} permission={permission?.Exam?.View} />
              },
              {
                path: 'checking',
                element: <PrivateRoute component={AnswerChecking} permission={permission?.Exam?.View} />
              }
            ]
          },
          {
            path: 'time-table',
            children: [
              {
                path: '',
                element: <PrivateRoute component={ResultTable} permission={permission?.TimeTable?.View} />
              },
              {
                path: 'add',
                element: <PrivateRoute component={AddTimeTable} permission={permission?.TimeTable?.Create} />
              },
              {
                path: 'edit',
                element: <PrivateRoute component={AddTimeTable} permission={permission?.TimeTable?.Update} />
              },
              {
                path: 'students',
                element: <PrivateRoute component={StudentList} permission={permission?.TimeTable?.Update} />
              }
            ]
          },
          {
            path: 'settings',
            children: [
              {
                path: 'accountsettings',
                element: <PrivateRoute component={AccountSettings} permission={permission?.AccountSettings?.View} />
              }
            ]
          },
          {
            path: 'chat',
            element: <PrivateRoute component={AppChat} permission={permission?.Chats?.View} />
          },
          {
            path: '/',
            element: (
              <AuthGuard>
                <ContentLayout hideDrawer={true} isStudent={false} />
              </AuthGuard>
            ),
            children: [
              {
                path: 'mylearning',
                children: [
                  {
                    path: '/mylearning/course',
                    element: <PrivateRoute component={VideoPlayer} permission={permission?.UserCourses?.View} />
                  }
                ]
              }
            ]
          },
          {
            path: '/maintenance',
            element: <CommonLayout />,
            children: [
              {
                path: '404',
                element: <MaintenanceError />
              },
              {
                path: '500',
                element: <MaintenanceError500 />
              },
              {
                path: 'away-screen',
                element: <MaintenanceAwayScreen />
              },
              {
                path: 'under-construction',
                element: <MaintenanceUnderConstruction />
              },
              {
                path: 'coming-soon',
                element: <MaintenanceComingSoon />
              }
            ]
          },
          {
            path: '/auth',
            element: <CommonLayout />,
            children: [
              {
                path: 'login',
                element: <AuthLogin />
              },
              {
                path: 'register',
                element: <AuthRegister />
              },
              {
                path: 'forgot-password',
                element: <AuthForgotPassword />
              },
              {
                path: 'reset-password',
                element: <AuthResetPassword />
              },
              {
                path: 'check-mail',
                element: <AuthCheckMail />
              },
              {
                path: 'code-verification',
                element: <AuthCodeVerification />
              }
            ]
          },
          {
            path: 'getStarted',
            children: [
              {
                path: '',
                element: <PrivateRoute component={GetStarted} permission={true} />
              }
            ]
          },
          {
            path: 'timeline',
            children: [
              {
                path: '',
                element: <PrivateRoute component={TimeLineTable} permission={permission?.Timeline?.View} />
              }
            ]
          }
        ]
      },
      // learner routes
      {
        path: '/learner',
        element: (
          <AuthGuard>
            <ContentLayout hideDrawer={true} isStudent={true} />
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            children: [
              {
                path: '',
                element: <PrivateRoute component={LearnerDashboard} permission={permission?.Dashboard?.View} />
              },
              {
                path: 'packages',
                element: <PrivateRoute component={StudentPackages} permission={permission?.Subscription?.View} />
              },
              {
                path: 'skills',
                element: <PrivateRoute component={Skill} permission={permission?.Skills?.View} />
              },
              {
                path: 'dictionary',
                element: <PrivateRoute component={Dictionary} permission={true} />
              },
              // {
              //   path: 'chat',
              //   children: [
              //     {
              //       path: '',
              //       element: <PrivateRoute component={AppChat} permission={permission?.Chats?.View} />
              //     }
              //   ]
              // },
              {
                path: 'assignments',
                children: [
                  { path: '', element: <PrivateRoute component={Assignment} permission={permission?.Courses?.View} /> },
                  { path: 'view', element: <PrivateRoute component={ViewAssignment} permission={permission?.Courses?.View} /> }
                ]
              },
              {
                path: 'timetable',
                element: <PrivateRoute component={timeTable} permission={permission?.TimeTable?.View} />
              },
              {
                path: 'ticket',
                children: [
                  {
                    path: '',
                    element: <PrivateRoute component={TcketTable} permission={permission?.Tickets?.View} />
                  },
                  {
                    path: 'add',
                    element: <PrivateRoute component={AddTicket} permission={permission?.Tickets?.Create} />
                  },
                  {
                    path: 'edit',
                    element: <PrivateRoute component={AddTicket} permission={permission?.Tickets?.Update} />
                  }
                ]
              },
              {
                path: 'result',
                children: [
                  {
                    path: '',
                    element: <PrivateRoute component={ResultTable} permission={permission?.Result?.View} />
                  },
                  {
                    path: 'reportcard',
                    element: <PrivateRoute component={ResultReportCard} permission={permission?.Result?.View} />
                  },
                  {
                    path: 'finalresult',
                    element: <PrivateRoute component={FinalReportCard} permission={permission?.Result?.View} />
                  }
                ]
              },
              {
                path: 'demo',
                element: <PrivateRoute component={DemoExam} permission={permission?.Dashboard?.View} />
              },
              {
                path: 'test',
                element: <PrivateRoute component={ExamStepper} permission={permission?.Dashboard?.View} />
              },
              {
                path: 'freeCourses',
                element: <PrivateRoute component={FreeCoursesList} permission={permission?.Dashboard?.View} />
              },
              {
                path: 'notify',
                element: <PrivateRoute component={AllNotify} permission={permission?.Dashboard?.View} />
              },
              {
                path: 'course',
                element: <PrivateRoute component={CourseCatalogList} permission={permission?.Courses?.View} />
              },
              {
                path: 'wishlist',
                element: <PrivateRoute component={Wishlist} permission={permission?.Courses?.View} />
              },
              {
                path: 'order-history',
                element: <PrivateRoute component={OrderHistory} permission={permission?.UserCourses?.Purchase} />
              },
              {
                path: 'my-activity',
                element: <PrivateRoute component={MyActivity} permission={true} />
              },
              {
                path: 'contest',
                element: <PrivateRoute component={Contest} permission={permission?.Quiz?.Contest} />
              },
              {
                path: 'contest/all',
                element: <PrivateRoute component={AllContest} permission={permission?.Quiz?.Contest} />
              },
              {
                path: 'globalleaderboard',
                element: <PrivateRoute component={LeaderBoard} permission={permission?.Quiz?.Contest} />
              },
              {
                path: 'contest/leaderboard',
                element: <PrivateRoute component={ContestleaderBoard} permission={permission?.Quiz?.Contest} />
              },
              {
                path: 'contest/view',
                element: <PrivateRoute component={ViewContest} permission={permission?.Quiz?.Contest} />
              },
              {
                path: 'course/info',
                element: <PrivateRoute component={CourseCatalog} permission={permission?.Courses?.View} />
              },
              {
                path: 'certificate/mycertificate',
                element: <PrivateRoute component={UserCertificateTable} permission={permission?.Certificates?.View} />
              },
              {
                path: 'events',
                element: <PrivateRoute component={EventTable} permission={true} />
              },
              {
                path: 'leaderboard',
                element: <PrivateRoute component={QuizLeaderboard} permission={permission?.Quiz?.LeaderBoard} />
              },
              {
                path: 'badges',
                element: <PrivateRoute component={BadgesAchieved} permission={permission?.Badges?.View} />
              },
              {
                path: 'timeline',
                element: <PrivateRoute component={TimeLineTable} permission={permission?.Timeline?.View} />
              },
              // {
              //   path: 'groups',
              //   children: [
              //     {
              //       path: '',
              //       element: <PrivateRoute component={GroupsList} permission={permission?.Groups?.View} />
              //     },
              //     {
              //       path: 'info',
              //       element: <PrivateRoute component={GroupCatalog} permission={permission?.Groups?.View} />
              //     }
              //   ]
              // },
              {
                path: 'discussion',
                children: [
                  {
                    path: '',
                    element: <PrivateRoute component={DiscussionHome} permission={permission?.Discussions?.View} />
                  },
                  {
                    path: 'view',
                    element: <PrivateRoute component={DiscussionPage} permission={permission?.Discussions?.View} />
                  }
                ]
              },
              {
                path: 'settings',
                children: [
                  {
                    path: 'accountSettings',
                    element: <PrivateRoute component={AccountSettings} permission={permission?.AccountSettings?.View} />
                  }
                ]
              }
            ]
          },
          {
            path: 'mylearning',
            children: [
              {
                path: '',
                element: <PrivateRoute component={MyCourse} permission={permission?.UserCourses?.View} />
              },
              {
                path: 'course',
                element: <PrivateRoute component={VideoPlayer} permission={permission?.UserCourses?.View} />
              }
            ]
          },
          {
            path: 'checkout',
            element: <PrivateRoute component={Checkout} permission={permission?.UserCourses?.Purchase} />
          },
          {
            path: 'quiz',
            children: [
              {
                path: '',
                element: <PrivateRoute component={QuizView} permission={permission?.Quiz?.View} />
              },
              {
                path: 'test',
                element: <PrivateRoute component={QuizStepper} permission={permission?.Quiz?.Attempt} />
              },
              {
                path: 'skilltest',
                element: <PrivateRoute component={SkillMatrixStepper} permission={permission?.Quiz?.Attempt} />
              },
              {
                path: 'contest',
                element: <PrivateRoute component={QuizView} permission={permission?.Quiz?.Contest} />
              },
              {
                path: 'resulthistory',
                element: <PrivateRoute component={ResultHistory} permission={permission?.Quiz?.View} />
              }
            ]
          },
          { path: 'account/accomplishments/verify/:id', element: <PrivateRoute component={VerifyCertificate} permission={true} /> },
          { path: 'account/accomplishments/certificate/:id', element: <PrivateRoute component={ViewCertificate} permission={true} /> },
          {
            path: 'event',
            children: [
              {
                path: '',
                element: <PrivateRoute component={Event} permission={true} />
              }
            ]
          },
          {
            path: 'profile',
            element: <PrivateRoute component={AddUser} permission={permission?.Users?.Update} />
          },
          {
            path: 'blog',
            children: [
              {
                path: '',
                element: <PrivateRoute component={Blogs} permission={permission?.Blogs?.View} />
              },
              {
                path: 'view',
                element: <PrivateRoute component={BlogView} permission={permission?.Blogs?.View} />
              }
            ]
          },
          {
            path: 'feedback',
            children: [
              {
                path: '',
                element: <PrivateRoute component={FeedbackForm} permission={permission?.Feedback?.View} />
              }
              // {
              //   path: '/feedback/feedbackTable',
              //   element: <PrivateRoute component={FeedbackTable} permission={permission?.Feedback?.View} />
              // }
            ]
          },
          {
            path: 'Settings',
            children: [
              {
                path: 'accountsettings',
                element: <PrivateRoute component={AccountSettings} roles={permission?.AccountSettings?.View} />
              }
            ]
          }
          // {
          //   path: 'feedback',
          //   children: [
          //     {
          //       path: '',
          //       element: <PrivateRoute component={FeedbackForm} permission={permission?.Feedback?.View} />
          //     }
          //     // {
          //     //   path: '/feedback/feedbackTable',
          //     //   element: <PrivateRoute component={FeedbackTable} permission={permission?.Feedback?.View} />
          //     // }
          //   ]
          // }
          // {
          //   path: 'Settings',
          //   children: [
          //     {
          //       path: 'accountsettings',
          //       element: <PrivateRoute component={AccountSettings} roles={true} />
          //     }
          //   ]
          // }
        ]
      },
      // teacher routes
      {
        path: '/faculty',
        element: (
          <AuthGuard>
            <MainLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            children: [
              {
                path: '',
                element: <PrivateRoute component={FacultyDashboard} permission={permission?.Dashboard?.View} />
              },
              {
                path: 'course/info',
                element: <PrivateRoute component={CourseCatalog} permission={permission?.Courses?.View} />
              },
              {
                path: 'users/edit',
                element: <PrivateRoute component={AddUser} permission={permission?.Users?.Update} />
              },
              {
                path: 'checkout',
                element: <PrivateRoute component={Checkout} permission={permission?.UserCourses?.Purchase} />
              }
              // {
              //   path: 'course',
              //   element: <PrivateRoute component={CourseCatalogList} roles={all} />
              // },
              // {
              //   path: 'course/info',
              //   element: <PrivateRoute component={CourseCatalog} roles={all} />
              // }
            ]
          }
          // {
          //   path: 'mylearning',
          //   children: [
          //     {
          //       path: '',
          //       element: <PrivateRoute component={MyCourse} roles={all} />
          //     },
          //     {
          //       path: 'course',
          //       element: <PrivateRoute component={VideoPlayer} roles={all} />
          //     }
          //   ]
          // },
          // {
          //   path: 'checkout',
          //   element: <PrivateRoute component={Checkout} roles={all} />
          // },
          // {
          //   path: 'Settings',
          //   children: [
          //     {
          //       path: 'accountsettings',
          //       element: <PrivateRoute component={AccountSettings} roles={all} />
          //     },
          //     {
          //       path: 'certificate/add',
          //       element: <PrivateRoute component={Certificate} roles={all} />
          //     },
          //     {
          //       path: 'certificate/edit',
          //       element: <PrivateRoute component={Certificate} roles={all} />
          //     }
          //   ]
          // }
        ]
      },
      // superadminroutes
      {
        path: '/superadmin',
        element: (
          <AuthGuard>
            <MainLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            children: [
              {
                path: 'dashboard',
                element: <PrivateRoute component={DashboardDefault} permission={true} />
              },
              {
                path: 'organization',
                children: [
                  {
                    path: '',
                    element: <PrivateRoute component={Organization} permission={true} />
                  },
                  {
                    path: 'info',
                    element: <PrivateRoute component={OrgTabs} permission={true} />
                  },
                  {
                    path: 'add',
                    element: <PrivateRoute component={AddOrganization} permission={true} />
                  },
                  {
                    path: 'users/info/',
                    element: <PrivateRoute component={UserDashboard} permission={true} />
                  },
                  {
                    path: 'courses/info/',
                    element: <PrivateRoute component={CourseDashboard} permission={true} />
                  },
                  {
                    path: 'assessments/info/',
                    element: <PrivateRoute component={AssessmentDashboard} permission={true} />
                  }
                ]
              },
              {
                path: 'user',
                children: [
                  {
                    path: 'list',
                    element: <PrivateRoute component={SuperUsers} permission={true} />
                  },
                  {
                    path: 'add',
                    element: <PrivateRoute component={SuperUsersAdd} permission={true} />
                  }
                ]
              },
              {
                path: 'course',
                children: [
                  {
                    path: 'list',
                    element: <PrivateRoute component={CourseTableSA} permission={true} />
                  },
                  {
                    path: 'add',
                    element: <PrivateRoute component={CourseFormSA} permission={true} />
                  },
                  {
                    path: 'edit',
                    element: <PrivateRoute component={CourseFormSA} permission={true} />
                  }
                ]
              },
              {
                path: 'contentmanagement',
                children: [
                  {
                    path: '',
                    element: <PrivateRoute component={ContentManagementList} permission={true} />
                  }
                  // {
                  //   path: 'view',
                  //   element: <PrivateRoute component={ContentManagement} permission={true} />
                  // }
                ]
              },
              {
                path: 'landingpagemanagement',
                children: [
                  // {
                  //   path: '',
                  //   element: <PrivateRoute component={LandingPageList} permission={true} />
                  // },
                  {
                    path: '',
                    element: (
                      <TabProvider>
                        <PrivateRoute component={LandingPageTabs} permission={true} />
                      </TabProvider>
                    )
                  },
                  {
                    path: 'view',
                    element: <PrivateRoute component={FormRenderer} permission={true} />
                  }
                ]
              },
              {
                path: 'question-group',
                children: [
                  {
                    path: '',
                    element: <PrivateRoute component={QuizGroupList} permission={true} />
                  },
                  {
                    path: 'add',
                    element: <PrivateRoute component={AddQuizGroup} permission={true} />
                  },
                  {
                    path: 'organizations',
                    element: <PrivateRoute component={AssignedOrgnizationList} permission={true} />
                  }
                ]
              },
              {
                path: 'quiz',
                children: [
                  {
                    path: 'questions',
                    element: <PrivateRoute component={QuestionTable} permission={true} />
                  },
                  {
                    path: 'questions/add',
                    element: <PrivateRoute component={AddQuestions} permission={true} />
                  },
                  {
                    path: 'questions/edit',
                    element: <PrivateRoute component={AddQuestions} permission={true} />
                  }
                ]
              },
              {
                path: 'customNotification',
                children: [
                  {
                    path: '',
                    element: <PrivateRoute component={CustomNotification} permission={true} />
                  },
                  {
                    path: 'add',
                    element: <PrivateRoute component={AddCustomNotification} permission={true} />
                  }
                  // {
                  //   path: '/notification/edit',
                  //   element: <PrivateRoute component={AddNotification} roles={permission?.EventNotification?.Update} />
                  // }
                ]
              },
              {
                path: 'package',
                children: [
                  {
                    path: 'add',
                    element: <PrivateRoute component={addPackage} permission={true} />
                  },
                  // {
                  //   path: 'view',
                  //   element: <PrivateRoute component={packages} permission={true} />
                  // },
                  {
                    path: 'assign',
                    element: <PrivateRoute component={assignPackage} permission={true} />
                  },
                  {
                    path: 'list',
                    element: <PrivateRoute component={packageList} permission={true} />
                  }
                ]
              },
              {
                path: 'blog',
                children: [
                  {
                    path: '',
                    element: <PrivateRoute component={Blogs} permission={true} />
                  },
                  {
                    path: 'view',
                    element: <PrivateRoute component={BlogView} permission={true} />
                  },
                  {
                    path: 'list',
                    element: <PrivateRoute component={BlogCreate} permission={true} />
                  },
                  {
                    path: 'add',
                    element: <PrivateRoute component={BlogForm} permission={true} />
                  },
                  {
                    path: 'edit',
                    element: <PrivateRoute component={BlogForm} permission={true} />
                  }
                ]
              },
              {
                path: 'contacts',
                children: [
                  {
                    path: 'list',
                    element: <PrivateRoute component={ContactTable} permission={true} />
                  }
                ]
              },
              {
                path: 'ticket',
                children: [
                  {
                    path: '',
                    element: <PrivateRoute component={AllTicket} permission={true} />
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: '*',
        element: <PrivateRoute component={MaintenanceError} permission={true} />
      }
    ]
  };
  return MainRoutes;
};
export default Route;
