import { Button, Divider, Grid, InputLabel, Stack, Box, TextField, Select, MenuItem, FormLabel, Autocomplete, Chip } from '@mui/material';
import MainCard from 'components/MainCard';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Snackbar from 'utils/Snackbar';
import useAuth from 'hooks/useAuth';
import BackToggle from 'components/third-party/dropzone/BackToggle';
import { addContent, editContent } from 'services/landingPageManagement.service';

const validationSchema = yup.object({
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required'),
  pageType: yup.string().required('pageType is required'),
  highlighted: yup.array().when('pageType', {
    is: 'homePage',
    then: yup
      .array()
      .of(yup.string().required('Each highlighted text is required'))
      .min(1, 'At least one highlighted text is required')
      .max(10, 'You can only add up to 10 highlighted texts'),
    otherwise: yup.array().nullable() // No validation for non-homePage
  })
});

function CommonBanner(data: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const { organization } = useAuth();
  const { rowData } = location.state || {};
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      pageType: rowData?.pageType ? rowData?.pageType : undefined,
      title: rowData?.title || '',
      description: rowData?.description || '',
      highlighted: rowData?.highlighted || []
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();

      setIsButtonDisabled(true);
      const serviceMethod = rowData ? editContent : addContent;
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('pageType', values.pageType);
      rowData && formData.append(`id`, rowData?._id?.toString());

      if (values.pageType === 'homePage') {
        values.highlighted.forEach((highlight: any, index: any) => {
          formData.append(`highlighted[${index}]`, highlight);
        });
      }
      serviceMethod(formData, 'banner')
        .then((res) => {
          const successMessage = rowData ? 'Content updated successfully' : 'Content added successfully';
          const errorMessage = res?.data?.message || 'An error occurred';

          if (res.status === 200 || res.status === 201) {
            Snackbar(successMessage, 'success');
            navigate('/superadmin/landingpagemanagement');
          } else {
            Snackbar(errorMessage, 'error');
            setIsButtonDisabled(false);
          }
        })
        .catch((err) => {
          Snackbar(err?.data?.message || 'An error occurred', 'error');
          setIsButtonDisabled(false);
        });
    }
  });

  return (
    <MainCard
      title={
        <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
          <BackToggle show={true} />
          <span style={{ marginLeft: 10 }}>{rowData ? 'Update Banner Content' : 'Add Banner Content'}</span>
        </span>
      }
      content={false}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ p: 2.5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="pageType">Page Type</InputLabel>
                <Select
                  fullWidth
                  id="pageType"
                  name="pageType"
                  value={formik.values.pageType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.pageType && Boolean(formik.errors.pageType)}
                  displayEmpty
                  sx={{
                    "& .MuiOutlinedInput": {
                      borderRadius: '10px'
                    }
                  }}
                >
                  <MenuItem value="" disabled>
                    Select page type
                  </MenuItem>

                  <MenuItem value="homePage">Home Page</MenuItem>
                  <MenuItem value="contactUs">Contact Us</MenuItem>
                  <MenuItem value="overview">Overview</MenuItem>
                  <MenuItem value="features">Features</MenuItem>
                  <MenuItem value="mobileApp">Mobile App</MenuItem>
                </Select>
                {formik.touched.pageType && formik.errors.pageType && (
                  <FormLabel error sx={{ color: 'red', mt: 1 }}>
                    {String(formik.errors.pageType)}
                  </FormLabel>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">Title</InputLabel>
                <TextField
                  fullWidth
                  id="title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  // helperText={formik.touched.title && formik.errors.title}
                />
                {formik.touched.title && formik.errors.title && (
                  <FormLabel error sx={{ color: 'red', mt: 1 }}>
                    {String(formik.errors.title)}
                  </FormLabel>
                )}
              </Stack>
            </Grid>

            {formik.values.pageType === 'homePage' && (
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="highlighted">Highlighted text</InputLabel>
                  <Autocomplete
                    multiple
                    id="highlighted"
                    options={[]} // You can provide an array of options if needed
                    freeSolo
                    value={formik.values.highlighted || []}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('highlighted', newValue);
                    }}
                    onBlur={formik.handleBlur}
                    renderTags={(value, getTagProps) =>
                      value?.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={formik.touched.highlighted && Boolean(formik.errors.highlighted)}
                      />
                    )}
                  />
                  {formik.touched.highlighted && formik.errors.highlighted && (
                    <FormLabel error sx={{ color: 'red', mt: 1 }}>
                      {String(formik.errors.highlighted)}
                    </FormLabel>
                  )}
                </Stack>
              </Grid>
            )}
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="description">Description</InputLabel>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  multiline
                  rows={4}
                />
                {formik.touched.description && formik.errors.description && (
                  <FormLabel error sx={{ color: 'red', mt: 1 }}>
                    {String(formik.errors.description)}
                  </FormLabel>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ p: 1.5 }}>
          <Button 
           sx={{ 
              fontSize: { xs: "12px", sm: "14px", },
              padding: { xs: "4px 8px", sm: "6px 12px", }, 
            }} 
            variant="contained" 
            type="submit" 
            disabled={isButtonDisabled}
          >
            {rowData ? 'Update' : 'Add'}
          </Button>
          <Link to={''} onClick={() => navigate(-1)}>
            <Button 
              variant="outlined" 
              color="error" 
              sx={{
                fontSize: { xs: "12px", sm: "14px", },
                padding: { xs: "3.5px 10px", sm: "4.5px 10px", },
                ":hover": {
                  color: "white",
                  backgroundColor:  "#dc2626"
                },
              }}
            >
              Cancel
            </Button>
          </Link>
        </Stack>
      </form>
    </MainCard>
  );
}

export default CommonBanner;
