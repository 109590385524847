import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL + 'user';

export const getUserByToken = async () => {
  return await axios.get(API_URL + '/token');
};

export const getAllUser = (reqData: Object) => {
  return axios.get(API_URL + '/all', { params: reqData });
};

export const getCustomUser = (reqData: Object, signal?: any) => {
  return axios.get(API_URL + '/search', { params: reqData, signal });
};

export const getUserTabs = (reqData: Object, signal?: any) => {
  return axios.get(API_URL + '/tabCount', { params: reqData, signal });
};

export const getAllUserWithCount = (reqData: Object) => {
  return axios.get(API_URL + '/withCourseCount', { params: reqData });
};

export const updateUser = (reqData: Object) => {
  return axios.put(API_URL + '/', reqData);
};

export const updateFCMToken = (reqData: Object) => {
  return axios.put(API_URL + '/fcmToken', reqData);
};

export const updateUserNotification = (reqData: Object) => {
  return axios.put(API_URL + '/notificationPermission', reqData);
};

export const deleteUser = (reqData: Object) => {
  return axios.delete(API_URL + '/', { params: reqData });
};
export const removeDevice = (reqData: Object) => {
  return axios.put(API_URL + '/removeDevice', reqData);
};
export const searchUser = (reqData: Object) => {
  return axios.get(API_URL + '/', { params: reqData });
};

export const addUser = (reqData: Object) => {
  return axios.post(API_URL + '/add', reqData);
};

export const changePassword = (reqData: Object) => {
  return axios.put(API_URL + '/changePassword', reqData);
};

export const getUsersWithCourseCount = (reqData: Object, signal?: any) => {
  return axios.get(API_URL + '/withCourseCount', { params: reqData, signal });
};

export const addCSV = (reqData: Object) => {
  return axios.post(API_URL + '/importTrainingMode', reqData);
};

export const addCSVWithoutTrainingMode = (reqData: Object) => {
  return axios.post(API_URL + '/importMultiUser', reqData);
};

export const getUsersWithClassFilter = (reqData: Object) => {
  return axios.get(API_URL + '/withClassFilter', { params: reqData });
};

export const getFilterListUser = (reqData: Object) => {
  return axios.get(API_URL + '/filterListUser', { params: reqData });
};

export const profileUnderReviewStatus = (reqData: Object) => {
  return axios.put(API_URL + '/profileUnderReviewStatus', reqData);
};
export const walkthroughStatus = () => {
  return axios.put(API_URL + '/walkthroughStatus');
};
export const completeGetStartedVideo = (reqData: any) => {
  return axios.put(API_URL + '/onboardingStatus', reqData);
};

export const downloadDemoCsv = (reqData: Object) => {
  return axios.get(API_URL + '/downloadCSVFormat', { params: reqData, responseType: 'blob' });
};

export const userClassroomHistory = (reqData: Object) => {
  return axios.get(API_URL + '/userClassroomHistory', { params: reqData });
};

export const getUserEmailAndPhone = () => {
  return axios.get(API_URL + '/getUserEmailAndPhone');
};
