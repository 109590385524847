import { IconButton, Paper, Slide, Stack, Typography, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { ArrowDown2, ArrowLeft2, ArrowUp2, Gemini, Maximize2 } from 'iconsax-react';
import Dot from '../@extended/Dot';
import Chat from 'pages/chat/Chat';
import DrawerChat from './MainInnerChat';
import useChat from 'hooks/useChat';

const ChatOptions = () => {
  const { user } = useAuth();
  const { getRoomUsers } = useChat();
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [maximize, setMaximize] = useState(false);
  // const [contact, setContact] = useState<string | null>(null);
  // const [messages, setMessages] = useState<Array<{ message: string; type: string }>>([]);
  // const navigate = useNavigate();
  // const location = useLocation();
  const theme = useTheme();

  const handleOpen = () => {
    setOpen(true);
    // setContact(null);
  };
  // const handleChatNavigate = () => {
  //   navigate('/learner/chat', { state: { type: 'aibot' } });
  //   setOpen(false);
  //   setContact('aibot');
  // };
  const handleClose = () => {
    setOpen(false);
    setMaximize(false);
    // setContact(null);
  };
  useEffect(() => {
    if (user) {
      getRoomUsers();
    }
  }, [user, maximize]);
  const handleMaximize = () => {
    setMaximize(true);
  };
  const handleMinimize = () => {
    setMaximize(false);
  };
  // const handleSetChatMessages = (data: { message: string; type: 'bot' | 'user' }[]) => {
  //   setMessages((prev) => [...prev, ...data]);
  // };
  if (user?._id && user?.type === 'student') {
    return (
      <Stack position={'fixed'} bottom={0} right={0} zIndex={99999999} maxWidth={!maximize ? '320px' : '100%'} width={'100%'}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          mt={1}
          maxWidth={!maximize ? '320px' : '100%'}
          height={'50px'}
          sx={{
            backgroundColor: open ? '#141414' : '#a9dfd7',
            overflowX: 'hidden',
            transition: '0.3s ease-in-out',
            transform: open ? 'translateY(0)' : 'translateY(-10%)'
          }}
        >
          <Stack mx={3} direction={'row'} justifyContent={'right'} alignItems={'center'}>
            {drawerOpen && !maximize && (
              <IconButton
                onClick={() => {
                  setDrawerOpen(false);
                }}
              >
                <ArrowLeft2 color={theme.palette.primary.main} />
              </IconButton>
            )}
            <Stack direction={'row'} alignItems={'flex-start'}>
              <Typography variant="h4" color={'primary'}>
                {user?.firstName}
              </Typography>
              <Dot color="success" />
            </Stack>
          </Stack>
          <Stack direction={'row'}>
            <IconButton onClick={open ? handleClose : handleOpen} sx={{ mr: 0.5 }}>
              {open ? <ArrowDown2 color={theme.palette.primary.main} /> : <ArrowUp2 color={theme.palette.primary.main} />}
            </IconButton>
            {open && (
              <IconButton onClick={maximize ? handleMinimize : handleMaximize} sx={{ ml: 0.5 }}>
                {maximize ? <Gemini color={theme.palette.primary.main} /> : <Maximize2 color={theme.palette.primary.main} />}
              </IconButton>
            )}
          </Stack>
        </Stack>

        <Slide direction="up" in={open} unmountOnExit>
          <Paper
            elevation={2}
            sx={{ maxHeight: { lg: '92.2vh', md: '92.2vh', xs: '94vh' }, height: '100%', overflow: 'auto', borderRadius: '0px' }}
          >
            {!maximize ? <DrawerChat open={drawerOpen} setOpen={setDrawerOpen} /> : <Chat />}
          </Paper>
        </Slide>
      </Stack>
    );
  }
  return null;
};

export default ChatOptions;
