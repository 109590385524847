import { lazy } from 'react';
import Loadable from 'components/Loadable';
import { useState, ReactNode, SyntheticEvent } from 'react';
import { Box, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import MainCard from 'components/MainCard';
import { useLocation } from 'react-router';
import BackToggle from 'components/third-party/dropzone/BackToggle';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import useAuth from 'hooks/useAuth';
import QuizGroupList from './quizGroupList';
import MarketPlaceGroups from './MarketPlaceGroups';
const CourseForm = Loadable(lazy(() => import('pages/course/courseForm')));
// const GroupTable = Loadable(lazy(() => import('pages/course/courseData/GroupTable')));
// const UserTable = Loadable(lazy(() => import('pages/course/courseData/userTable')));
const AnnouncementForm = Loadable(lazy(() => import('pages/course/Announcement/AnnouncementForm')));
const FaqForm = Loadable(lazy(() => import('pages/course/Faq/FaqForm')));
const ReviewPage = Loadable(lazy(() => import('pages/course/courseData/ReviewPage')));
const File = Loadable(lazy(() => import('pages/course/courseData/File')));
const AssignmentList = Loadable(lazy(() => import('pages/course/courseData/ViewAssignment')));

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

// ==============================|| TABS - BASIC ||============================== //
function QuizGroupTabs() {
  const { user }: any = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = useState(0);
  const isSuperAdmin = user?.type == 'superadmin';
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    // <MainCard
    //   title={
    //     <span style={{ display: 'flex', flexDirection: 'row' }}>
    //       {/* <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
    //         <BackToggle show={true} />
    //       </span> */}
    //       <span style={{ display: 'flex', flexDirection: 'column' }}>
    //         <Typography variant="h5">
    //           <FormattedMessage id="Tickets List" />
    //         </Typography>
    //         <Typography variant="body2">
    //           <FormattedMessage id="Manage tickets raised by your organization’s users with options to view and take action as needed." />
    //         </Typography>
    //       </span>
    //     </span>
    //   }
    //   content={false}
    // >
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant={isMobile ? 'scrollable' : 'standard'}>
          {!isSuperAdmin && <Tab label="My Groups" {...a11yProps(0)} />}
          {/* <Tab label="Free Groups" {...a11yProps(1)} /> */}
          {!isSuperAdmin && <Tab label="Paid Groups" {...a11yProps(1)} />}
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <QuizGroupList type="org" />
      </TabPanel>

      {/* {isSuperAdmin ? (
        <></>
      ) : (
        <TabPanel value={value} index={1}>
          <QuizGroupList type="free" />
        </TabPanel>
      )} */}

      <TabPanel value={value} index={1}>
        {/* <QuizGroupList type="paid" /> */}
        <MarketPlaceGroups />
      </TabPanel>
    </Box>
    // </MainCard>
  );
}

export default QuizGroupTabs;
