// third-party
import { createSlice } from '@reduxjs/toolkit';

// types
import { CartCheckoutStateProps } from 'types/cart';

// ----------------------------------------------------------------------

const initialState: CartCheckoutStateProps = {
  cart: {
    _id: '',
    name: '',
    image: '',
    offerPrice: 0,
    color: '',
    size: '',
    quantity: 0,
    courseId: {
      name: '',
      _id: '',
      createdBy: {
        _id: '',
        firstName: '',
        lastName: '',
      },
      description: '',
      price: 0,
      offerPrice: 0,
      image: {
        path: '',
        _id: '',
        file: '',
        filename: '',
        mimetype: '',
        deleted: false,
        created_at: '',
        updated_at: ''
      }
    },
    groupId: {
      name: '',
      _id: '',
      description: '',
      price: 0,
      offerPrice: 0
    },
    questionBankId: {
      name: '',
      _id: '',
      price: 0,
    },
    offerId: ''
  },
  promoCode: '',
  payment: {
    type: 'free',
    method: 'card',
    card: ''
  }
};

// ==============================|| SLICE - CART ||============================== //
const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCart(state, action) {
      state.cart = action.payload;
    },
    removeCourseFromCart(state, action) {
      state.cart = action.payload;
    },
    addPromoCode(state, action) {
      state.promoCode = action.payload;
    },
    setOfferPromoCode(state, action) {
      state.cart = action.payload.data;
      state.promoCode = action.payload.promoCode;
    },
    removeOfferPromoCode(state, action) {
      state.cart = action.payload;
      state.promoCode = '';
    },
    setPaymentMethod(state, action) {
      state.payment = {
        ...state.payment,
        method: action.payload
      };
    },
    setPaymentCard(state, action) {
      state.payment = {
        ...state.payment,
        card: action.payload
      };
    }
  }
});

// Reducer

export const { setCart, removeCourseFromCart, addPromoCode, setOfferPromoCode, removeOfferPromoCode, setPaymentMethod, setPaymentCard } =
  slice.actions;
export default slice.reducer;
