import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Radio,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Stack } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import { ArchiveTick, Profile2User, SearchNormal1, ShoppingCart, TaskSquare } from 'iconsax-react';
import { formatCurrency } from 'utils/helper';
import { addToCart } from 'services/order.service';
import { dispatch, useSelector } from 'store';
import Snackbar from 'utils/Snackbar';
import useAuth from 'hooks/useAuth';
import { setCart } from 'store/reducers/cart';
import HoverSocialCard from 'components/cards/statistics/HoverSocialCard';
import { getAllOrgQuizGroup, getAllQuizGroup } from 'services/quiz.service';
import { searchQuizGroupTable, setQuizGroup, setQuizGroupTableCount } from 'store/reducers/quizGroup';
import MainCard from 'components/MainCard';
import DummyButton from 'layout/MainLayout/Header/HeaderContent/DummyButton';
import Loader2 from 'components/Loader2';
import useConfig from 'hooks/useConfig';
import IconButton from 'components/@extended/IconButton';

// const quizGroupData = [
//   {
//     id: 1,
//     groupName: 'Math Quiz Group',
//     questionCount: 50,
//     packages: [
//       { duration: 'monthly', price: 100 },
//       { duration: 'quarterly', price: 250 },
//       { duration: 'yearly', price: 400 },
//       { duration: 'lifetime', price: 600 }
//     ]
//   },
//   {
//     id: 2,
//     groupName: 'Science Quiz Group',
//     questionCount: 80,
//     packages: [
//       { duration: 'monthly', price: 120 },
//       { duration: 'quarterly', price: 300 },
//       { duration: 'yearly', price: 450 },
//       { duration: 'lifetime', price: 700 }
//     ]
//   }
// ];

const MarketPlaceGroups = () => {
  const quizGroupData = useSelector((state: any) => state?.quizGroup?.quizGroups);
  const theme = useTheme();
  const { i18n } = useConfig();
  const { user, organization } = useAuth();
  const isDarkMode = theme.palette.mode == 'dark';
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [notFound, setNotFound] = useState(true);
  const [cartPrice, setCartPrice] = useState<number>(0);
  const [isCart, setIsCart] = useState(false);
  const { cart } = useSelector((state: any) => state.cart);
  const count = useSelector((state: any) => state?.quizGroup)?.count;
  const search = useSelector((state: any) => state?.quizGroup)?.search;
  const [selectedPackage, setSelectedPackage] = useState<string | null>(null);
  const [packages, setPackages] = useState([]);
  const [value, setValue] = useState('');
  const currencySettings = organization?.basicsettings?.locale;
  const [quizGroupId, setQuizgroupId] = useState<string>('');
  const [duration, setDuration] = useState<string>('');
  const [open1, setOpen1] = useState<boolean>(false);
  const loggedInUserOrgId = user?.organizationId?._id;
  const [lazyLoad, setLazyLoad] = useState(false);
  const [skip, setSkip] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);

  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleSelect = (price: any, duration: any) => {
    setDuration(duration);
    setSelectedPackage(duration);
    setCartPrice(price);
  };

  const openCart = (id: string, plans: any) => {
    setQuizgroupId(id);
    setPackages(plans);
    setOpen1(true);
  };

  const handleSearch = () => {
    dispatch(searchQuizGroupTable(value));
  };

  const handleCart = () => {
    setIsLoading(true);

    if (cartPrice && duration && quizGroupId) {
      addToCart({
        userId: user?._id,
        questionBankId: quizGroupId,
        duration: duration,
        price: cartPrice,
        subtotal: cartPrice,
        totalAmount: cartPrice
      })
        .then((res) => {
          if (res.status === 201 || res.status === 200) {
            setIsCart(true);
            setIsLoading(false);
            dispatch(setCart(res.data.data));
            Snackbar('Question bank added to cart successfully!!!', 'success');
          } else {
            Snackbar(res.data.message, 'error');
          }
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            setIsLoading(false);
            const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
            Snackbar(errorMessage, 'warning');
          } else {
            setIsLoading(false);
            const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
            Snackbar(errorMessage, 'error');
          }
        });
    }
  };

  const getAllQuizGroups = () => {
    setIsLoading(true);
    let sort: any = {};
    let reqData: any = { organizationId: loggedInUserOrgId };

    reqData.sort = sort;
    if (value) reqData.search = value;

    getAllQuizGroup(reqData)
      .then((result) => {
        if (result.status === 200) {
          setIsLoading(false);
          dispatch(setQuizGroup(result?.data.data.questionBanks));
          dispatch(setQuizGroupTableCount(result.data.data?.count ? result.data.data?.count : result.data.data?.length));
          setLazyLoad(true);
          setLoadingMore(false);
        }
        if (result.data.data.length > 0) {
          setNotFound(false);
          setIsLoading(false);
          setLoadingMore(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });

    // eslint-disable-next-line
  };
  useEffect(() => {
    getAllQuizGroups();
  }, [search]);

  const handleLength = () => {
    if (!loadingMore) {
      setLoadingMore(true);
      setSkip((prevSkip) => prevSkip + 1);
      // getAllQuizGroups();
    }
  };

  return (
    <MainCard
      title={
        <span style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5">
            <FormattedMessage id={'Market Place Question Groups'} />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage id="Manage groups of Questions and their details, with options to add, edit, delete." />
          </Typography>
        </span>
      }
      content={false}
      secondary={
        <Stack alignItems="center" direction="row-reverse" sx={{ py: 1, mr: 2 }}>
          <Stack>
            <Tooltip title={<FormattedMessage id="Search" />}>
              <span>
                <IconButton variant="outlined" color="primary" sx={{ ml: 0.5 }} onClick={handleSearch} disabled={!value || value === ''}>
                  <SearchNormal1 />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
          <Stack>
            <TextField
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                if (e.target.value === '') {
                  dispatch(searchQuizGroupTable(undefined));
                }
              }}
              placeholder={
                i18n === 'fr'
                  ? `recherche à partir de ${count || 0} enregistrements`
                  : i18n === 'ro'
                  ? `cauta din ${count || 0} inregistrari`
                  : i18n === 'zh'
                  ? `从 ${count || 0} 条记录中搜索`
                  : i18n === 'ar'
                  ? 'بحث في السجلات...'
                  : i18n === 'hindi'
                  ? '${count || 0}रिकॉर्ड खोजें...'
                  : `Search ${count || 0} records...`
              }
            />
          </Stack>
        </Stack>
      }
    >
      <Box sx={{ p: 2 }}>
        {/* <Typography variant="h4" gutterBottom>
        Quiz Groups
      </Typography> */}
        <Dialog
          open={open1}
          onClose={handleClose1}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            sx: {
              borderRadius: '16px',
              overflow: 'hidden',
              boxShadow: '0px 8px 20px rgba(0, 123, 255, 0.2)',
              backgroundColor: isDarkMode ? '#1e1e1e' : '#f5f7fa'
            }
          }}
        >
          <Box
            sx={{
              p: 3,
              textAlign: 'center',
              backgroundColor: isDarkMode ? '#1D2630' : '#f5f7fa'
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                fontSize: '22px',
                fontWeight: 'bold',
                color: isDarkMode ? '#90caf9' : '#007BFF',
                mb: 2
              }}
            >
              Select Plan
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} alignItems={'center'}>
                {packages?.map((pkg: any) => (
                  <Grid item xs={12} sm={6} md={3} key={pkg.duration}>
                    <Box
                      onClick={() => handleSelect(pkg.price, pkg.duration)}
                      sx={{
                        border: selectedPackage === pkg.duration ? '3px solid #007BFF' : '1px solid #444',
                        borderRadius: '12px',
                        minHeight: '140px',
                        minWidth: '140px',
                        padding: '20px',
                        cursor: 'pointer',
                        transition: '0.3s',
                        backgroundColor: selectedPackage === pkg.duration && isDarkMode ? '#004ba0' : isDarkMode ? '#333' : '#FFFFFF',
                        color: isDarkMode ? '#FFFFFF' : '#333',
                        textAlign: 'center',
                        '&:hover': {
                          border: '3px solid #0056b3',
                          backgroundColor: isDarkMode ? '#0056b3' : '#E3F2FD'
                        }
                      }}
                    >
                      <Typography sx={{ fontSize: '18px', fontWeight: 700, color: 'inherit' }} gutterBottom>
                        {pkg.duration.charAt(0).toUpperCase() + pkg.duration.slice(1)} Plan
                      </Typography>
                      <Typography sx={{ fontSize: '16px', fontWeight: 500, color: 'inherit' }}>
                        {formatCurrency(pkg.price?.toString(), {
                          country: currencySettings?.code,
                          currency: currencySettings?.currency
                        })}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  // setDisabled(true);
                  handleClose1();
                  handleCart();
                }}
                autoFocus
              >
                Add to Cart
              </Button>
              <Button variant="outlined" color="error" onClick={handleClose1}>
                Cancel
              </Button>
            </DialogActions>
          </Box>
        </Dialog>

        <Grid container spacing={3}>
          {quizGroupData?.map((group: any) => (
            <Grid item xs={12} sm={6} md={4} key={group.id}>
              <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
                <CardHeader
                  sx={{ backgroundColor: theme.palette.primary.light, padding: 0 }}
                  title={
                    <Stack
                      sx={{ mt: 1.5, mx: 1.5 }}
                      display={'flex'}
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Typography
                        sx={{
                          fontSize: '20px',
                          fontWeight: 600,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '250px'
                        }}
                        gutterBottom
                      >
                        {group?.name}
                      </Typography>
                      {group?.assigned ? (
                        <Tooltip
                          title="Assigned"
                          // title={<FormattedMessage id={isCart || group?.inCart ? 'Added to cart' : 'Add to cart'} />}
                        >
                          <IconButton
                            color={'secondary'}
                            sx={{
                              width: '40px',
                              height: '40px',
                              border: 'none',
                              '&:hover': {
                                backgroundColor: 'transparent',
                                boxShadow: 'none'
                              }
                            }}
                            // onClick={() => {
                            //   openCart(group?._id, group?.packages);
                            // }}
                          >
                            <ArchiveTick />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={
                            group?.inCart || cart?.cartItems?.questionBankItems?.some((item: any) => item?.questionBankId === group?._id)
                              ? 'Added to cart'
                              : 'Add to cart'
                          }
                          // title={<FormattedMessage id={isCart || group?.inCart ? 'Added to cart' : 'Add to cart'} />}
                        >
                          <span>
                            <IconButton
                              color={'secondary'}
                              disabled={
                                cart?.cartItems?.questionBankItems?.some((item: any) => item?.questionBankId === group?._id) ||
                                // isCart ||
                                group?.inCart
                              }
                              sx={{
                                width: '40px',
                                height: '40px',
                                border: 'none',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                  boxShadow: 'none'
                                }
                              }}
                              // onClick={() => handleCart(group?.price, group?._id)}
                              onClick={() => {
                                openCart(group?._id, group?.packages);
                              }}
                            >
                              <ShoppingCart />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                    </Stack>
                  }
                  titleTypographyProps={{ fontSize: '20px', fontWeight: 'bold' }}
                />
                <CardContent
                  sx={{
                    padding: '16px',
                    backgroundColor: isDarkMode ? '#1A2538' : '#F9FAFB',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    transition: '0.3s',
                    '&:hover': {
                      backgroundColor: isDarkMode ? '#1D2630' : '#E9F0FF',
                      transform: 'translateY(-8px)',
                      boxShadow: isDarkMode ? '0px 8px 15px #1A2538' : '0px 8px 15px #E9F0FF'
                    }
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={6}
                    sx={{
                      color: '#37474F',
                      '& .highlight': {
                        color: '#007BFF'
                      }
                    }}
                  >
                    <Stack direction="column" alignItems="center" justifyContent="center">
                      <Typography sx={{ fontSize: '24px', fontWeight: 700 }} className="highlight">
                        {group.questionCount}
                      </Typography>
                      <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Questions</Typography>
                    </Stack>
                    <Stack direction="column" alignItems="center" justifyContent="center">
                      <Typography sx={{ fontSize: '24px', fontWeight: 700 }} className="highlight">
                        {group.board}
                      </Typography>
                      <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Board</Typography>
                    </Stack>
                  </Stack>
                </CardContent>

                {/* <CardActions sx={{ justifyContent: 'center' }}>
                <Button variant="contained" color="primary" onClick={() => handleAddToCart(group.id)}>
                  Add to Cart
                </Button>
              </CardActions> */}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      {lazyLoad && count > quizGroupData.length && (
        <>
          <DummyButton handleLength={handleLength}>
            <></>
          </DummyButton>
        </>
      )}
      {!lazyLoad && count > quizGroupData.length && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <Loader2 />
        </Box>
      )}
    </MainCard>
  );
};

export default MarketPlaceGroups;
