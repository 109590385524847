import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL + 'orders';

export const addToCart = (reqData: Object) => {
  return axios.post(API_URL + '/add', reqData);
};
export const redeemOffer = async (reqData: object) => {
  return await axios.put(API_URL + '/redeemOffer', reqData);
};
export const removeOffer = async (reqData: object) => {
  return await axios.put(API_URL + '/removeOffer', reqData);
};
export const getUserCart = (reqData: Object) => {
  return axios.get(API_URL + '/getUserCartData', { params: reqData });
};
export const getUserOrder = (reqData: Object) => {
  return axios.get(API_URL + '/getUserOrderData', { params: reqData });
};
export const removeFromCart = (reqData: Object) => {
  return axios.put(API_URL + '/removeFromCart', reqData);
};
export const getUserOrderHistory = (reqData: Object) => {
  return axios.get(API_URL + '/history', { params: reqData });
};
