import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Tooltip,
  Typography,
  SelectChangeEvent,
  Chip,
  useTheme,
  Grid,
  InputLabel,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import IconButton from 'components/@extended/IconButton';
import MainCard from 'components/MainCard';
import useAuth from 'hooks/useAuth';
import Loader2 from 'components/Loader2';
import { AddCircle, Bag, Edit, People, RefreshSquare, Send, ShoppingCart, TaskSquare } from 'iconsax-react';
import { dispatch } from 'store';
import { addToCart } from 'services/order.service';
import { formatCurrency, formatDateBasedOnOrgSettings } from 'utils/helper';
import Snackbar from 'utils/Snackbar';
import {
  delQuizGroup,
  resetStateQuizGroup,
  searchQuizGroupTable,
  setQuizGroup,
  setQuizGroupTableCount,
  setQuizGroupTablePageIndex,
  setQuizGroupTablePageSize,
  setSortingValue
} from 'store/reducers/quizGroup';
import CustomTable from 'components/@extended/CustomTable';
import SkeletonTable from 'components/SkeletonTable';
import { FormattedMessage } from 'react-intl';
import ModalFilter from 'components/ModalFilters2';
// import useConfig from 'hooks/useConfig';
import { getFilterListUser } from 'services/user.service';
import moment from 'moment';
import useContemp from 'hooks/useContemp';
import Avatar from 'components/@extended/Avatar';
import { deleteQuizGroup, getAllOrgQuizGroup, getAllQuizGroup } from 'services/quiz.service';
import { setCart } from 'store/reducers/cart';
import MarketPlaceGroups from './MarketPlaceGroups';

function QuizGroupList({ type }: any) {
  const navigate = useNavigate();
  const { user, organization } = useAuth();
  const { pathname } = useLocation();
  // const { i18n } = useConfig();
  const groupPermission = user?.userTypeId?.permission?.QuestionGroup;
  const loggedInUserOrgId = user?.organizationId?._id;
  const quizGroupData = useSelector((state: any) => state?.quizGroup?.quizGroups);

  // const paidGroupData = quizGroupData?.filter((item: any) => Array.isArray(item?.packages) && item.packages.length > 0);

  // const freeGroupData = quizGroupData?.filter((item: any) => !Array.isArray(item?.packages) || item.packages.length === 0);

  const search = useSelector((state: any) => state?.quizGroup)?.search;
  const sortData = useSelector((state: any) => state.quizGroup)?.sortingData;
  const currentPage = useSelector((state: any) => state?.quizGroup)?.pageIndex;
  const pageSize = useSelector((state: any) => state?.quizGroup)?.pageSize;
  const count = useSelector((state: any) => state?.quizGroup)?.count;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [open1, setOpen1] = useState<boolean>(false);
  const [quizGroupId, setQuizgroupId] = useState<string>('');
  const [duration, setDuration] = useState<string>('');
  const [plan, setPlan] = useState<string>('');
  const [cartPrice, setCartPrice] = useState<number>(0);
  const [value, setValue] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [notFound, setNotFound] = useState(true);
  const [submitFilters, setSubmitFilters] = useState(false);
  const theme = useTheme();
  const mode = theme.palette.mode;
  const isFromVidyalaya = organization?.vidyalayaMigration;
  const batchData = organization?.batches;
  const { setData, prevStoredData } = useContemp();
  const [isCart, setIsCart] = useState(false);
  const { cart } = useSelector((state: any) => state.cart);
  const [selectedPackage, setSelectedPackage] = useState<string | null>(null);
  const [packages, setPackages] = useState([]);
  const currencySettings = organization?.basicsettings?.locale;

  function capitalizeFirstLetter(str: any) {
    return str
      .split(' ')
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
  const navigatePath = user?.type === 'superadmin' ? '/superadmin/question-group/add' : '/question-group/add';

  const [disabledFilter, setDisabledFilter] = useState(true);
  // const [publishStatus, setPublishStatus] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<any>(
    prevStoredData?.[location.pathname]?.filters
      ? prevStoredData?.[location.pathname]?.filters
      : {
          search: '',
          FilterTypes: [],
          // publishStatus: [],
          // createdBy: { ids: [], names: [], indices: [] },
          startDate: null,
          endDate: null,
          batches: isFromVidyalaya ? { names: [], indices: [] } : undefined,
          // batches: undefined,
          ...(user?.type !== 'superadmin' && {
            createdBy: { ids: [], names: [], indices: [] }
          })
        }
  );

  const [filterSubmitValues, setFilterSubmitValues] = useState<any>({
    search: '',
    FilterTypes: [],
    startDate: null,
    endDate: null,
    batches: isFromVidyalaya ? { names: [], indices: [] } : undefined,
    ...(user?.type !== 'superadmin' && {
      createdBy: { ids: [], names: [], indices: [] }
    })
  });
  const options = isFromVidyalaya ? ['createdBy', 'batches'] : ['createdBy'];
  // const options = [user?.type !== 'admin' && 'createdBy'];
  const [cretedByData, setCreatedByData] = useState([]);
  const [totalFilter, setTotalFilter] = useState<any>({
    search: '',
    FilterTypes: { val1: options, val2: 'checkbox', val3: {} }
  });

  const getAllQuizGroups = () => {
    setIsLoading(true);
    let sort: any = {};
    let reqData: any = { organizationId: loggedInUserOrgId, limit: pageSize, skip: currentPage };

    sortData?.forEach((val: any) => {
      if (val.sort && val.desc) {
        sort[val.id] = -1;
      } else if (val.sort) {
        sort[val.id] = 1;
      } else {
        delete sort[val.id];
      }
    });
    reqData.sort = sort;
    // if (type !== 'org') reqData.catalogueView = true;
    if (filterValues?.search) reqData.search = filterValues.search;
    // reqData.publishStatus = filterValues?.publishStatus;
    if (filterValues?.startDate) reqData.startDate = filterValues.startDate;
    if (filterValues?.startDate) reqData.endDate = filterValues.endDate ? filterValues.endDate : new Date();
    if (filterValues?.createdBy?.ids?.length > 0) reqData.createdBy = filterValues?.createdBy?.ids;

    // if (search) reqData.search = search;
    if (user?.type !== 'superadmin') {
      getAllOrgQuizGroup(reqData)
        .then((result) => {
          if (result.status === 200) {
            setDisabledFilter(false);
            setIsLoading(false);
            dispatch(setQuizGroup(result?.data.data.questionBanks));
            dispatch(setQuizGroupTableCount(result.data.data?.count ? result.data.data?.count : result.data.data?.length));
          }
          if (result.data.data.length > 0) {
            setNotFound(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      getAllQuizGroup(reqData)
        .then((result) => {
          if (result.status === 200) {
            setDisabledFilter(false);
            setIsLoading(false);
            dispatch(setQuizGroup(result?.data.data.questionBanks));
            dispatch(setQuizGroupTableCount(result.data.data?.count ? result.data.data?.count : result.data.data?.length));
          }
          if (result.data.data.length > 0) {
            setNotFound(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
    // eslint-disable-next-line
  };

  useEffect(() => {
    getAllQuizGroups();
  }, [sortData, search, pageSize, currentPage, submitFilters]);

  useEffect(() => {
    if (typeof prevStoredData?.[pathname]?.currentPage === 'number')
      dispatch(setQuizGroupTablePageIndex(prevStoredData?.[pathname]?.currentPage));
    if (typeof prevStoredData?.[pathname]?.pageSize === 'number') dispatch(setQuizGroupTablePageSize(prevStoredData?.[pathname]?.pageSize));
    return () => {
      dispatch(resetStateQuizGroup());
    };
  }, [dispatch]);

  const openDialogBox = (id: string) => {
    setQuizgroupId(id);
    setOpen(true);
  };
  const openCart = (duration: any, id: string, plans: any) => {
    setDuration(id);
    setQuizgroupId(duration);
    setPackages(plans);
    setOpen1(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleSelect = (id: string, price: any) => {
    setSelectedPackage(id);
    setCartPrice(price);
  };
  function checkEnable(createdBy: String) {
    if (user?.type === 'admin') return false;
    if (user?.type === 'student') return true;
    if (user?.type === 'faculty') {
      if (user?._id !== createdBy) return true;
      if (user?._id === createdBy) return false;
    }
  }
  function checkAllPermission(isPublished: boolean, permission: boolean, createdBy: string) {
    return isPublished ? true : permission ? checkEnable(createdBy) : true;
  }
  const deleteGroupQuiz = async (quizGroupId: string) => {
    await deleteQuizGroup({ questionBankId: quizGroupId })
      .then((result) => {
        handleClose();
        dispatch(setQuizGroupTableCount(count - 1));
        if (result.status === 200) {
          dispatch(delQuizGroup(quizGroupId));
          Snackbar('Quiz Group deleted successfully', 'success');
        } else {
          Snackbar(result.data.message, 'error');
        }
        setDisabled(false);
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
          Snackbar(errorMessage, 'warning');
        } else {
          const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
          Snackbar(errorMessage, 'error');
        }
        setDisabled(false);
      });
  };
  // const handleCart = () => {
  //   setIsLoading(true);
  //   if (cartPrice && duration && quizGroupId) {
  //     addToCart({
  //       userId: user?._id,
  //       questionBankId: quizGroupId,
  //       duration: duration,
  //       price: cartPrice,
  //       subtotal: cartPrice,
  //       totalAmount: cartPrice
  //     })
  //       .then((res) => {
  //         if (res.status === 201 || res.status === 200) {
  //           setIsCart(true);
  //           setIsLoading(false);
  //           dispatch(setCart(res.data.data));
  //           Snackbar('Question bank added to cart successfully!!!', 'success');
  //         } else {
  //           Snackbar(res.data.message, 'error');
  //         }
  //       })
  //       .catch((err) => {
  //         if (err?.response?.status === 403) {
  //           setIsLoading(false);
  //           const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
  //           Snackbar(errorMessage, 'warning');
  //         } else {
  //           setIsLoading(false);
  //           const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
  //           Snackbar(errorMessage, 'error');
  //         }
  //       });
  //   }
  // };
  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'classType',
        accessor: 'classType',
        Cell: (row: any) => {
          return (
            <Chip
              sx={{ textTransform: 'capitalize' }}
              color={'primary'}
              variant="filled"
              label={row?.cell?.row?.original?.classType ? row?.cell?.row?.original?.classType : 'none'}
              size="small"
            />
          );
        }
      },
      // {
      //   Header: 'Class',
      //   accessor: 'className',
      //   Cell: (row: any) => {
      //     return row?.cell?.row?.original?.classRoomId?.className ? row?.cell?.row?.original?.classRoomId?.className : '-';
      //   }
      // },
      // {
      //   Header: 'Details',
      //   accessor: 'users.length',
      //   Cell: (row: any) => {
      //     const usersCount = row?.cell?.row?.original?.users ? row?.cell?.row?.original?.users.length : 0;
      //     const coursesCount = row?.cell?.row?.original?.courses ? row?.cell?.row?.original?.courses.length : 0;
      //     return (
      //       <>
      //         <Typography>Users: {usersCount}</Typography>
      //         <Typography>Courses: {coursesCount}</Typography>
      //       </>
      //     );
      //   }
      // },
      // {
      //   Header: 'User Count',
      //   accessor: 'users.length'
      // },
      // {
      //   Header: 'Courses Count',
      //   accessor: 'courses.length'
      // },
      // {
      //   Header: 'Files Count',
      //   accessor: 'files.length'
      // },
      {
        Header: 'Created By',
        accessor: 'createdBy',
        paddingLeft: '3px',
        Cell: ({ row, value }: { row: any; value: any }) => (
          <Box sx={{ textTransform: 'capitalize', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {row.original.createdBy?.firstName ? (
              <Tooltip
                title={
                  <>
                    {user?.type !== 'superadmin'
                      ? formatDateBasedOnOrgSettings(row.original.created_at, organization)
                      : moment.utc(row.original.created_at).format('YYYY-MM-DD hh:mm A')}
                    <br />
                    {`${value?.firstName ?? ''} ${value?.lastName ?? ''}`}
                  </>
                }
              >
                <span>
                  <Avatar
                    alt={value?.firstName}
                    size="sm"
                    src={
                      value?.userProfilePicId?.path
                        ? process.env.REACT_APP_FILE_URL + value?.userProfilePicId?.path
                        : value?.userProfilePicId?.mimetype && value?.userProfilePicId?.file
                        ? `data:${value?.userProfilePicId?.mimetype};base64,${value?.userProfilePicId?.file}`
                        : ''
                    }
                    sx={{ margin: '8px 0' }}
                  />
                </span>
              </Tooltip>
            ) : (
              '--'
            )}
          </Box>
        )
      },
      {
        Header: 'Last Updated By',
        accessor: 'updatedBy',
        paddingLeft: '3px',
        Cell: ({ row, value }: { row: any; value: any }) => (
          <Box sx={{ textTransform: 'capitalize', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {value?.length > 0 ? (
              <Tooltip
                title={
                  <span style={{ textAlign: 'center', justifyContent: 'center' }}>
                    {user?.type !== 'superadmin'
                      ? formatDateBasedOnOrgSettings(row.original.created_at, organization)
                      : moment.utc(row.original.created_at).format('YYYY-MM-DD hh:mm A')}
                    <br />
                    {`${value[value?.length - 1]?.firstName} ${value[value?.length - 1]?.lastName}`}
                  </span>
                }
              >
                <span>
                  <Avatar
                    alt={value[value?.length - 1]?.firstName}
                    size="sm"
                    src={
                      value[value?.length - 1]?.userProfilePicId?.path
                        ? process.env.REACT_APP_FILE_URL + value[value?.length - 1]?.userProfilePicId?.path
                        : value[value?.length - 1]?.userProfilePicId?.mimetype && value[value?.length - 1]?.userProfilePicId?.file
                        ? `data:${value[value?.length - 1]?.userProfilePicId?.mimetype};base64,${
                            value[value?.length - 1]?.userProfilePicId?.file
                          }`
                        : ''
                    }
                    sx={{ margin: '8px 0' }}
                  />
                </span>
              </Tooltip>
            ) : (
              '--'
            )}
          </Box>
        )
      }
      // {
      //   Header: 'price',
      //   accessor: 'price',
      //   Cell: ({ cell }: { cell: any }) => {
      //     return cell.row.original.price && cell.row.original.price > 0 ? (
      //       <Typography variant="body1">Paid: {cell.row.original.price}</Typography>
      //     ) : (
      //       <Typography variant="body1">Free</Typography>
      //     );
      //   }
      // }
    ];

    baseColumns.push({
      Header: 'Options',
      accessor: 'progress',
      Cell: (row: any) => {
        useEffect(() => {
          if (
            cart?.cartItems?.questionBankItems?.some((item: any) => item?.questionBankId?._id === row?.cell?.row?.original?._id) ||
            row?.cell?.row?.original?.inCart
          ) {
            setIsCart(true);
          }
        }, [cart, row]);
        console.log('row?.cell?.row?.original?.packages', row?.cell?.row?.original?.packages);
        return (
          <>
            {row?.cell?.row?.original?.packages && row?.cell?.row?.original?.packages?.length > 0 && user?.type !== 'superadmin' ? (
              <Tooltip title={<FormattedMessage id={isCart || row?.cell?.row?.original?.inCart ? 'Added to cart' : 'Add to cart'} />}>
                <span>
                  <IconButton
                    color={'primary'}
                    disabled={
                      cart?.cartItems?.questionBankItems?.some(
                        (item: any) => item?.questionBankId?._id === row?.cell?.row?.original?._id
                      ) ||
                      isCart ||
                      row?.cell?.row?.original?.inCart
                    }
                    // onClick={() => handleCart(row?.cell?.row?.original?.price, row?.cell?.row?.original?._id)}
                    onClick={() => {
                      openCart(row?.cell?.row?.original?.duration, row?.cell?.row?.original?._id, row?.cell?.row?.original?.packages);
                    }}
                  >
                    <ShoppingCart />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <Stack direction="row" spacing={0}>
                <Tooltip title={<FormattedMessage id="Edit" />}>
                  <span>
                    <IconButton
                      color={'primary'}
                      disabled={
                        user?.type !== 'superadmin' &&
                        checkAllPermission(
                          row?.cell?.row?.original?.isPublished,
                          groupPermission?.Update,
                          row?.cell?.row?.original?.createdBy?._id
                        )
                      }
                      onClick={(e) => {
                        // setData({
                        //   [pathname]: { pageSize, currentPage, filters: filterValues, gotoUrl: '/question-group/add' }
                        // });
                        navigate(navigatePath, {
                          state: { rowData: row.cell.row.original, quizGroup: quizGroupData }
                        });
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="Delete" />}>
                  <span>
                    <IconButton
                      color={'error'}
                      disabled={
                        user?.type !== 'superadmin' &&
                        checkAllPermission(
                          row?.cell?.row?.original?.isPublished,
                          groupPermission?.Delete,
                          row?.cell?.row?.original?.createdBy?._id
                        )
                      }
                      onClick={() => {
                        openDialogBox(row.row.original?._id);
                      }}
                    >
                      <Bag />
                    </IconButton>
                  </span>
                </Tooltip>
                {user?.type === 'superadmin' && (
                  <Tooltip title={<FormattedMessage id="View Assigned Organizations" />}>
                    <span>
                      <IconButton
                        color={'primary'}
                        // disabled={}
                        onClick={() => {
                          // navigate('/assignment/studentView', { state: { assignmentId: row?.cell?.row?.original?._id } });
                          navigate('/superadmin/question-group/organizations', { state: { quizGroup: row?.cell?.row?.original } });
                        }}
                      >
                        <Send />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </Stack>
            )}
          </>
        );
      }
    });
    return baseColumns;
  }, [quizGroupData]);

  const handleSearch = () => {
    dispatch(searchQuizGroupTable(value));
  };

  const handleChange = (event: SelectChangeEvent<number>) => {
    dispatch(setQuizGroupTablePageIndex(1));
    dispatch(setQuizGroupTablePageSize(+event.target.value));
  };

  const handlePageChange = (event: any, page: number) => {
    dispatch(setQuizGroupTablePageIndex(page));
  };
  const setSortingData = (data: any) => {
    const index1 = sortData?.findIndex((item: any) => item?.id === data?.name);
    let arr: any = sortData;
    if (sortData?.[index1]?.sort === true && sortData?.[index1]?.desc === true) {
      let arrCopy = [...sortData];
      arrCopy.splice(index1, 1);

      dispatch(setSortingValue(arrCopy));
    } else if (sortData?.[index1]?.sort === true) {
      let data: any = arr[index1];
      const newData = { ...data, desc: true };
      const newArr: any = [...arr];
      newArr[index1] = newData;

      dispatch(setSortingValue(newArr));
    } else {
      arr = [...arr, { id: data.name, sort: true, desc: false }];

      dispatch(setSortingValue(arr));
    }
  };

  useEffect(() => {
    if (user?.type !== 'superadmin') {
      getFilterListUser({ orgId: user?.organizationId?._id }).then((res) => {
        if (res.status === 200) {
          setCreatedByData(res?.data?.data);
        }
      });
    }
  }, []);

  // const hasFilterValues = Object.values(filterValues).some((value: any) => value?.length > 0);
  const createdByName = [...new Set(cretedByData?.map((item: any) => item.firstName))];
  const createdById = [...new Set(cretedByData?.map((item: any) => item._id))];
  const publishedStatus = ['true', 'false'];
  const batchesName = [...new Set(batchData?.map((item: any) => item))];

  // const categoryName = [...new Map(category?.map((item: any) => [item._id, item.name])).values()];
  // const categoryName = [...new Set(category?.map((item: any) => item.name))];

  const setModalFilters = (key: string, value: any, isFromDelete?: boolean) => {
    if (key === 'FilterTypes') {
      setFilterValues((prev: any) => {
        const currentValue = prev[key] || [];
        let updatedArray;

        if (currentValue.includes(options[value])) {
          updatedArray = currentValue.filter((item: any) => item !== options[value]);
        } else {
          updatedArray = [...currentValue, options[value]];
        }

        if (isFromDelete) {
          setFilterSubmitValues({
            ...prev,
            [key]: updatedArray,
            // publishStatus: key === 'FilterTypes' && updatedArray.includes('publishStatus') ? prev.publishStatus : '',
            createdBy: key === 'FilterTypes' && updatedArray.includes('createdBy') ? prev.createdBy : { ids: [], names: [], indices: [] },
            batches: key === 'FilterTypes' && updatedArray.includes('batches') ? prev.batches : { names: [], indices: [] }
          });
        }
        return {
          ...prev,
          [key]: updatedArray,
          // publishStatus: key === 'FilterTypes' && updatedArray.includes('publishStatus') ? prev.publishStatus : '',
          createdBy: key === 'FilterTypes' && updatedArray.includes('createdBy') ? prev.createdBy : { ids: [], names: [], indices: [] },
          batches: key === 'FilterTypes' && updatedArray.includes('batches') ? prev.batches : { names: [], indices: [] }
        };
      });
    } else if (key === 'createdBy') {
      setFilterValues((prev: any) => {
        const currentIds = prev[key]?.ids || [];
        const currentNames = prev[key]?.names || [];
        const currentIndices = prev[key]?.indices || [];
        let updatedIds;
        let updatedNames;
        let updatedIndices;

        if (currentIds.includes(createdById[value]) || currentNames.includes(createdByName[value]) || currentIndices.includes(value)) {
          updatedIds = currentIds.filter((item: any) => item !== createdById[value]);
          updatedNames = currentNames.filter((item: any) => item !== createdByName[value]);
          updatedIndices = currentIndices.filter((item: any) => item !== value);
        } else {
          updatedIds = [...currentIds, createdById[value]];
          updatedNames = [...currentNames, createdByName[value]];
          updatedIndices = [...currentIndices, value];
        }

        if (isFromDelete) {
          setFilterSubmitValues({
            ...prev,
            [key]: { ids: updatedIds, names: updatedNames, indices: updatedIndices }
          });
        }
        return {
          ...prev,
          [key]: { ids: updatedIds, names: updatedNames, indices: updatedIndices }
        };
      });
    } else if (key === 'batches') {
      setFilterValues((prev: any) => {
        const currentNames = prev[key]?.names || [];
        const currentIndices = prev[key]?.indices || [];
        let updatedNames;
        let updatedIndices;

        if (currentNames.includes(batchesName[value]) || currentIndices.includes(value)) {
          updatedNames = currentNames.filter((item: any) => item !== batchesName[value]);
          updatedIndices = currentIndices.filter((item: any) => item !== value);
        } else {
          updatedNames = [...currentNames, batchesName[value]];
          updatedIndices = [...currentIndices, value];
        }

        if (isFromDelete) {
          setFilterSubmitValues({
            ...prev,
            [key]: { names: updatedNames, indices: updatedIndices }
          });
        }
        return {
          ...prev,
          [key]: { names: updatedNames, indices: updatedIndices }
        };
      });
    }
    // else if (key === 'publishStatus') {
    //   setFilterValues((prev: any) => {
    //     const updatedStatus = publishedStatus[value];

    //     return {
    //       ...prev,
    //       [key]: updatedStatus
    //     };
    //   });
    // }
    else {
      setFilterValues((prev: any) => ({ ...prev, [key]: value }));
      if (isFromDelete) {
        setFilterSubmitValues((prev: any) => ({ ...prev, [key]: value }));
      }
    }
    // dispatch(setUserPageIndex(1));
  };

  useEffect(() => {
    if (filterValues.FilterTypes.includes('createdBy')) {
      setTotalFilter((prev: any) => ({ ...prev, createdBy: { val1: createdByName, val2: 'checkbox', val3: createdById } }));
    } else {
      setTotalFilter((prev: any) => {
        const { createdBy: classKey, ...rest } = prev;
        return rest;
      });
    }
    if (filterValues.FilterTypes.includes('batches')) {
      setTotalFilter((prev: any) => ({ ...prev, batches: { val1: batchesName, val2: 'checkbox' } }));
    } else {
      setTotalFilter((prev: any) => {
        const { batches: classKey, ...rest } = prev;
        return rest;
      });
    }
    // if (filterValues.FilterTypes.includes('publishStatus')) {
    //   setTotalFilter((prev: any) => ({ ...prev, publishStatus: { val1: ['true', 'false'], val2: 'radioButton' } }));
    // } else {
    //   setTotalFilter((prev: any) => {
    //     const { publishStatus: classKey, ...rest } = prev;
    //     return rest;
    //   });
    // }
  }, [filterValues]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <Box sx={{ p: 1, pb: 1.2, textAlign: 'center' }}>
          <DialogTitle id="alert-dialog-title" sx={{ fontSize: '20px' }}>
            <b>Delete Group</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure you want to delete this group ?</DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setDisabled(true);
                handleClose();
                deleteGroupQuiz(quizGroupId);
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      {/* 
      <Dialog open={open1} onClose={handleClose1} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <Box sx={{ p: 1, pb: 1.2, textAlign: 'center' }}>
          <DialogTitle id="alert-dialog-title" sx={{ fontSize: '20px' }}>
            <b>Select Plan</b>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              {packages?.map((pkg: any) => (
                <Grid item xs={12} sm={6} md={3} key={pkg._id}>
                  <Box
                    onClick={() => handleSelect(pkg._id, pkg.price)}
                    sx={{
                      border: selectedPackage === pkg._id ? '2px solid #007BFF' : '1px solid #ccc',
                      borderRadius: '8px',
                      minHeight: '150px',
                      padding: '16px',
                      cursor: 'pointer',
                      transition: '0.3s',
                      backgroundColor: selectedPackage === pkg._id ? '#E9F0FF' : '#F8F9FA',
                      '&:hover': {
                        border: '2px solid #007BFF',
                        backgroundColor: '#E9F0FF'
                      }
                    }}
                  >
                    <Radio
                      checked={selectedPackage === pkg._id}
                      onChange={() => handleSelect(pkg._id, pkg.price)}
                      value={pkg._id}
                      name="package"
                      sx={{ color: '#007BFF' }}
                    />
                    <Typography variant="h6" gutterBottom>
                      {pkg.duration.charAt(0).toUpperCase() + pkg.duration.slice(1)} Plan
                    </Typography>
                    <Typography variant="body1">
                      {formatCurrency(pkg.price?.toString(), {
                        country: currencySettings?.code,
                        currency: currencySettings?.currency
                      })}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button variant="outlined" onClick={handleClose1}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                // setDisabled(true);
                handleClose1();
                handleCart();
              }}
              autoFocus
            >
              Add to Cart
            </Button>
          </DialogActions>
        </Box>
      </Dialog> */}

      <MainCard
        title={
          <span style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5">
              <FormattedMessage
                id={type === 'org' ? 'My Question Groups' : type === 'paid' ? 'Market Place Question Groups' : 'Question Groups'}
              />
            </Typography>
            <Typography variant="body2">
              <FormattedMessage id="Manage groups of Questions and their details, with options to add, edit, delete." />
            </Typography>
          </span>
        }
        content={false}
        secondary={
          <Stack direction={'row-reverse'}>
            {type !== 'paid' && (
              <Stack>
                <Tooltip title={<FormattedMessage id="Add Question Group" />}>
                  <span>
                    <IconButton
                      variant="dashed"
                      color="primary"
                      disabled={user?.type !== 'superadmin' && !groupPermission?.Create}
                      onClick={() => {
                        navigate(navigatePath);
                      }}
                    >
                      <AddCircle />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
            )}
            {type !== 'paid' && (
              <Stack sx={{ mt: 1 }}>
                <ModalFilter
                  filterKeywords={totalFilter}
                  onFilterKeywordChange={setModalFilters}
                  count={count}
                  filterValuesSet={setFilterValues}
                  values={filterValues}
                  setFilterSubmitValues={setFilterSubmitValues}
                  submittedFilterValues={filterSubmitValues}
                  // disabled={disabledFilter}
                  applyFilter={setSubmitFilters}
                  filterFlag={submitFilters}
                  setDisabled={setDisabledFilter}
                  pageIndex={setQuizGroupTablePageIndex}
                />
              </Stack>
            )}
          </Stack>
        }
      >
        {/* <Stack direction="row-reverse" spacing={1} sx={{ py: 1, mr: 2 }} alignItems="center">
          <Stack>
            <Tooltip title={<FormattedMessage id="Search" />}>
              <Button sx={{ p: '9px', minWidth: 'auto' }} variant="outlined" color="primary" onClick={handleSearch}>
                <SearchNormal1 />
              </Button>
            </Tooltip>
          </Stack>
          <Stack>
            <OutlinedInput
              value={value || search || ''}
              onChange={(e) => {
                setValue(e.target.value);
                if (e.target.value === '') {
                  dispatch(searchQuizGroupTable(''));
                }
              }}
              placeholder={
                i18n === 'fr'
                  ? `recherche à partir de ${count} enregistrements`
                  : i18n === 'ro'
                  ? `cauta din ${count} inregistrari`
                  : i18n === 'zh'
                  ? `从 ${count} 条记录中搜索`
                  : i18n === 'ar'
                  ? 'بحث في السجلات...'
                  : i18n === 'hindi'
                  ? `${count} रिकॉर्ड खोजें...`
                  : `Search ${count} records...`
              }
              id="start-adornment-email"
            />
          </Stack>
        </Stack> */}
        <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ my: 1 }}>
          <Stack display={'flex'} sx={{ mx: 1 }} flexDirection={'row'} flexWrap={'wrap'}>
            {filterValues?.createdBy?.ids?.length > 0 ||
            // filterValues?.publishStatus?.length > 0 ||
            filterValues?.batches?.names?.length > 0 ||
            filterValues?.search ||
            filterValues?.startDate ? (
              <Button
                variant="outlined"
                onClick={() => {
                  setSubmitFilters(!submitFilters);
                  setFilterValues({
                    search: '',
                    FilterTypes: [],
                    // publishStatus: [],
                    createdBy: [],
                    batches: [],
                    startDate: null,
                    endDate: null
                  });
                  setFilterSubmitValues({
                    search: '',
                    FilterTypes: [],
                    // publishStatus: [],
                    createdBy: [],
                    batches: [],
                    startDate: null,
                    endDate: null
                  });
                }}
                sx={{ m: 1 }}
              >
                Clear All
              </Button>
            ) : (
              <></>
            )}

            {Object.entries<any>(filterValues).map(([key, values]: [string, string[] | string]) =>
              (Array.isArray(values) ? values : [values]).map(
                (value: any, index: number) =>
                  value &&
                  value !== undefined &&
                  value !== null && (
                    <div key={`${key}-box`}>
                      {/* {key === 'publishStatus'
                        ? values &&
                          values.length > 0 && (
                            <div
                              style={{
                                border: '1px solid gray',
                                display: 'flex',
                                margin: '2px',
                                borderRadius: '15px',
                                flexDirection: 'row'
                              }}
                            >
                              <Typography mx={1} mt={1.5}>
                                <b>{capitalizeFirstLetter(key)}:</b>
                              </Typography>
                              {(Array.isArray(values) ? values : [values]).map(
                                (value: string | Number, index: number) =>
                                  value !== undefined &&
                                  value !== null && (
                                    <Chip
                                      key={`${key}-${index}`}
                                      sx={{ m: 1 }}
                                      label={`${value}`}
                                      color={key === 'publishStatus' ? 'primary' : 'secondary'}
                                      style={{ color: key !== 'publishStatus' && mode === 'dark' ? 'black' : 'white' }}
                                      onDelete={() => {
                                        if (key === 'publishStatus') {
                                          setModalFilters(key, value);
                                        } else {
                                          setModalFilters(key, '');
                                        }
                                      }}
                                    />
                                  )
                              )}
                            </div>
                          )
                        : */}
                      {value?.names?.length !== 0 && (
                        <>
                          {value?.names ? (
                            <div
                              key={`${key}-${index}`}
                              style={{
                                border: '1px solid gray',
                                margin: '2px',
                                borderRadius: '15px',
                                display: 'flex',
                                flexDirection: 'row',
                                backgroundColor: key === 'createdBy' && mode !== 'dark' ? '#F8F9FA' : 'transparent'
                              }}
                            >
                              <Typography mx={1} mt={1.5}>
                                <b>{capitalizeFirstLetter(key)}:</b>
                              </Typography>
                              {value?.names?.map((name: string, nameIndex: number) => (
                                <Chip
                                  key={`${key}-${index}-${nameIndex}`}
                                  sx={{ my: 1, mx: 0.3 }}
                                  label={name}
                                  color={key === 'createdBy' ? 'primary' : 'secondary'}
                                  style={{ color: key !== 'createdBy' && mode === 'dark' ? 'black' : 'white' }}
                                  onDelete={() => {
                                    if (key === 'FilterTypes' || key === 'createdBy') {
                                      setSubmitFilters(!submitFilters);
                                      setModalFilters(key, value?.indices?.[nameIndex], true);
                                    } else {
                                      setModalFilters(key, '');
                                    }
                                  }}
                                />
                              ))}
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </div>
                  )
              )
            )}

            {filterValues.startDate || filterValues.endDate ? (
              <div
                style={{
                  border: '1px solid gray',
                  margin: '2px',
                  borderRadius: '15px',
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <React.Fragment>
                  <Typography mx={1} mt={1.5}>
                    <b>Range:</b>
                  </Typography>
                  <Chip
                    label={`${moment(filterValues.startDate).format('DD-MM-YYYY')} to ${moment(filterValues?.endDate || new Date()).format(
                      'DD-MM-YYYY'
                    )}`}
                    color="primary"
                    sx={{ m: 1 }}
                    onDelete={() => {
                      setModalFilters('startDate', null);
                      setModalFilters('endDate', null);
                    }}
                  />
                </React.Fragment>
              </div>
            ) : null}

            {filterValues.search ? (
              <div
                style={{
                  border: '1px solid gray',
                  margin: '2px',
                  borderRadius: '15px',
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <React.Fragment>
                  <Typography mx={1} mt={1.5}>
                    <b>Search:</b>
                  </Typography>
                  <Chip
                    label={filterValues.search}
                    color="primary"
                    sx={{ m: 1 }}
                    onDelete={() => {
                      setModalFilters('search', '');
                    }}
                  />
                </React.Fragment>
              </div>
            ) : null}
          </Stack>
        </Stack>
        {disabled && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1,
              marginTop: '12px'
            }}
          >
            <Loader2 />
          </div>
        )}
        {isLoading ? (
          <SkeletonTable columns={columns} />
        ) : type === 'paid' ? (
          <MarketPlaceGroups />
        ) : (
          <Box
            sx={{
              height: '100%',
              overflow: 'hidden',
              filter: disabled ? 'grayscale(100%) blur(3px)' : 'none'
            }}
          >
            <CustomTable
              icon={TaskSquare}
              text={<FormattedMessage id="No Question Groups available, Add now !" />}
              link={navigatePath}
              sortArray={['name']}
              columns={columns}
              data={quizGroupData || []}
              sort={sortData}
              setSortingData={setSortingData}
              notFound={notFound}
              search={search}
              count={count}
              size={pageSize}
              pageIndex={currentPage}
              handlePageChange={handlePageChange}
              handleSizeChange={handleChange}
            />
          </Box>
        )}
      </MainCard>
    </>
  );
}

export default QuizGroupList;
