import { useEffect } from 'react'; // useState

// project-imports
import Routes from 'routes';
import ThemeCustomization from 'themes';

// import Loader from 'components/Loader';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Customization from 'components/Customization';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';
import WindowFocusHandler from 'contexts/WindowFocusHandler';

// import { dispatch } from 'store';
// import { fetchDashboard } from 'store/reducers/menu';

// auth-provider
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { useSelector } from 'react-redux';
import { ContemporaryProvider } from 'contexts/ContemporaryContext';
import { ExamRecorderProvider } from 'contexts/ExamRecorder';
import { CanvasProvider } from 'contexts/CanvasContext';
// import useConfig from 'hooks/useConfig';
// import useAuth from 'hooks/useAuth';
// import { setThemeSetting } from 'store/reducers/certificate';
// import { setLogo, setSettings } from 'store/reducers/organizationSettings';
// import { dispatch } from 'store';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  // const [loading, setLoading] = useState<boolean>(false);
  const favicon = process.env.REACT_APP_FILE_URL + useSelector((state: any) => state?.organization)?.favIcon?.[0]?.path;

  useEffect(() => {
    if (favicon) {
      let link: any = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = favicon;
    }
    // -----------------Dynamic favicon icon ----------------end
  }, [favicon]);

  // if (!loading) return <Loader />;

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <ContemporaryProvider>
              <AuthProvider>
                <ExamRecorderProvider>
                  <CanvasProvider>
                    <>
                      <Notistack>
                        <Routes />
                        <Customization />
                        <Snackbar />
                        <WindowFocusHandler />
                      </Notistack>
                    </>
                  </CanvasProvider>
                </ExamRecorderProvider>
              </AuthProvider>
            </ContemporaryProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
