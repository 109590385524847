import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL;

export const getAllCertificates = (reqData: object) => {
  return axios.get(API_URL + 'certificates/getAll', { params: reqData });
};

export const getUserCertificates = async (reqData: any) => {
  return axios.get(API_URL + 'certificates/myCertificates', { params: reqData });
};

export const getAllPublishCertificates = (reqData: object) => {
  return axios.get(API_URL + 'certificates/all', { params: reqData });
};

export const getCertificates = (reqData: object) => {
  return axios.get(API_URL + 'certificates', { params: reqData });
};

export const addCertificate = (categoryReqData: Object) => {
  return axios.post(API_URL + 'certificates/add', categoryReqData); // reqData => {search: "text", limit: number, skip: limit}
};

export const deleteCertificate = (reqData: object) => {
  return axios.delete(API_URL + 'certificates', { params: reqData }); // reqData => {search: "text", limit: number, skip: limit}
};

export const updateCertificate = (reqData: object) => {
  return axios.put(API_URL + 'certificates', reqData); // reqData => {search: "text", limit: number, skip: limit}
};

export const UpdateOrganizationSettings = (reqData: object) => {
  return axios.put(API_URL + 'organization/setting', reqData);
};

export const SetBasicSettings = (reqData: object) => {
  return axios.put(`${API_URL}organization/basicsetting`, reqData);
};

export const GetCertificatePreview = (reqData: object) => {
  return axios.put(`${API_URL}certificates/checkView`, reqData);
};

export const PrintCertificatePdf = (reqData: object) => {
  return axios.put(`${API_URL}certificates/printpdf`, reqData);
};

export const GetCertificate = (reqData: object) => {
  return axios.get(`${API_URL}certificates`, { params: reqData });
};

export const getCertificateAccomplishUrl = (id: string) => {
  return axios.get(`${API_URL}certificates/get/${id}`);
};

export const downloadCertificate = (reqData: object) => {
  return axios.get(`${API_URL}certificates/download`, { params: reqData });
};
