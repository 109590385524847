import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Badge,
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  Stack,
  Typography,
  useMediaQuery,
  Dialog,
  Tooltip,
  DialogTitle,
  Menu
} from '@mui/material';
import IconButton from 'components/@extended/IconButton';
import { PopupTransition } from 'components/@extended/Transitions';
import { CloseCircle, Notification } from 'iconsax-react';
import useAuth from 'hooks/useAuth';
import { getAllNotifyMessage, markAllRead, markSingleRead } from 'services/notifyMessage.service';
import { useDispatch } from 'react-redux';
import { removeMessage, setMessage } from 'store/reducers/notify';
import { useSelector } from 'store';
import { Link, useNavigate } from 'react-router-dom';
import { formatDateBasedOnOrgSettings, getTime } from 'utils/helper';
import Snackbar from 'utils/Snackbar';

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',
  transform: 'none'
};

const NotificationPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const { organization } = useAuth();
  const anchorRef = useRef<any>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();
  const data = useSelector((state) => state.notifyMessage).notifymessages;
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null); // State for Menu anchor element
  const { user }: any = useAuth();

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(menuAnchorEl ? null : event.currentTarget); // Toggle Menu anchor
  };

  const handleClose = () => {
    setMenuAnchorEl(null); // Close Menu
  };

  const handleRead = (messageId: any) => {
    markSingleRead({ notificationMessageId: messageId, userId: user?._id, organizationId: user?.organizationId?._id }).then(() => {
      dispatch(removeMessage(messageId));
      getMessages();
    });
  };

  const getMessages = () => {
    if (user?.organizationId) {
      getAllNotifyMessage({ orgId: user?.organizationId?._id, userId: user?._id, skip: 1, limit: 4, type: user?.type, isRead: 'unread' })
        .then((data) => {
          dispatch(setMessage(data.data.data.notificationData));
          setCount(data?.data?.data?.totalCount?.[0]?.count);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (anchorRef.current && !anchorRef.current.contains(event.target)) {
        setMenuAnchorEl(null); // Close Menu if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorRef]);

  const markAllAsRead = () => {
    markAllRead({ userId: user?._id, organizationId: user?.organizationId?._id })
      .then(() => {
        dispatch(setMessage([]));
        getMessages();
      })
      .catch((err) => {
        Snackbar(err?.response?.data?.message, 'error');
      });
  };

  useEffect(() => {
    getMessages();
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const downLG = useMediaQuery(theme.breakpoints.down('sm'));

  const dateDifference = (date: any) => {
    const providedDate: any = new Date(date);
    const currentDate: any = new Date();
    const timeDifference = currentDate - providedDate;
    const minute = 60 * 1000;
    const hour = 60 * minute;
    const day = 24 * hour;
    if (timeDifference < hour) {
      const minutes = Math.floor(timeDifference / minute);
      if (minutes === 1) {
        return '1min ago';
      } else {
        return `${minutes} min ago`;
      }
    } else if (timeDifference < day) {
      const hours = Math.floor(timeDifference / hour);
      if (hours === 1) {
        return '1 h ago';
      } else {
        return `${hours} hours ago`;
      }
    } else {
      const days = Math.floor(timeDifference / day);
      if (days === 1) {
        return '1 day ago';
      } else {
        return `${days} days ago`;
      }
    }
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.5 }}>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        TransitionComponent={PopupTransition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: 3
          }
        }}
      >
        {/* <Box height={'70vh'} overflow={'none'} position={'relative'} bgcolor={theme.palette.mode === 'dark' ? '#141a21' : '#f5f5f5'}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '70px',
              flexGrow: 1
            }}
          >
            <Box sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
              <Stack direction="row-reverse" sx={{ mt: 1, mr: 1 }}>
                <CloseCircle onClick={handleCloseDialog} />
              </Stack>
              <DialogTitle sx={{ textAlign: 'center', marginTop: '-25px' }}>
                <Typography variant="h3" color="primary" fontWeight="bold" mb={2}>
                  {downLG ? 'Notification' : '⏰ Notification ⏰'}
                </Typography>
              </DialogTitle>
            </Box>
          </Box>
          <Box sx={{ overflowY: 'auto', height: '83%' }}>
            <ListItemText
              sx={{ px: 2 }}
              primary={<Typography variant="h4">{selectedData?.emailDetailsData?.subject}</Typography>}
              secondary={
                <Stack flexDirection="row">
                  <Typography variant="subtitle1" color="textSecondary" mr={1}>
                    Date:
                  </Typography>
                  <Stack direction="column">
                    <Typography variant="body1">
                      {formatDateBasedOnOrgSettings(selectedData?.updated_at, organization)}
                    </Typography>
                  </Stack>
                </Stack>
              }
            />
            <Typography
              variant="body2"
              color="textPrimary"
              dangerouslySetInnerHTML={{ __html: selectedData?.emailDetailsData?.body }}
            ></Typography>
          </Box>
        </Box> */}
        <Box
          sx={{
            height: '70vh',
            overflow: 'hidden',
            position: 'relative',
            bgcolor: theme.palette.mode === 'dark' ? '#141a21' : '#f5f5f5',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              bgcolor: 'background.paper',
              px: 0.4,
              borderBottom: '1px solid',
              borderColor: 'divider'
            }}
          >
            <Stack
              direction="row-reverse"
              // sx={{ mb: 2 }}
            >
              <IconButton
                onClick={handleCloseDialog}
                aria-label="close"
                sx={{
                  color: 'text.secondary',
                  '&:hover': {
                    bgcolor: 'action.hover'
                  }
                }}
              >
                <CloseCircle onClick={handleCloseDialog} />
              </IconButton>
            </Stack>
            <DialogTitle
              sx={{
                textAlign: 'center',
                marginTop: -4 // Adjust the top margin if needed
                // mb: 2
              }}
            >
              <Typography variant="h4" color="primary" fontWeight="bold">
                {downLG ? 'Notification' : '⏰ Notification ⏰'}
              </Typography>
            </DialogTitle>
          </Box>
          <Box
            sx={{
              overflowY: 'auto',
              flexGrow: 1,
              p: 2,
              bgcolor: theme.palette.background.paper
            }}
          >
            <ListItemText
              sx={{ mb: 2 }}
              primary={
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="h6">Subject:</Typography>
                  <Typography variant="h6">{selectedData?.emailDetailsData?.subject}</Typography>
                </Stack>
              }
              secondary={
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body2" color="textSecondary">
                    Date:
                  </Typography>
                  <Typography variant="body2">{formatDateBasedOnOrgSettings(selectedData?.updated_at, organization)}</Typography>
                </Stack>
              }
            />
            <Typography variant="body1" color="textPrimary" dangerouslySetInnerHTML={{ __html: selectedData?.emailDetailsData?.body }} />
          </Box>
        </Box>
      </Dialog>
      <Tooltip title="Notification">
        <IconButton
          color="secondary"
          variant="light"
          aria-label="open profile"
          // aria-controls={open ? 'profile-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          size="large"
          sx={{
            width: user?.type === 'student' ? '45px' : '40px',
            height: user?.type === 'student' ? '45px' : '40px',
            border: user?.type === 'student' ? 'none' : 'none',
            borderRadius: user?.type === 'student' ? '1000px' : 'none',
            color: '#1D1D1F',
            backgroundColor: '#FFFFFF',
            '&:hover': {
              backgroundColor: user?.type === 'student' ? '#FFFFFF' : 'transparent'
            },
            p: 0.75
          }}
        >
          <Badge badgeContent={count} color="warning" sx={{ '& .MuiBadge-badge': { top: 2, right: 4 } }}>
            <Notification
              color={user?.type === 'student' ? '#1D1D1F' : undefined}
              className="mainlogoicon"
              variant={user?.type === 'student' ? undefined : 'Bold'}
            />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            boxShadow: theme.customShadows.z1,
            borderRadius: 1.5,
            width: '100%',
            minWidth: 285,
            marginTop: 1.5,
            maxWidth: 380,
            textAlign: 'center',
            [theme.breakpoints.down('md')]: {
              maxWidth: 285
            }
          }
        }}
      >
        <Box sx={{ padding: 2 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h5">Notifications</Typography>
            {data.length === 0 ? (
              <Box sx={{ display: 'none' }}></Box>
            ) : (
              <Typography variant="h6" color="warning.main">
                <Link to={'#'} onClick={markAllAsRead} style={{ textDecoration: 'none', color: 'inherit' }}>
                  Mark all read
                </Link>
              </Typography>
            )}
          </Stack>
          <Box sx={{ height: data.length > 0 ? 300 : 100, padding: 0, overflowY: 'auto', overflowX: 'hidden' }}>
            {data.length > 0 ? (
              <List
                component="nav"
                sx={{
                  '& .MuiListItemButton-root': {
                    p: 1.5,
                    my: 1.5,
                    mr: 1,
                    border: `1px solid ${theme.palette.divider}`,
                    '&:hover': {
                      bgcolor: 'primary.lighter',
                      borderColor: theme.palette.primary.light
                    },
                    '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                  }
                }}
              >
                {data.map((value: any) => (
                  <ListItemButton
                    key={value._id}
                    onClick={() => {
                      setSelectedData(value);
                      handleOpenDialog();
                      handleRead(value._id);
                    }}
                  >
                    <ListItemText
                      primary={
                        <>
                          <Typography
                            variant="h6"
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '2',
                              WebkitBoxOrient: 'vertical'
                            }}
                          >
                            {value?.emailDetailsData?.subject}
                          </Typography>
                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="body2">{dateDifference(value?.emailDetailsData?.updated_at)}</Typography>
                            <Typography variant="caption" noWrap sx={{ fontWeight: 600 }}>
                              {value?.updated_at &&
                                getTime(
                                  value?.updated_at,
                                  organization?.basicsettings?.locale?.defaulttimezone
                                    ? organization?.basicsettings?.locale?.defaulttimezone
                                    : 'asia/calcutta'
                                )}
                            </Typography>
                          </Stack>
                        </>
                      }
                    />
                  </ListItemButton>
                ))}
              </List>
            ) : (
              <Typography variant="body1" color="textSecondary" textAlign="center" mt={4} noWrap sx={{ fontWeight: 700 }}>
                No notifications yet!
              </Typography>
            )}
          </Box>
          <Stack direction="row" justifyContent="center">
            <Typography
              variant="h6"
              color="primary"
              sx={{ cursor: 'pointer', zIndex: 9999 }}
              onClick={(e: any) => {
                navigate(user?.type === 'student' ? '/learner/notify' : '/notify');
                handleClose();
              }}
            >
              View all
            </Typography>
          </Stack>
        </Box>
      </Menu>
    </Box>
  );
};

export default NotificationPage;
