// https://chatgpt.com/c/67486d6b-bbc0-8010-8785-abcb4ec05bc5

import useAuth from 'hooks/useAuth';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { examCaptures, submitExam } from 'services/completeExam.service';
import { useSelector } from 'store';

// Define the type for the context
interface ExamRecorderContextType {
  isScreenShared: boolean;
  isRecording: boolean;
  isWebcamEnabled: boolean;
  error: boolean | string;
  setError: (err: boolean | string) => void;
  startScreenSharing: () => Promise<void>;
  stopScreenSharing: () => void;
  startRecording: () => void;
  stopRecording: () => void;
  // saveRecording: () => void;
  enableWebcam: () => Promise<void>;
  disableWebcam: () => void;
  // completeExamId: string | null;
  setCompleteExamId: (id: string) => void;
  examSubmitType: string;
  setExamSubmitType: (id: string) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  done: boolean;
  setDone: (done: boolean) => void;
  feedback: boolean;
  setFeedback: (feedback: boolean) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  checkDevTools: () => Boolean;
  openFullscreen: () => void;
  checkWebcamAccess: () => Promise<boolean>;
}

// Create the context with default undefined
const ExamRecorderContext = createContext<ExamRecorderContextType | undefined>(undefined);

// Provider component
export const ExamRecorderProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const webcamStreamRef = useRef<MediaStream | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const [screenStream, setScreenStream] = useState<MediaStream | null>(null);
  const screenStreamRef = useRef<MediaStream | null>(null);

  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
  const recordedChunksRef = useRef<Blob[]>([]);

  const [recordedBlob, setRecordedBlob] = useState<Blob | null>(null);
  const [isScreenShared, setIsScreenShared] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isWebcamEnabled, setIsWebcamEnabled] = useState<boolean>(false);
  const [error, setError] = useState<boolean | string>('');

  const [completeExamId, setCompleteExamId] = useState<string | null>(null);
  const completeExamIdRef = useRef<string | null>();

  const [examSubmitType, setExamSubmitType] = useState<string>('');

  const currentIndex = useSelector((state: any) => state?.examattend)?.currentIndex;
  const questions = useSelector((state: any) => state?.examattend)?.questionsList;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    // console.log('On screenStreamRef changed');
    if (screenStreamRef) {
      startRecording();
    }
  }, [screenStreamRef]);

  useEffect(() => {
    completeExamIdRef.current = completeExamId;
    recordedChunksRef.current = recordedChunks;
  }, [completeExamId, recordedChunks]);

  const enableWebcam = async (): Promise<void> => {
    // try {
    await navigator.mediaDevices.getUserMedia({
      video: true
      // audio: false // Adjust if audio is needed
    });
    setIsWebcamEnabled(true);
  };

  const disableWebcam = (): void => {
    // if (webcamStreamRef.current) {
    //   webcamStreamRef.current.getTracks().forEach((track) => track.stop());
    //   webcamStreamRef.current = null;
    // }
    setIsWebcamEnabled(false);
  };

  //camera integration
  const checkWebcamAccess = () => {
    return navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        // Access granted!
        stream.getTracks().forEach((track) => track.stop()); // Stop the stream
        return true;
      })
      .catch((error) => {
        // Access denied or error occurred
        console.error('Error accessing camera:', error);
        return false;
      });
  };

  const startScreenSharing = async (): Promise<void> => {
    // Stop any existing screen sharing and recording
    if (screenStreamRef.current) {
      stopScreenSharing();
    }
    if (isRecording) {
      stopRecording();
    }

    const mediaStream = await navigator.mediaDevices.getDisplayMedia({
      video: {
        displaySurface: 'monitor' // Ensure full screen is shared
      }
    });

    const videoTrack = mediaStream.getVideoTracks()[0];
    const settings = videoTrack.getSettings();

    if (settings.displaySurface !== 'monitor') {
      videoTrack.stop();
      throw new Error('You must share your entire screen.');
    }

    screenStreamRef.current = mediaStream;
    setScreenStream(mediaStream);
    setIsScreenShared(true);
    // setError(null);

    videoTrack.addEventListener('ended', () => {
      setIsScreenShared(false);
      setError(true);
      // setError('Screen sharing was stopped. Please share your screen again.');
      screenStreamRef.current = null;
    });
  };

  const stopScreenSharing = (): void => {
    if (screenStreamRef.current) {
      screenStreamRef.current.getTracks().forEach((track) => track.stop());
      screenStreamRef.current = null;
    }
    setIsScreenShared(false);
  };

  const startRecording = (): void => {
    // console.log('start recording called');

    if (isRecording) {
      stopRecording(); // Ensure any ongoing recording is stopped
    }

    const options = {
      mimeType: 'video/webm;codecs=vp9'
    };

    if (!screenStreamRef.current) {
      // throw new Error('No media stream available for recording.');
      console.error('No media stream available for recording.');
      return;
    }

    const mediaRecorder = new MediaRecorder(screenStreamRef.current, options);
    mediaRecorderRef.current = mediaRecorder;

    // console.log('mediaRecorderRef.current', mediaRecorderRef.current);

    mediaRecorderRef.current.ondataavailable = (event) => {
      // console.log('On mediaRecorder ondataavailable', recordedChunksRef.current);
      if (event.data && event.data.size > 0) {
        setRecordedChunks((prev) => [...prev, event.data]);
      }
    };

    mediaRecorderRef.current.onstop = () => {
      // console.log('In mediaRecorderRef.current.onstop completeExamIdRef', completeExamIdRef);
      if (completeExamIdRef.current) {
        const completeScreenBlob = new Blob(recordedChunksRef.current, { type: options.mimeType });
        stopScreenSharing();
        setIsRecording(false);
        submitRecording(completeScreenBlob);
      }
      setRecordedChunks([]);
    };

    mediaRecorderRef.current.start(5000);
    setIsRecording(true);
  };

  const stopRecording = (): void => {
    // console.log('stopRecording function called');
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null;
    }
    setIsRecording(false);
  };

  // const saveRecording = (): void => {
  //   if (recordedChunks.length === 0) {
  //     // setError('No recording available to save.');
  //     return;
  //   }

  //   const blob = new Blob(recordedChunks, { type: 'video/webm' });
  //   const url = URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = 'screen-recording.webm';
  //   a.click();
  //   URL.revokeObjectURL(url);
  //   // setError('');
  // };

  const onFinish: any = async (index: any, status: any) => {
    localStorage.setItem('recordingType', '');
    let statusSubmit = questions?.[index]?.submit;
    const reqData = {
      status: status,
      completeExamId: completeExamIdRef.current,
      studentCheckedExam: true,
      orgId: user?.organizationId?._id
    };
    if (statusSubmit === true || statusSubmit === undefined) {
      setIsLoading(true);
      submitExam(reqData)
        .then((res) => {
          if (res.status === 200) {
            setDone(true);
            setFeedback(true);
            setIsLoading(false);
          } else {
            setDone(false);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setDone(false);
          setIsLoading(false);
          // console.error('Error submitting exam:', error);
          // Snackbar(error, 'error');
        });
      closeMessage();
    }
  };

  const submitRecording = (scrRecBlob: any) => {
    const formData: any = new FormData();
    formData.append('completeExamId', completeExamIdRef.current);
    if (scrRecBlob) {
      formData.append('screenRecFile', scrRecBlob, 'screenRecorder.mp4');
    }
    examCaptures(formData)
      .then((res) => {
        if (res.status === 200) {
          // Snackbar('Recording Submitted Successfully', 'success');
          const type = localStorage.getItem('recordingType');
          // handleSubmit(type);
          onFinish(currentIndex, type !== '' ? type : 'misconduct');
        }
      })
      .catch((error) => {
        // Snackbar(`Error submitting exam: ${error}`, 'error');
      });
  };

  const closeMessage = () => {
    setOpen(false);
  };

  const checkDevTools = (): boolean => {
    const threshold = 160; // Adjust the threshold based on your needs
    const widthDifference = window.outerWidth - window.innerWidth;
    const heightDifference = window.outerHeight - window.innerHeight;

    return widthDifference > threshold || heightDifference > threshold;
    // return false;
  };

  const openFullscreen = () => {
    const docElement = document.documentElement as HTMLElement & {
      webkitRequestFullscreen?: () => Promise<void>;
      mozRequestFullScreen?: () => Promise<void>;
      msRequestFullscreen?: () => Promise<void>;
    };

    if (docElement.requestFullscreen) {
      docElement.requestFullscreen().catch((err) => console.error(err));
    } else if (docElement.webkitRequestFullscreen) {
      docElement.webkitRequestFullscreen();
    } else if (docElement.mozRequestFullScreen) {
      docElement.mozRequestFullScreen();
    } else if (docElement.msRequestFullscreen) {
      docElement.msRequestFullscreen();
    } else {
      console.error('Fullscreen mode is not supported by this browser.');
    }
  };

  useEffect(() => {
    if (examSubmitType !== '') {
      localStorage.setItem(
        'recordingType',
        examSubmitType === 'completed' ? 'completed' : examSubmitType === 'timeOver' ? 'timeOver' : 'misconduct'
      );
      stopRecording();
    }
  }, [examSubmitType]);

  return (
    <ExamRecorderContext.Provider
      value={{
        isScreenShared,
        isRecording,
        isWebcamEnabled,
        error,
        setError,
        startScreenSharing,
        stopScreenSharing,
        startRecording,
        stopRecording,
        // saveRecording,
        enableWebcam,
        disableWebcam,
        // completeExamId,
        setCompleteExamId,
        examSubmitType,
        setExamSubmitType,
        isLoading,
        setIsLoading,
        done,
        setDone,
        feedback,
        setFeedback,
        open,
        setOpen,
        checkDevTools,
        openFullscreen,
        checkWebcamAccess
      }}
    >
      {children}
    </ExamRecorderContext.Provider>
  );
};

// export default ExamRecorderContext;

// Custom hook to use the context
export const useExamRecorder = (): ExamRecorderContextType => {
  const context = useContext(ExamRecorderContext);
  if (!context) {
    throw new Error('useExamRecorder must be used within a ExamRecorderProvider');
  }
  return context;
};
