import { useEffect, useState } from 'react';
import { Tabs, Tab, Box, useMediaQuery } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const HeaderOption = (props: any) => {
  const { user }: any = useAuth();
  const location = useLocation();
  const permission = user?.userTypeId?.permission;
  const [value, setValue] = useState(location.pathname);
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('1266'));

  // const [selectedTab, setSelectedTab] = useState(0);

  // const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setSelectedTab(newValue);
  // };

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: '100%',
        // overflowX: 'auto',
        // whiteSpace: 'nowrap'
      }}
      className="navbar-control"
    >
      <Tabs
        value={value}
        onChange={handleChange}
        onClick={props.handleClose}
        textColor="inherit"
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{
          sx: {
            backgroundColor: '#FFFFFF',
            height: 5,
            borderRadius: '20px 20px 0px 0px',
            position: 'absolute',
          }
        }}
      >
        {/* <Tab
          label="Dashboard"
          value="/learner"
          component={Link}
          to="/learner"
          onClick={props.handleClose}
          style={{
            color: value === '/learner' ? '#FFFFFF' : '#FFFFFFB2',
            // padding: '0px 8px',
            fontSize: '15px',
            fontWeight: value == '/learner' ? '500' : '400'
          }}
        /> */}
        {permission?.UserCourses?.View && (
          <Tab
            label="My Courses"
            value="/learner/mylearning"
            component={Link}
            to="/learner/mylearning"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/mylearning' ? '#FFFFFF' : '#FFFFFFB2',
              // padding: '0px 8px',
              fontSize: '15px',
              fontWeight: value == '/learner/mylearning' ? '500' : '400'
            }}
          />
        )}
        {permission?.Quiz?.View && (
          <Tab
            label="Quiz"
            value="/learner/quiz"
            component={Link}
            to="/learner/quiz"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/quiz' ? '#FFFFFF' : '#FFFFFFB2',
              // padding: '0px 8px',
              fontSize: '15px',
              fontWeight: value == '/learner/quiz' ? '500' : '400'
            }}
          />
        )}
        {permission?.Quiz?.Contest && (
          <Tab
            label="Contest"
            value="/learner/contest"
            component={Link}
            to="/learner/contest"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/contest' ? '#FFFFFF' : '#FFFFFFB2',
              // padding: '0px 8px',
              fontSize: '15px',
              fontWeight: value == '/learner/contest' ? '500' : '400'
            }}
          />
        )}
        {permission?.Meeting?.View && (
          <Tab
            label="Meeting"
            value="/learner/event"
            component={Link}
            to="/learner/event"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/event' ? '#FFFFFF' : '#FFFFFFB2',
              // padding: '0px 8px',
              fontSize: '15px',
              fontWeight: value == '/learner/event' ? '500' : '400'
            }}
          />
        )}
        {/* {permission?.Assignment?.View && (
          <Tab
            label="Assignments"
            value="/learner/assignments"
            component={Link}
            to="/learner/assignments"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/assignments' ? '#FFFFFF' : '#FFFFFFB2',
              // padding: '0px 8px',
              fontSize: '15px',
              fontWeight: value == '/learner/assignments' ? '500' : '400'
            }}
          />
        )} */}
        {permission?.Blogs?.View && (
          <Tab
            label="Blogs"
            value="/learner/blog"
            component={Link}
            to="/learner/blog"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/blog' ? '#FFFFFF' : '#FFFFFFB2',
              // padding: '0px 8px',
              fontSize: '15px',
              fontWeight: value == '/learner/blog' ? '500' : '400'
            }}
          />
        )}
        {/* Add more tabs as needed */}
      </Tabs>
    </Box>
  );
};

export default HeaderOption;
