import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Divider,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Switch
} from '@mui/material';
import MainCard from 'components/MainCard';
import useConfig from 'hooks/useConfig';
import {
  setsubjectlistPageIndex,
  setsubjectlistSearch,
  setsubjectlistTableSize,
  updatesubjectlist,
  setOrganizationTotalCount,
  resetStatesubjectlist,
  delsubjectlist,
  // setsubjectliststatus,
  setSwitchStatus
} from 'store/reducers/subjectlist';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { AddCircle, Bag, Book, Edit, SearchNormal1 } from 'iconsax-react';
import IconButton from 'components/@extended/IconButton';
import { dispatch } from 'store';
import { useSelector } from 'react-redux';
import Snackbar from 'utils/Snackbar';
import SkeletonTable from 'components/SkeletonTable';
import useAuth from 'hooks/useAuth';
import { FormattedMessage } from 'react-intl';
import CustomTable from 'components/@extended/CustomTable';
import { deleteSubject, getAllSubjects, statusChangeSubject } from 'services/subject.service';
import useContemp from 'hooks/useContemp';
import { formatDateBasedOnOrgSettings } from 'utils/helper';
import Avatar from 'components/@extended/Avatar';

const SubjectList = () => {
  const { user, organization }: any = useAuth();
  const subjectPermission = user?.userTypeId?.permission?.Subjects;

  const { i18n } = useConfig();
  const { pathname } = useLocation();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [value, setValue] = useState('');
  const pageIndex = useSelector((state: any) => state?.subjectlist)?.pageIndex;
  const size = useSelector((state: any) => state?.subjectlist)?.size;
  const search = useSelector((state: any) => state?.subjectlist)?.search;
  const subjectData = useSelector((state: any) => state?.subjectlist)?.subjectlist;
  const count = useSelector((state: any) => state?.subjectlist)?.count;
  const [isLoading, setLoading] = useState(subjectData && subjectData.length > 0 ? false : true);
  const [selectedFilter, setSelectedFilter] = useState(undefined);
  const [sortingValue, setSortingValue] = useState<any[]>([]);
  const [sortArray, setSortArray] = useState<Array<any>>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [subjectId, setSubjectId] = useState<string | null>(null);
  const [disabled, setDisabled] = useState(false);
  const [notFound, setNotFound] = useState(true);
  const { setData, prevStoredData } = useContemp();

  const fetchContacts = async () => {
    let sort: any = {};
    sortingValue.forEach((val: any) => {
      if (val.sort && val.desc) {
        sort[val.id] = -1;
      } else if (val.sort) {
        sort[val.id] = 1;
      } else {
        delete sort[val.id];
      }
      if (val.id === 'createdBy') {
        sort['createdBy.firstName'] = sort['createdBy'];
        delete sort['createdBy'];
      }
    });
    setLoading(true);
    let reqData: any = {
      organizationId: organization?._id,
      limit: size,
      skip: pageIndex,
      type: selectedFilter,
      sort: sort
    };
    if (value) reqData.search = search;
    try {
      getAllSubjects(reqData)
        .then((result: any) => {
          // console.log(result, "result")
          setLoading(false);
          dispatch(updatesubjectlist(result?.data?.data?.data));
          dispatch(setOrganizationTotalCount(result?.data?.data?.count));
          if (result?.data?.data?.data?.length > 0) {
            setNotFound(false);
          }
        })
        .catch((error) => {
          Snackbar(error.message, 'error');
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchContacts();
  }, [currentPage, pageSize, search, selectedFilter, sortingValue]);

  useEffect(() => {
    if (typeof prevStoredData?.[pathname]?.pageIndex === 'number') dispatch(setsubjectlistPageIndex(prevStoredData?.[pathname]?.pageIndex));
    if (typeof prevStoredData?.[pathname]?.size === 'number') dispatch(setsubjectlistTableSize(prevStoredData?.[pathname]?.size));
    return () => {
      dispatch(resetStatesubjectlist());
    };
  }, [dispatch]);

  deleteSubject;

  const navigate = useNavigate();
  function checkEnable(createdBy: String) {
    if (user?.type === 'admin') return false;
    if (user?.type === 'student') return true;
    if (user?.type === 'faculty') {
      if (user?._id !== createdBy) return true;
      if (user?._id === createdBy) return false;
    }
  }
  function checkPermission(createdBy: any, permission: any) {
    return permission ? (checkEnable(createdBy) ? true : false) : true;
  }
  const setSortingData = (data: any) => {
    const index1 = sortArray?.findIndex((item: any) => item?.id === data?.name);
    let arr: any = sortArray;
    if (sortArray?.[index1]?.sort === true && sortArray?.[index1]?.desc === true) {
      let arrCopy = [...sortArray];
      arrCopy.splice(index1, 1);
      setSortArray(arrCopy);
      setSortingValue(arrCopy);
    } else if (sortArray?.[index1]?.sort === true) {
      let data: any = arr[index1];
      const newData = { ...data, desc: true };
      const newArr: any = [...arr];
      newArr[index1] = newData;
      setSortArray(newArr);
      setSortingValue(newArr);
    } else {
      arr = [...arr, { id: data.name, sort: true, desc: false }];
      setSortArray(arr);
      setSortingValue(arr);
    }
  };
  const columns: any = useMemo(
    () => [
      {
        Header: 'Subject Name',
        accessor: 'subjectName'
      },
      {
        Header: 'Subject Code',
        accessor: 'subjectCode'
      },
      {
        Header: 'Created By',
        accessor: 'createdBy',
        paddingLeft: '3px',
        Cell: ({ row, value }: { row: any; value: any }) => 
         {
          return(
          <Box sx={{ textTransform: 'capitalize', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {row.original.createdBy?.firstName ? (
              <Tooltip
                title={
                  <>
                    {formatDateBasedOnOrgSettings(row.original?.createdBy?.userProfilePicId?.created_at, organization)}
                    <br />
                    {`${value?.firstName} ${value?.lastName}`}
                  </>
                }
              >
                <span>
                  <Avatar
                    alt={value?.firstName}
                    size="sm"
                    src={
                      value?.userProfilePicId?.path
                        ? process.env.REACT_APP_FILE_URL + value?.userProfilePicId?.path
                        : value?.userProfilePicId?.mimetype && value?.userProfilePicId?.file
                        ? `data:${value?.userProfilePicId?.mimetype};base64,${value?.userProfilePicId?.file}`
                        : ''
                    }
                    sx={{ margin: '8px 0' }}
                  />
                </span>
              </Tooltip>
            ) : (
              '--'
            )}
          </Box>
        )}
      },
      {
        Header: 'Last Updated By',
        accessor: 'lastUpdatedBy',
        paddingLeft: '3px',
        Cell: ({ row, value }: { row: any; value: any }) => (
          <Box sx={{ textTransform: 'capitalize', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {row.original.lastUpdatedBy?.firstName ? (
              <Tooltip
                title={
                  <>
                    {formatDateBasedOnOrgSettings(row.original.updated_at, organization)}
                    <br />
                    {`${row?.original?.lastUpdatedBy?.firstName} ${row?.original?.lastUpdatedBy?.lastName}`}
                  </>
                }
              >
                <span>
                  <Avatar
                    alt={row.original.lastUpdatedBy?.firstName}
                    size="sm"
                    src={
                      row?.original?.lastUpdatedBy?.userProfilePicId?.path
                        ? process.env.REACT_APP_FILE_URL + row?.original?.lastUpdatedBy?.userProfilePicId?.path
                        : row?.original?.lastUpdatedBy?.userProfilePicId?.mimetype && row?.original?.lastUpdatedBy?.userProfilePicId?.file
                        ? `data:${row?.original?.lastUpdatedBy?.userProfilePicId?.mimetype};base64,${row?.original?.lastUpdatedBy?.userProfilePicId?.file}`
                        : ''
                    }
                    sx={{ margin: '8px 0' }}
                  />
                </span>
              </Tooltip>
            ) : "--"}
          </Box>
        )
      },
      {
        Header: 'Status',
        Cell: ({ row }: any) => {
          const handleSubjectChange = async () => {
            const activeData = { _id: row.original._id };
            const response = await statusChangeSubject(activeData);
            if (response?.status === 200) {
              Snackbar('Status Changed Successfully.', 'success');
              dispatch(setSwitchStatus({ id: row.original._id, status: !row?.original?.active }));
            } else {
              Snackbar('Something went wrong', 'error');
            }
          };
          return (
            <Switch
              checked={row?.original?.active}
              onChange={handleSubjectChange}
              disabled={checkPermission(row?.original?.createdBy, subjectPermission?.Update)}
            />
          );
        }
      },
      {
        Header: 'Options',
        Cell: ({ row }: any) => {
          const navigate = useNavigate();
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
              <Stack direction="row" spacing={0}>
                <Tooltip title="Edit">
                  <span>
                    <IconButton
                      color={'primary'}
                      disabled={checkPermission(row?.original?.createdBy, subjectPermission?.Update)}
                      onClick={(e) => {
                        if (row?.original?._id) {
                          setData({
                            [pathname]: { size, pageIndex, gotoUrl: '/subject/edit' }
                          });
                          navigate('/subject/edit', { state: { subject: row?.original } });
                        }
                      }}
                      sx={{ display: 'block' }}
                    >
                      <Edit size="32" />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Delete">
                  <span>
                    <IconButton
                      color={'error'}
                      disabled={checkPermission(row?.original?.createdBy, subjectPermission?.Delete)}
                      onClick={() => {
                        setSubjectId(row.original?._id);
                        setDialogOpen(true);
                      }}
                    >
                      <Bag size="32" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
            </div>
          );
        }
      }
    ],
    []
  );
  const handleDialogClose = () => {
    setDialogOpen(false);
    setSubjectId(null);
  };

  const handleDelete = async () => {
    setDisabled(true);
    await deleteSubject({ subjectId })
      .then((result) => {
        if (result.status === 200) {
          dispatch(delsubjectlist(subjectId));
          Snackbar('Subject deleted successfully', 'success');
          handleDialogClose();
        } else {
          Snackbar(result.data.message, 'error');
        }
        setDisabled(false);
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
          Snackbar(errorMessage, 'warning');
        } else {
          const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
          Snackbar(errorMessage, 'error');
        }
      });
  };

  const handleChange = (event: SelectChangeEvent<number>) => {
    setPageSize(+event.target.value);
    setCurrentPage(1);
    dispatch(setsubjectlistPageIndex(1));
    dispatch(setsubjectlistTableSize(+event.target.value));
  };
  const handlePageChange = (event: any, page: number) => {
    setCurrentPage(page);
    dispatch(setsubjectlistPageIndex(page));
  };
  const setSearchOption = () => {
    if (pageIndex !== 1 && currentPage !== 1) {
      setCurrentPage(1);
    } else {
      dispatch(setsubjectlistSearch(value));
    }
    dispatch(setsubjectlistPageIndex(1));
  };
  return (
    <MainCard
      title={
        <span style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5">
            <FormattedMessage id="Subjects List" />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage id="Manage subjects and their details, with options to add, edit, or delete." />
          </Typography>
        </span>
      }
      content={false}
      secondary={
        <>
          <Tooltip title={<FormattedMessage id="Add Subject" />}>
            <span>
              <IconButton
                variant="dashed"
                color="primary"
                onClick={() => {
                  navigate('/subject/add');
                }}
              >
                <AddCircle />
              </IconButton>
            </span>
          </Tooltip>
        </>
      }
    >
      <Box>
        <Stack alignItems="center" direction="row-reverse" sx={{ py: 1, mr: 2 }}>
          <Stack>
            <Tooltip title={<FormattedMessage id="Search" />}>
              <span>
                <IconButton variant="outlined" color="primary" sx={{ ml: 0.5 }} onClick={setSearchOption} disabled={!value || value === ''}>
                  <SearchNormal1 />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
          <Stack>
            <TextField
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                if (e.target.value === '') {
                  dispatch(setsubjectlistSearch(undefined));
                  dispatch(setsubjectlistPageIndex(1));
                }
              }}
              placeholder={
                i18n === 'fr'
                  ? `recherche à partir de ${count || 0} enregistrements`
                  : i18n === 'ro'
                  ? `cauta din ${count || 0} inregistrari`
                  : i18n === 'zh'
                  ? `从 ${count || 0} 条记录中搜索`
                  : i18n === 'ar'
                  ? 'بحث في السجلات...'
                  : i18n === 'hindi'
                  ? '${count || 0}रिकॉर्ड खोजें...'
                  : `Search ${count || 0} records...`
              }
            />
          </Stack>
        </Stack>
        <Divider />
        {isLoading ? (
          <SkeletonTable columns={columns} />
        ) : (
          <CustomTable
            icon={Book}
            text={<FormattedMessage id="No Subjects Available, Add Subjects Now !! " />}
            link={'/subject/add'}
            notFound={notFound}
            columns={columns}
            data={subjectData?.length > 0 ? subjectData : []}
            sortArray={['subjectName', 'createdBy']}
            sort={sortingValue}
            setSortingData={setSortingData}
            count={count}
            size={size}
            pageIndex={pageIndex}
            handlePageChange={handlePageChange}
            handleSizeChange={handleChange}
          />
        )}
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 1, pb: 1.2, textAlign: 'center' }}>
          <DialogTitle id="alert-dialog-title" sx={{ fontSize: '20px' }}>
            <b>Delete Subject</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure you want to delete this Subject ?</DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button 
              sx={{ 
                fontSize: { xs: "12px", sm: "14px", },
                padding: { xs: "4px 8px", sm: "6px 12px", },
              }}
              variant="contained" 
              onClick={handleDialogClose}
            >
              Cancel
            </Button>
            <Button 
              sx={{
                fontSize: { xs: "12px", sm: "14px", },
                padding: { xs: "3.5px 10px", sm: "4.5px 10px", },
                ":hover": {
                  color: "white",
                  backgroundColor:  "#dc2626"
                },
              }}
              variant="outlined" 
              color="error" 
              onClick={handleDelete} 
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </MainCard>
  );
};

export default SubjectList;
