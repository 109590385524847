import { debounce } from 'lodash';
import { socket, chatEvents, AIChatEvents } from '../utils/socket';
import { useDispatch } from 'react-redux';
import {
  getOrgUser,
  getRoomUser,
  getUserChats,
  hasError,
  increaseUserChats,
  removeUnreadCount,
  setAiLoading,
  setAIRoomId,
  setChatLoading,
  setChatMessage,
  setChatSkip,
  setCurrentUser,
  setReduceChatCount,
  setTotalChatCount,
  setUnreadChip,
  setUserChatId,
  setUserRoomId,
  setUserSwitching
} from 'store/reducers/chat';
import useAuth from './useAuth';
import { useSelector } from 'store';
import { checkValidUser } from 'utils/helper';
import Snackbar from 'utils/Snackbar';
import { useLocation } from 'react-router';
import useAIChatOptions from './useAIChatOptions';

const useChat = () => {
  const { user } = useAuth();
  const { createAIRoom } = useAIChatOptions();
  const dispatch = useDispatch();
  const location = useLocation();
  const activeUser = useSelector((state) => state.chat.activeUser);
  const programmaticScroll = useSelector((state) => state.chat.programmaticScroll);
  const userInteracted = useSelector((state) => state.chat.userInteracted);
  const loading = useSelector((state) => state.chat.chatLoading);
  const userSwitch = useSelector((state) => state.chat.userSwitch);
  const firstLoad = useSelector((state) => state.chat.firstLoad);
  const skip = useSelector((state) => state.chat.skip);
  const orgUsers = useSelector((state: any) => state?.chat?.orgUsers);
  const roomUsers = useSelector((state: any) => state?.chat?.roomUsers);
  const userChats = useSelector((state: any) => state?.chat?.userChats);
  const currentUser = useSelector((state: any) => state?.chat?.user);
  const userRoomId = useSelector((state: any) => state?.chat?.roomId);
  const userChatId = useSelector((state: any) => state?.chat?.chatId);
  const searchText = useSelector((state: any) => state?.chat?.search);
  const count = useSelector((state: any) => state?.chat?.totalChatCount);
  const chatMessage = useSelector((state: any) => state?.chat?.chatMessage);
  const AiRoomId = useSelector((state) => state.chat.aiRoomId);

  const debouncedCreateChatCallback = debounce((chatData: any) => {
    dispatch(increaseUserChats(chatData?.result?.result));
  }, 500);
  const simpleIncrementChat = (chatData: any) => {
    dispatch(increaseUserChats(chatData?.result?.result));
  };

  async function getUsers(search: string, organizationId: string) {
    try {
      socket?.emit(chatEvents.GET_ALL_USERS, { search: search, organizationId: organizationId }, (data: any) => {
        dispatch(getOrgUser(data.result.totalData));
        return data.result.totalData;
      });
    } catch (error) {
      dispatch(hasError(error));
    }
    // try {
    //   const response = await axios.get(process.env.REACT_APP_PUBLIC_URL + `user/searchByName?search=${search}&orgId=${organizationId}`);
    //   dispatch(getOrgUser(response.data.data.totalData));
    //   return response.data.data.totalData;
    // } catch (error) {
    //   dispatch(hasError(error));
    // }
  }
  function readAllChats() {
    try {
      if (userRoomId === AiRoomId) {
        socket.emit(AIChatEvents.READ_ALL_AI_MESSAGES, {
          organizationId: user?.organizationId?._id,
          roomId: userRoomId
        });
      } else {
        socket.emit(chatEvents.READ_ALL_MESSAGES, {
          organizationId: user?.organizationId?._id,
          senderId: currentUser?._id,
          roomId: userRoomId
        });
      }
    } catch (err) {
      dispatch(hasError(err));
    }
  }
  function getRoomUsers() {
    try {
      socket?.emit(chatEvents.GET_ROOMS, { userId: user?._id, organizationId: user?.organizationId?._id }, (data: any) => {
        dispatch(getRoomUser(data.result));
        const isAiChatExist = Array.isArray(data.result) && data.result.filter((val: { isAIChat: boolean }) => val.isAIChat === true);
        if (!isAiChatExist?.[0]?._id) {
          if (location.state?.type === 'aibot') {
            createAIRoom([user?._id as string]);
          }
        } else {
          dispatch(setAIRoomId(isAiChatExist?.[0]?._id));
        }
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  }

  async function getRoomChats(roomId: string, currentSkip?: number) {
    try {
      socket?.emit(
        chatEvents.GET_MESSAGES,
        { userId: user?._id, organizationId: user?.organizationId?._id, roomId, skip: currentSkip ? currentSkip : skip, limit: 20 },
        (data: any) => {
          if (currentSkip === 1 || skip === 1) {
            dispatch(getUserChats(data.result?.chats));
          } else {
            dispatch(getUserChats([...data.result?.chats, ...userChats]));
          }

          if (data.result?.chats.length === 0) {
            dispatch(setTotalChatCount(userChats.length));
          } else {
            dispatch(setTotalChatCount(data.result?.count));
          }
          dispatch(setUserSwitching(false));
          dispatch(setChatLoading(false));
        }
      );
    } catch (error) {
      dispatch(hasError(error));
    }
  }

  function createRoom(users: [string]) {
    try {
      socket?.emit(chatEvents.CREATE_ROOM, { userId: user?._id, organizationId: user?.organizationId?._id, users }, (data: any) => {
        if (!data?.result?.message) {
          const updatedData =
            roomUsers?.length > 0
              ? AiRoomId
                ? [roomUsers[0], data?.result, ...roomUsers.slice(1)]
                : [data?.result, ...roomUsers]
              : [data?.result];
          dispatch(getRoomUser(updatedData));
        } else {
          Snackbar(data?.result?.message, 'error');
        }
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  }

  async function createChat(roomId: string, data: any) {
    try {
      if (!data?.isAIChat) {
        await socket?.emit(
          chatEvents.SEND_MESSAGE,
          { userId: user?._id, organizationId: user?.organizationId?._id, roomId, ...data },
          (chatData: any) => {
            debouncedCreateChatCallback(chatData); // Use debounced callback here
          }
        );
      } else {
        dispatch(setAiLoading(true));
        await socket?.emit(
          AIChatEvents.AI_CHAT_MESSAGE,
          { userId: user?._id, organizationId: user?.organizationId?._id, roomId, ...data },
          (chatData: any) => {
            simpleIncrementChat(chatData);
          }
        );
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  }

  function updateRoom(users: [string], roomId: string, admins: [string]) {
    try {
      socket?.emit(
        chatEvents.UPDATE_ROOM,
        { userId: user?._id, organizationId: user?.organizationId?._id, users, roomId, admins },
        (data: any) => {
          dispatch(getRoomUser(data.result));
        }
      );
    } catch (error) {
      dispatch(hasError(error));
    }
  }

  function updateChat(chatId: string, payload: object) {
    try {
      socket?.emit(
        chatEvents.UPDATE_MESSAGE,
        { chatId, userId: user?._id, organizationId: user?.organizationId?._id, roomId: userRoomId, ...payload },
        (data: any) => {
          const updatedMessages = userChats.map((chat: any) => {
            if (chat?._id === data?.result?._id) {
              return data?.result;
            } else {
              return chat;
            }
          });
          updatedMessages.length > 0 && dispatch(getUserChats(updatedMessages));
        }
      );
    } catch (error) {
      dispatch(hasError(error));
    }
  }

  function deleteRoom(roomId: string) {
    try {
      socket?.emit(chatEvents.DELETE_ROOM, { userId: user?._id, organizationId: user?.organizationId?._id, roomId });
    } catch (error) {
      dispatch(hasError(error));
    }
  }

  function deleteChat(chatId: string) {
    try {
      socket?.emit(chatEvents.DELETE_MESSAGE, { userId: user?._id, organizationId: user?.organizationId?._id, chatId });

      const data = userChats.map((chat: { _id: string }) => {
        if (chat._id === chatId) {
          return { ...chat, text: 'This message is deleted', isDeleted: true };
        } else {
          return chat;
        }
      });

      dispatch(getUserChats(data));
    } catch (error) {
      dispatch(hasError(error));
    }
  }

  function readChat(chatIds: [string]) {
    try {
      socket.emit(chatEvents.READ_MESSAGES, { chatIds, userId: user?._id, organizationId: user?.organizationId?._id });
      dispatch(setReduceChatCount(chatIds));
    } catch (error) {
      dispatch(hasError(error));
    }
  }

  function sendIsTyping(userId: string, receiverId: string, userType: string, receiverType: string, isTyping: boolean) {
    try {
      if (checkValidUser(userType, receiverType))
        socket.emit(chatEvents.IS_TYPING, {
          userId: userId,
          receiverId: receiverId,
          isTyping: isTyping,
          userType: userType,
          receiverType: receiverType
        });
    } catch (error) {
      dispatch(hasError(error));
    }
  }

  return {
    currentUser,
    userRoomId,
    userChatId,
    AiRoomId,
    orgUsers,
    roomUsers,
    userChats,
    searchText,
    programmaticScroll,
    userInteracted,
    userSwitch,
    firstLoad,
    skip,
    count,
    loading,
    activeUser,
    chatMessage,
    getUsers,
    readAllChats,
    getRoomUsers,
    getRoomChats,
    createRoom,
    createChat,
    updateRoom,
    updateChat,
    deleteRoom,
    deleteChat,
    readChat,
    sendIsTyping,
    createAIRoom
  };
};

export default useChat;
