import { Button, Divider, Grid, InputLabel, Stack, Box, TextField, FormHelperText } from '@mui/material';
import MainCard from 'components/MainCard';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Snackbar from 'utils/Snackbar';
import useAuth from 'hooks/useAuth';
import BackToggle from 'components/third-party/dropzone/BackToggle';
import { addContent, editContent } from 'services/landingPageManagement.service';
import UploadAvatar from 'components/third-party/dropzone/Avatar';

const validationSchema = yup.object({
  title: yup.string().required('Title is required'),
  files: yup
    .mixed()
    .required('image is required')
    .test('fileType', 'Only JPG, PNG, JPEG, and GIF files are allowed', (value) => {
      if (!value || value.length === 0) return false;
      const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg', 'image/svg+xml'];
      return allowedFileTypes.includes(value.type);
    })
});

function OurClients(data: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const { organization } = useAuth();
  const { rowData } = location.state || {};
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const existingImage = rowData?.image ? process.env.REACT_APP_FILE_URL + rowData?.image?.path : null;

  const formik = useFormik({
    initialValues: {
      title: rowData?.title || '',
      files: rowData ? rowData.image : []
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();

      setIsButtonDisabled(true);
      const serviceMethod = rowData ? editContent : addContent;
      formData.append('title', values.title);
      if (formik.values?.files?.length !== 0) {
        formData.append('image', formik.values.files);
      }
      rowData && formData.append(`id`, rowData?._id?.toString());

      serviceMethod(formData, 'clients')
        .then((res) => {
          const successMessage = rowData ? 'Content updated successfully' : 'Content added successfully';
          const errorMessage = res?.data?.message || 'An error occurred';

          if (res.status === 200 || res.status === 201) {
            Snackbar(successMessage, 'success');
            navigate('/superadmin/landingpagemanagement');
          } else {
            Snackbar(errorMessage, 'error');
            setIsButtonDisabled(false);
          }
        })
        .catch((err) => {
          Snackbar(err?.data?.message || 'An error occurred', 'error');
          setIsButtonDisabled(false);
        });
    }
  });

  return (
    <MainCard
      title={
        <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
          <BackToggle show={true} />
          <span style={{ marginLeft: 10 }}>{rowData ? 'Update Client Content' : 'Add Client Content'}</span>
        </span>
      }
      content={false}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ p: 2.5 }}>
          <Grid container spacing={3}>
            <>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="title">Title</InputLabel>
                  <TextField
                    fullWidth
                    id="title"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    // helperText={formik.touched.title && formik.errors.title}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <FormHelperText error style={{ color: 'red' }}>
                      {String(formik.errors.title)}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="image">Upload Image</InputLabel>
                  <Stack spacing={1}>
                    <UploadAvatar setFieldValue={formik.setFieldValue} file={existingImage} />
                    {formik.touched.files && formik.errors.files && (
                      <FormHelperText error style={{ color: 'red' }}>
                        {formik.errors.files as string}
                      </FormHelperText>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            </>
          </Grid>
        </Box>
        <Divider />
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ p: 1.5 }}>
          <Button 
            sx={{ 
              fontSize: { xs: "12px", sm: "14px", },
              padding: { xs: "4px 8px", sm: "6px 12px", }, 
            }}
            variant="contained" 
            type="submit" 
            disabled={isButtonDisabled}
          >
            {rowData ? 'Update' : 'Add'}
          </Button>
          <Button 
            sx={{
              fontSize: { xs: "12px", sm: "14px", },
              padding: { xs: "3.5px 10px", sm: "4.5px 10px", },
              ":hover": {
                color: "white",
                backgroundColor:  "#dc2626"
              },
            }}
            variant="outlined" 
            color="error" 
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
        </Stack>
      </form>
    </MainCard>
  );
}

export default OurClients;
