import CertificatePreview from 'pages/course/myCourse/CertificatePreview';
import React from 'react';
import { useLocation } from 'react-router';

const ViewCertificate = () => {
  const { path } = useLocation().state;
  return (
    <div>
      <CertificatePreview data={{ file: path }} />
    </div>
  );
};

export default ViewCertificate;
