// firebase-client.ts
import { initializeApp, FirebaseApp } from 'firebase/app';
import { getMessaging, getToken, Messaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGESENDERID,
  appId: process.env.REACT_APP_APPID,
};

let app: FirebaseApp;
let messaging: Messaging;
let fcmToken: string | null = null;

const initializeFirebaseApp = (): FirebaseApp => {
  if (!app) {
    app = initializeApp(firebaseConfig);
  }
  return app;
};

const initializeMessagingService = (): Messaging => {
  if (!messaging && typeof window !== 'undefined') {
    const app = initializeFirebaseApp();
    messaging = getMessaging(app);
  }
  return messaging;
};

const retrieveToken = async (): Promise<string | null> => {
  try {
    if (typeof window !== 'undefined' && navigator && navigator.serviceWorker) {
      const messaging = initializeMessagingService();
      const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPIDKEY });
      if (currentToken) {
        fcmToken = currentToken;
        return currentToken;
      } else {
        console.warn('No registration token available. Request permission to generate one.');
      }
    }
  } catch (error) {
    console.error('Error retrieving token:', error);
  }
  return null;
};

const initializeFirebaseMessaging = async (): Promise<void> => {
  try {
    await retrieveToken();
  } catch (error) {
    console.error('Error initializing Firebase messaging:', error);
  }
};

export { initializeFirebaseMessaging, messaging, onMessage, fcmToken };
