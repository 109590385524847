import { Button, Divider, Grid, InputLabel, Stack, Box, TextField } from '@mui/material';
import MainCard from 'components/MainCard';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Snackbar from 'utils/Snackbar';
import useAuth from 'hooks/useAuth';
import BackToggle from 'components/third-party/dropzone/BackToggle';
import { addContent, editContent } from 'services/landingPageManagement.service';

const validationSchema = yup.object({
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required')
});

function ConnectToolsForm(data: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const { organization } = useAuth();
  const { rowData } = location.state || {};
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      ...(rowData ? { cmsId: rowData?._id } : {}),
      type: rowData?.type ? rowData?.type : undefined,
      title: rowData?.title || '',
      description: rowData?.description || '',
      organizationId: organization?._id
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();

      setIsButtonDisabled(true);
      const serviceMethod = rowData ? editContent : addContent;
      formData.append('title', values.title);
      formData.append('description', values.description);
      rowData && formData.append(`id`, rowData?._id?.toString());

      serviceMethod(formData, 'tools')
        .then((res) => {
          const successMessage = rowData ? 'Content updated successfully' : 'Content added successfully';
          const errorMessage = res?.data?.message || 'An error occurred';

          if (res.status === 200 || res.status === 201) {
            Snackbar(successMessage, 'success');
            navigate('/superadmin/landingpagemanagement');
          } else {
            Snackbar(errorMessage, 'error');
            setIsButtonDisabled(false);
          }
        })
        .catch((err) => {
          Snackbar(err?.data?.message || 'An error occurred', 'error');
          setIsButtonDisabled(false);
        });
    }
  });

  return (
    <MainCard
      title={
        <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
          <BackToggle show={true} />
          <span style={{ marginLeft: 10 }}>{rowData ? 'Update Tool Content' : 'Add Tool Content'}</span>
        </span>
      }
      content={false}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ p: 2.5 }}>
          <Grid container spacing={3}>
            <>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="title">Title</InputLabel>
                  <TextField
                    fullWidth
                    id="title"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && String(formik.errors.title)}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="description">Description</InputLabel>
                  <TextField
                    fullWidth
                    id="description"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && String(formik.errors.description)}
                    multiline
                    rows={4}
                  />
                </Stack>
              </Grid>
            </>
          </Grid>
        </Box>
        <Divider />
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ p: 1.5 }}>
          <Button 
            sx={{ 
              fontSize: { xs: "12px", sm: "14px", },
              padding: { xs: "4px 8px", sm: "6px 12px", }, 
            }} 
            variant="contained" 
            type="submit" 
            disabled={isButtonDisabled}
          >
            {rowData ? 'Update' : 'Add'}
          </Button>
          <Link to={''} onClick={() => navigate(-1)}>
            <Button 
              sx={{
                fontSize: { xs: "12px", sm: "14px", },
                padding: { xs: "3.5px 10px", sm: "4.5px 10px", },
                ":hover": {
                  color: "white",
                  backgroundColor:  "#dc2626"
                },
              }}
              variant="outlined" 
              color="error" 
            >
              Cancel
            </Button>
          </Link>
        </Stack>
      </form>
    </MainCard>
  );
}

export default ConnectToolsForm;
