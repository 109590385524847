import { useCanvas } from 'contexts/CanvasContext';
import {
  ArrowForward,
  ArrowForwardSquare,
  BackSquare,
  Brush2,
  Edit2,
  Eraser,
  Magicpen,
  Minus,
  MouseCircle,
  MouseSquare,
  Refresh,
  Triangle,
  Wind2
} from 'iconsax-react';
import { Box, Typography, Button, Slider, Divider, Fab, Input, Tooltip } from '@mui/material';
import { Tool } from 'types/canvas';
import { useState } from 'react';
// import { RotateCcw } from 'lucide-react';

type ToolbarProps = {
  activeTool: Tool;
  lineWidth: number;
  onToolChange: (tool: Tool) => void;
  onLineWidthChange: (width: number) => void;
  color: string;
  onChange: (color: string) => void;
};

export function Toolbar() {
  const tools = [
    { name: 'pen' as Tool, icon: Edit2, label: 'Pen' },
    // { name: 'brush' as Tool, icon: Brush2, label: 'Brush' },
    { name: 'eraser' as Tool, icon: Eraser, label: 'Eraser' }
    // { name: 'line' as Tool, icon: Minus, label: 'Line' },
    // { name: 'circle' as Tool, icon: MouseCircle, label: 'Circle' },
    // { name: 'rectangle' as Tool, icon: MouseSquare, label: 'Rectangle' },
    // { name: 'triangle' as Tool, icon: Triangle, label: 'Triangle' }
    // { name: 'stroke' as Tool, icon: Wind2, label: 'stroke width' }
  ];
  const {
    strokeWidth,
    activeTool,
    strokeWidthSliderVisible,
    strokeColor,
    setStrokeColor,
    onToolChange,
    setStrokeWidthSliderVisible,
    handleStrokeWidthChange,
    handleUndoClick,
    handleRedoClick,
    handleClearClick,
    handleResetClick
  } = useCanvas();

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 2,
          p: 2,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 3,
          position: 'relative'
        }}
      >
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          Tools
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {tools.map(({ name, icon: Icon, label }) => (
            <>
              <Tooltip title={name} aria-label={name} sx={{ textTransform: 'capitalize' }}>
                <Fab
                  color={activeTool === name ? 'primary' : 'secondary.lighter'}
                  aria-label={name}
                  size="small"
                  onClick={() => onToolChange(name)}
                  sx={{
                    color: activeTool === name ? 'white' : 'black',
                    transition: 'background-color 0.3s'
                  }}
                >
                  <Icon style={{ fontSize: '1rem' }} />
                </Fab>
              </Tooltip>
            </>
          ))}
          <Tooltip title="Stroke Width" aria-label="Stroke Width">
            <Fab
              size="small"
              color={strokeWidthSliderVisible ? 'primary' : 'secondary.lighter'}
              aria-label="Stroke Width"
              onClick={() => setStrokeWidthSliderVisible(!strokeWidthSliderVisible)}
            >
              <Wind2 style={{ fontSize: '1rem' }} />
            </Fab>
          </Tooltip>
          <Tooltip title="Color" aria-label="Color">
            <Input
              type="color"
              value={strokeColor}
              onChange={(e) => setStrokeColor(e.target.value)}
              //   fullWidth
              sx={{
                height: 50,
                cursor: 'pointer',
                p: 0,
                bgcolor: 'transparent',
                border: 'none'
              }}
            />
          </Tooltip>
        </Box>
        {/* <Divider /> */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Tooltip title="Undo" aria-label="undo">
            <Fab size="small" color="secondary" aria-label="undo" onClick={handleUndoClick}>
              <ArrowForward style={{ fontSize: '1rem', transform: 'scaleX(-1)' }} />
            </Fab>
          </Tooltip>

          <Tooltip title="Redo" aria-label="redo">
            <Fab size="small" color="secondary" aria-label="redo" onClick={handleRedoClick}>
              <ArrowForward style={{ fontSize: '1rem' }} />
            </Fab>
          </Tooltip>

          <Tooltip title="Clear" aria-label="clear">
            <Fab size="small" color="error" aria-label="clear" onClick={handleClearClick}>
              <Refresh style={{ fontSize: '1rem' }} />
            </Fab>
          </Tooltip>
        </Box>
      </Box>
      {strokeWidthSliderVisible && (
        <Box
          sx={{
            position: 'absolute', // Position the slider absolutely
            top: '40%', // Center it vertically relative to the button
            left: 'calc(100% + 10px)', // Place it to the right of the button with some gap
            transform: 'translateY(-50%)', // Adjust to align with the button vertically
            bgcolor: 'background.paper',
            borderRadius: 1,
            boxShadow: 3,
            px: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Slider
            min={1}
            max={20}
            value={strokeWidth}
            onChange={(e: any) => handleStrokeWidthChange(e.target.value)}
            sx={{
              width: 150 // Set the width of the slider
            }}
          />
        </Box>
      )}
    </Box>
  );
}
