import { createSlice } from '@reduxjs/toolkit';

interface TransferStudentState {
    userIds: string[];
}

const initialState: TransferStudentState = {
    userIds: [],
};
const transferStudent = createSlice({
  name: 'transferStudent',
  initialState,
  reducers: {
    selectedStudentsId(state, action) {
      action.payload.forEach((id: string) => {
        const index = state.userIds.indexOf(id);
        if (index === -1) {
          state.userIds.push(id);
        } else {
          state.userIds.splice(index, 1);
        }
      });
    },
    selectAllStudents(state, action) {
        state.userIds.push(...action.payload)
        state.userIds = Array.from(new Set(state.userIds));
    },
    clearStudentIdState(state) {
        state.userIds = []
    }
    
  }
});

export const { selectedStudentsId, selectAllStudents, clearStudentIdState } = transferStudent.actions;
export default transferStudent.reducer;